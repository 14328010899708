import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  Origin = 'origin',
  Odometer = 'odometer',
  OdometerMiles = 'odometerMiles',
  StyleId = 'styleId',
  ModelYear = 'modelYear',
  Make = 'make',
  Model = 'model',
  Series = 'series',
  EngineCylinderCount = 'engineCylinderCount',
  EngineCylinderLabel = 'engineCylinderLabel',
  BodyType = 'bodyType',
  TransmissionType = 'transmissionType',
  Traction = 'traction',
  ExteriorColor = 'exteriorColor',
  InteriorColor = 'interiorColor',
}

export const Schema = z.object({
  [Keys.Origin]: z.string().min(1, 'appraisal.vehicle.odometer.required'),
  [Keys.Odometer]: z.string().min(1, 'appraisal.vehicle.odometer.required'),
  [Keys.OdometerMiles]: z
    .string()
    .min(1, 'appraisal.vehicle.odometer.required'),
  [Keys.StyleId]: z.string(),
  [Keys.ModelYear]: z.string(),
  [Keys.Make]: z.string(),
  [Keys.Model]: z.string(),
  [Keys.Series]: z.string(),
  [Keys.EngineCylinderCount]: z.string(),
  [Keys.EngineCylinderLabel]: z.string(),
  [Keys.BodyType]: z.string(),
  [Keys.TransmissionType]: z.string(),
  [Keys.Traction]: z.string(),
  [Keys.ExteriorColor]: z.string(),
  [Keys.InteriorColor]: z.string(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.Origin]: '',
  [Keys.Odometer]: '',
  [Keys.OdometerMiles]: '',
  [Keys.StyleId]: '',
  [Keys.ModelYear]: '',
  [Keys.Make]: '',
  [Keys.Model]: '',
  [Keys.Series]: '',
  [Keys.EngineCylinderCount]: '',
  [Keys.EngineCylinderLabel]: '',
  [Keys.BodyType]: '',
  [Keys.TransmissionType]: '',
  [Keys.Traction]: '',
  [Keys.ExteriorColor]: '',
  [Keys.InteriorColor]: '',
};

export const useVehicleForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
