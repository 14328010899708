import { useGetAuthUserQuery } from 'graphql-types';
import useUserStore from './user.store';
import { useEffect } from 'react';
import { useAuth } from '../auth/auth.store';

const useUserInit = () => {
  const setUser = useUserStore((state) => state.setUser);
  const { token } = useAuth();
  const { loading, data } = useGetAuthUserQuery(token ? {} : { skip: true });

  useEffect(() => {
    if (data?.getAuthUser) {
      setUser(data.getAuthUser);
    }
  }, [data]);

  return { isUserLoading: loading };
};

export default useUserInit;
