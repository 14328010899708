import { PeCustomerForm } from 'constants/forms';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import { GOOGLE_MAPS_API_KEY } from 'constants/config/app-variables';

const getAddressComp = (place: any, name: string): string => {
  const item = place?.address_components?.find((it: any) =>
    it?.types?.includes(name)
  );

  return item?.long_name ?? '';
};

const useLocationWidget = () => {
  const { setValue } = useFormContext<PeCustomerForm.FormType>();

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: { types: ['geocode'] },
    onPlaceSelected: (place) => {
      setValue(PeCustomerForm.Keys.Address, place.formatted_address || '', {
        shouldValidate: true,
      });

      setValue(
        PeCustomerForm.Keys.State,
        getAddressComp(place, 'administrative_area_level_1'),
        {
          shouldValidate: true,
        }
      );

      setValue(PeCustomerForm.Keys.City, getAddressComp(place, 'locality'), {
        shouldValidate: true,
      });

      if (getAddressComp(place, 'postal_code')) {
        setValue(
          PeCustomerForm.Keys.PostalCode,
          getAddressComp(place, 'postal_code'),
          { shouldValidate: true }
        );
      }
    },
  });

  return { placeWidgetRef: ref };
};

export default useLocationWidget;
