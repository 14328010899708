import React from 'react';
import { Permissions } from 'constants/entities/user';
import { RoutesConfig } from 'router/routes.config';

const usersManagerRoutes = [
  {
    exact: true,
    path: RoutesConfig.Users.List,
    permission: Permissions.Users.Edit,
    component: React.lazy(() => import('modules/users-listing')),
  },
  {
    exact: true,
    path: RoutesConfig.Users.Edit,
    permission: Permissions.Users.Edit,
    component: React.lazy(() => import('modules/users/Update')),
  },
];

export default usersManagerRoutes;
