import { useSnackbar } from 'notistack';

export const useFeedback = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showWarning = (message: string) =>
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'error',
    });

  const showInfo = (message: string) =>
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      variant: 'info',
    });

  const shwoError = (message: string) =>
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'error',
    });

  const shwoErrorUnique = (message: string, key: number | string) =>
    enqueueSnackbar(message, {
      key,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'error',
    });

  const showSupport = (code: string, message?: string) => {
    console.error(code);

    enqueueSnackbar(
      message || 'Something bad happened. Please try again or contact support.',
      {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        variant: 'error',
      }
    );
  };

  return {
    showSupport,
    showWarning,
    shwoError,
    shwoErrorUnique,
    showInfo,
  };
};
