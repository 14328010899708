import { useAllFeatureForMyQuery } from 'graphql-types';
import { useCallback, useEffect, useMemo } from 'react';
import useFeaturesStore from './features.store';
import { FeatureFlags } from 'constants/entities/features';

const useFeaturesInit = () => {
  const setFeatures = useFeaturesStore((state) => state.setFeatures);
  const { data, loading } = useAllFeatureForMyQuery();

  const turnOnFeature = useMemo(() => {
    return data?.featureForMy.result;
  }, [data]);

  const isHasFeatures = useCallback(
    (name: string): boolean => {
      if (!turnOnFeature) return false;

      const filter = turnOnFeature.find((it) => it.name === name);

      if (!filter?.activate) return false;
      if (filter?.isDefault) return true;

      return true;
    },
    [turnOnFeature]
  );

  useEffect(() => {
    setFeatures({
      isFeatureAdminEnabled: isHasFeatures(FeatureFlags.FeatureAdmin),
      isFeatureAnalyticEnabled: isHasFeatures(FeatureFlags.FeatureAnalytic),
      isFeatureVehicleReport: isHasFeatures(FeatureFlags.ExtendedVehicleReport),
      isConditionManagementEnabled: isHasFeatures(
        FeatureFlags.ConditionManagement
      ),
      isNewNotificationSystem: isHasFeatures(
        FeatureFlags.NewNotificationSystem
      ),
      isFeatureAppraisalConditionsFeature: isHasFeatures(
        FeatureFlags.AppraisalConditions
      ),
      isQuaggaBarcode: isHasFeatures(FeatureFlags.FeatureQuaggaBarcode),
      isExtraPriceSystemPhysicalSystem: isHasFeatures(
        FeatureFlags.ExtraPriceSystemPhysical
      ),
    });
  }, [turnOnFeature]);

  return { isFeaturesLoading: loading };
};

export default useFeaturesInit;
