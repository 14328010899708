import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse,
} from '@react-oauth/google';

import { Paper, Container, Box, Grid, Typography } from '@mui/material';

import { GetAuthUserDocument, useSingInMutation } from 'graphql-types';

import { useAuth } from 'store';
import { GOOGLE_CLIENT_ID } from 'constants/config/app-variables';

import LangSwitcher from 'ui/layouts/lang-switcher';

import { useStyles } from './styles';
import { useApolloClient } from '@apollo/client';

const SingIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { login } = useAuth();

  // const { t } = useTranslation();
  const client = useApolloClient();
  const [signIn] = useSingInMutation();

  const handleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      const { data } = await signIn({
        variables: {
          input: {
            credential: credentialResponse.credential,
          },
        },
      });

      if (data?.signIn) {
        login(data.signIn.token);

        await client.refetchQueries({ include: [GetAuthUserDocument] });

        navigate('/');
      }
    }
  };

  const handleLoginError = () => {
    enqueueSnackbar(t('sign-in.notifications.error'), {
      variant: 'error',
    });
  };

  return (
    <Box className={classes.root}>
      <LangSwitcher color="secondary" className={classes.lang} />

      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.header}>
              <img src="https://cfwww.hgregoire.com/template/img/header/logos/logo.svg"></img>
              <Box pt={4}></Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={{ lg: 4, xs: 2 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box textAlign="center">
                      <Typography variant="h4">
                        {t('common.welcome')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.signBtn}>
                      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                        <GoogleLogin
                          size="large"
                          theme="filled_blue"
                          width="300px"
                          onSuccess={handleLoginSuccess}
                          onError={handleLoginError}
                        />
                      </GoogleOAuthProvider>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SingIn;
