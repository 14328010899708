import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import * as Form from 'ui/inputs';

import {
  Paper,
  Container,
  Typography,
  Box,
  Grid,
  MenuItem,
} from '@mui/material';

import {
  Countries,
  useDealershipsQuery,
  useSignUpMutation,
} from 'graphql-types';
import LoadingButton from 'ui/components/loading-button';

import { useStyles } from './styles';
import LangSwitcher from 'ui/layouts/lang-switcher';
import { useAuth } from 'store';

type Form = {
  country: string;
  dealership: string;
};

const SingUp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const history = useHistory();
  const { login } = useAuth();

  const { data: dData, loading: dLoading } = useDealershipsQuery();
  const [signUp, { loading: sLoading }] = useSignUpMutation();

  const dealershipsUS = useMemo(
    () => dData?.dealerships.filter((it) => it.slug.includes(`US-`)) ?? [],
    [dData]
  );
  const dealershipsCA = useMemo(
    () => dData?.dealerships.filter((it) => !it.slug.includes(`US-`)) ?? [],
    [dData]
  );
  const countries = [
    { name: 'Canada', slug: 'CA' },
    { name: 'USA', slug: 'US' },
  ];

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Form>({
    defaultValues: {
      country: '',
      dealership: '',
    },
  });

  const validation = useMemo(
    () => ({
      country: {
        validate: (value: string) =>
          !value ? (t('sign-up.fields.country.required') as string) : undefined,
      },
      dealership: {
        validate: (value: string) =>
          !value
            ? (t('sign-up.fields.dealership.required') as string)
            : undefined,
      },
    }),
    [t]
  );

  const onSubmit = async () => {
    try {
      const { country, dealership } = getValues();

      const { data } = await signUp({
        variables: { input: { country, dealership } },
      });

      if (data?.signUp) {
        login(data.signUp.token);

        window.location.pathname = '/dashboard/';
      }
    } catch (error) {
      enqueueSnackbar(t('sign-up.notifications.error'), {
        variant: 'error',
      });
    }
  };

  const loading = useMemo(() => dLoading || sLoading, [dLoading, sLoading]);

  return (
    <Box className={classes.root}>
      <LangSwitcher color="secondary" className={classes.lang} />
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.header}>
              <img src="https://cfwww.hgregoire.com/template/img/header/logos/logo.svg"></img>
              <Box pt={4}></Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper>
                {/* 222<pre>{JSON.stringify(user)}</pre>222 */}
                <Box p={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Box textAlign="center">
                        <Typography variant="h4">
                          {t('common.welcome')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{t('sign-up.texts.help')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="country"
                        control={control}
                        rules={validation.country}
                        render={({ field }) => (
                          <Form.TextField
                            select
                            fullWidth
                            data-cy-country
                            variant="outlined"
                            label={t('sign-up.fields.country.label')}
                            disabled={loading}
                            errors={errors.country}
                            {...field}
                          >
                            {countries.map((it) => (
                              <MenuItem
                                key={`country-${it.slug}`}
                                value={it.slug}
                              >
                                {it.name}
                              </MenuItem>
                            ))}
                          </Form.TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="dealership"
                        control={control}
                        rules={validation.dealership}
                        render={({ field }) => (
                          <Form.TextField
                            select
                            fullWidth
                            data-cy-dealership
                            variant="outlined"
                            label={t('sign-up.fields.dealership.label')}
                            disabled={loading || !watch('country')}
                            errors={errors.dealership}
                            {...field}
                          >
                            {watch('country').toLocaleUpperCase() ===
                            Countries.US
                              ? dealershipsUS.map((it) => (
                                  <MenuItem
                                    key={`dealership-${it.id}`}
                                    value={it.slug}
                                  >
                                    {it.name}
                                  </MenuItem>
                                ))
                              : dealershipsCA.map((it) => (
                                  <MenuItem
                                    key={`dealership-${it.id}`}
                                    value={it.slug}
                                  >
                                    {it.name}
                                  </MenuItem>
                                ))}
                          </Form.TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        fullWidth
                        data-cy-sign-in-btn
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        pending={loading}
                      >
                        {t('common.submit')}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SingUp;
