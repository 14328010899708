import React, { PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';
import { RoutesConfig } from '../routes.config';
import { useIsAuthenticated } from 'store';

const AuthGuard = (props: PropsWithChildren) => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <>{props.children}</>;
  }

  return <Navigate to={RoutesConfig.Auth.SignIn} />;
};

export default AuthGuard;
