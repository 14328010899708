export const DECODE_NAMES = ['Manheim', 'Adesa'];

export const US_TAX = 0.1498;

export const appraisalOrigins = [
  {
    id: 'buy',
    appraisal: {
      origin: 'buy',
      vehicleType: 'Live',
      leaseReturn: true,
    },
    label: 'purchase',
    description: 'buyingAtTheDoor',
    cssClass: 'badge-success',
    appraisalRequiredFields: [
      'custFirstName',
      'custLastName',
      'custHomePhone',
      'custEmail',
    ],
  },
  {
    id: 'exchange',
    appraisal: {
      origin: 'exchange',
      vehicleType: 'Live',
      leaseReturn: false,
    },
    label: 'exchange',
    description: 'exchangeFollowingPurchase',
    cssClass: 'badge-danger',
    appraisalRequiredFields: [
      'custFirstName',
      'custLastName',
      'custHomePhone',
      'custEmail',
    ],
  },
  {
    id: 'inventory',
    appraisal: {
      origin: 'inventory',
      vehicleType: 'Inventory',
      leaseReturn: false,
    },
    label: 'inStock',
    description: 'exchangeBetweenStores',
    cssClass: 'badge-warning',
    appraisalRequiredFields: [],
  },
  {
    id: 'service',
    appraisal: {
      origin: 'service',
      vehicleType: 'Live',
      leaseReturn: false,
    },
    label: 'service',
    description: 'repairService',
    cssClass: 'badge-warning',
    appraisalRequiredFields: [
      'custFirstName',
      'custLastName',
      'custHomePhone',
      'custEmail',
    ],
  },
];

export enum EvaluationSource {
  Exchange = 'exchange',
  Buy = 'buy',
  Service = 'service',
  Inventory = 'inventory',
}

export const origins = [
  {
    id: 'exchange',
    appraisal: {
      origin: 'exchange',
      vehicleType: 'Live',
      leaseReturn: false,
    },
    label: 'Échange',
    description: 'Échange suite à un achat',
    cssClass: 'badge-danger',
    appraisalRequiredFields: [
      'custFirstName',
      'custLastName',
      'custHomePhone',
      'custEmail',
    ],
  },
  {
    id: 'buy',
    appraisal: {
      origin: 'buy',
      vehicleType: 'Live',
      leaseReturn: true,
    },
    label: 'Achat',
    description: 'Achat à la porte',
    cssClass: 'badge-success',
    appraisalRequiredFields: [
      'custFirstName',
      'custLastName',
      'custHomePhone',
      'custEmail',
    ],
  },
  {
    id: 'service',
    appraisal: {
      origin: 'service',
      vehicleType: 'Live',
      leaseReturn: false,
    },
    label: 'Service',
    description: 'Service de réparation',
    cssClass: 'badge-warning',
    appraisalRequiredFields: [
      'custFirstName',
      'custLastName',
      'custHomePhone',
      'custEmail',
    ],
  },
  {
    id: 'inventory',
    appraisal: {
      origin: 'inventory',
      vehicleType: 'Inventory',
      leaseReturn: false,
    },
    label: 'En inventaire',
    description: 'Échange entre magasin',
    cssClass: 'badge-warning',
    appraisalRequiredFields: [],
  },
];
