import React from 'react';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLanguage } from 'store';

type LangSwitcherProps = IconButtonProps;

const useStyles = makeStyles({
  langText: {
    color: 'inherit',
    fontWeight: 700,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '18px',
  },
});

const LangSwitcher = (props: LangSwitcherProps) => {
  const classes = useStyles();
  const { language, toggleLanguage } = useLanguage();

  return (
    <IconButton {...props} onClick={toggleLanguage} size="large">
      <Box className={classes.langText}>{language === 'en' ? 'EN' : 'FR'}</Box>
    </IconButton>
  );
};

export default LangSwitcher;
