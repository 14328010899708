import React from 'react';
import { RoutesConfig } from 'router/routes.config';

const percentSettingsRoutes = [
  {
    path: RoutesConfig.PercentSettings,
    exact: true,
    component: React.lazy(() => import('modules/procent-settings/List')),
  },
];

export default percentSettingsRoutes;
