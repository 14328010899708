import React, { useCallback, useRef, useState } from 'react';
import { Capture, Controls } from '@monkvision/camera';
import { Box } from '@mui/material';

import { useStyles } from './styles';
import { useMonitoring } from '@monkvision/corejs';
import { blobToBase64Jpg } from '../../../../utils/blob-to-base64-jpg';
import { initialColors } from '../../../../constants/config/monkia.config';

export type TakePictureArg = {
  picture: string;
  sight: string;
};

type ImageLoadedType = {
  isZoomedPicture: boolean;
  picture: {
    canvasHeight: number;
    canvasWidth: number;
    imageFilenameExtension: string;
    imageType: string;
    uri: string;
  };
  sight: string;
};

type CameraProps = {
  inspectionId: string;
  sightIds: string[];
  onClose: () => void;
  onTakePicture: (arg: TakePictureArg) => Promise<void>;
};

interface TakenPicture {
  uri: string;
}

type TakenPictures = Record<string, TakenPicture>;

const CameraWeb: React.FC<CameraProps> = ({
  inspectionId,
  sightIds,
  onClose,
  onTakePicture,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const { errorHandler } = useMonitoring();

  const uploadedRef = useRef(new Map<string, true>());

  const uploadNewPictures = async (
    takenPictures: TakenPictures
  ): Promise<void> => {
    for (const pic of Object.keys(takenPictures)) {
      if (!uploadedRef.current.has(pic)) {
        await onLoadImage(pic);
        uploadedRef.current.set(pic, true);
      }
    }
  };

  const handleChange = useCallback(
    async (state: any) => {
      const END_ENDPOINT = 'qhKA2z';
      try {
        const { takenPictures, tour, current } = state.sights.state;

        await uploadNewPictures(takenPictures);

        const totalPictures = Object.keys(tour).length;
        const hasAllPictures =
          Object.keys(takenPictures).length === totalPictures;

        if (hasAllPictures && END_ENDPOINT === current?.id) {
          onClose();
        }

        if (hasAllPictures && totalPictures <= 1) {
          onClose();
        }
      } catch (err) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler]
  );

  const onLoadImage = useCallback(async (pictureData: any) => {
    setLoading(true);
    try {
      const {
        picture: { uri, canvasWidth, canvasHeight },
        sight,
      } = pictureData;

      await onTakePicture({
        picture: await blobToBase64Jpg(uri, canvasWidth, canvasHeight),
        sight,
      });
    } catch (error) {
      errorHandler(error);
      console.log('errorHandler MONK', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const controls = [
    [
      {
        disabled: loading,
        ...Controls.AddDamageButtonProps,
      },
      {
        disabled: loading,
        ...Controls.CaptureButtonProps,
      },
    ],
    {
      disabled: loading,
      onPress: () => onClose(),
      ...Controls.GoBackButtonProps,
    },
  ];

  return (
    <Box className={classes.root}>
      <Capture
        inspectionId={inspectionId}
        controls={controls}
        loading={loading}
        enableComplianceCheck={false}
        sightIds={sightIds}
        primaryColor={process.env.REACT_APP_PRIMARY_COLOR_DARK}
        onReady={() => setLoading(false)}
        colors={{ ...initialColors }}
        settings={{ resolution: 'QHD' }}
        onPictureTaken={onLoadImage}
        onChange={handleChange}
      />
    </Box>
  );
};

export default CameraWeb;
