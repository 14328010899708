import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  height: 'calc(100% - 160px)',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(6),
  padding: '0 ' + theme.spacing(3),
}));

export const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});
