import React from 'react';
import { RoutesConfig } from 'router/routes.config';
import { Permissions } from 'constants/entities/user';
import EditEvalPage from 'modules/physical-eval-edit';

const physicalEvaluationRoutes = [
  {
    path: RoutesConfig.PhysicalEvaluation.List,
    exact: true,
    component: React.lazy(() => import('modules/physical-eval-listing')),
    permission: Permissions.PhysicalEvaluation.GetList,
  },
  {
    path: RoutesConfig.PhysicalEvaluation.Create,
    exact: true,
    component: React.lazy(() => import('modules/physical-eval-create')),
    permission: Permissions.PhysicalEvaluation.Create,
  },
  {
    path: RoutesConfig.PhysicalEvaluation.ViewOne,
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/View')),
    permission: Permissions.PhysicalEvaluation.GetOne,
  },
  {
    path: RoutesConfig.PhysicalEvaluation.Edit,
    exact: true,
    component: EditEvalPage,
    permission: Permissions.PhysicalEvaluation.Update,
  },

  {
    path: RoutesConfig.PhysicalEvaluation.ConditionReport,
    exact: true,
    component: React.lazy(
      () => import('modules/physical-evaluation/Condition')
    ),
    permission: `physical-evaluation:get-one`,
  },
  {
    path: RoutesConfig.PhysicalEvaluation.Reminder,
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/Reminder')),
    permission: `physical-evaluation:get-reminder`,
  },
  {
    path: RoutesConfig.PhysicalEvaluation.EditUser,
    exact: true,
    component: React.lazy(
      () => import('modules/physical-evaluation/Edit-user')
    ),
    permission: `physical-evaluation:update`,
    // permission: `physical-evaluation:edit-user`,
  },
  {
    path: RoutesConfig.PhysicalEvaluation.Appraise,
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/Appraise')),
    permission: `physical-evaluation:appraise`,
  },
];

export default physicalEvaluationRoutes;
