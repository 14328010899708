import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  FirstName = 'custFirstName',
  LastName = 'custLastName',
  HomePhone = 'custHomePhone',
  Email = 'custEmail',
  Address = 'custAddress',
  City = 'custCity',
  State = 'custState',
  PostalCode = 'custPostalCode',
}

export const Schema = z.object({
  [Keys.FirstName]: z.string().min(1, 'common.validation.required'),
  [Keys.LastName]: z.string().min(1, 'common.validation.required'),
  [Keys.HomePhone]: z
    .string()
    .min(1, 'common.validation.required')
    .refine((phone) => !phone.includes('_'), 'common.validation.required'),
  [Keys.Email]: z
    .string()
    .email('common.validation.email')
    .optional()
    .or(z.literal('')),
  [Keys.Address]: z.string().min(1, 'common.validation.required'),
  [Keys.City]: z.string(),
  [Keys.State]: z.string(),
  [Keys.PostalCode]: z.string(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.FirstName]: '',
  [Keys.LastName]: '',
  [Keys.HomePhone]: '',
  [Keys.Email]: '',
  [Keys.Address]: '',
  [Keys.City]: '',
  [Keys.State]: '',
  [Keys.PostalCode]: '',
};

export const useCustomerForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
