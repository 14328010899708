import { useFormContext, useWatch } from 'react-hook-form';
import { DisclosuresUsInput } from 'graphql-types';
import { useMemo } from 'react';
import { RequiredQuestions } from '../../../constants/values';
import { PeQuestionsForm } from 'constants/forms';

const useQuestions = () => {
  const { setValue, getValues, formState } =
    useFormContext<PeQuestionsForm.FormType>();

  const questionList: DisclosuresUsInput[] = useWatch<PeQuestionsForm.FormType>(
    { name: PeQuestionsForm.Keys.QuestionList }
  );

  const errors: string[] = useMemo(() => {
    if (formState.errors[PeQuestionsForm.Keys.QuestionList]) {
      console.log(questionList);
      return RequiredQuestions.filter(
        (requiredName) =>
          !questionList.find((it) => it.name === requiredName)?.value?.trim()
      );
    }

    return [];
  }, [formState.errors, questionList]);

  const updateHandler = (
    question: string,
    value: string,
    text?: string,
    children?: string[]
  ) => {
    const currentList = getValues(PeQuestionsForm.Keys.QuestionList).filter(
      (it) => it.name !== question
    );

    setValue(PeQuestionsForm.Keys.QuestionList, [
      ...currentList,
      {
        name: question,
        value,
        text: text ?? '',
        children: children ? children : [],
      },
    ]);
  };

  const findQuestion = useMemo(() => {
    return questionList.reduce(
      (acc, question) => ({
        ...acc,
        [question.name as string]: question,
      }),
      {} as Record<string, DisclosuresUsInput>
    );
  }, [questionList]);

  return { errors, updateHandler, findQuestion };
};

export default useQuestions;
