import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

type SettingsStore = {
  language: 'fr' | 'en';
  sidebarMode: SidebarMode;

  toggleLanguage: () => void;
  changeMode: (mode: SidebarMode) => void;
  closeSidebar: () => void;
};

const useSettingsStore = create<SettingsStore>()(
  persist<SettingsStore>(
    (set) => ({
      language: 'fr',
      sidebarMode: 'full',

      toggleLanguage: () =>
        set((state) => ({ language: state.language === 'fr' ? 'en' : 'fr' })),

      changeMode: (sidebarMode) => set({ sidebarMode }),
      closeSidebar: () => set({ sidebarMode: 'close' }),
    }),
    {
      name: 'settings-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useSettings = useSettingsStore;

export const useLanguage = () =>
  useSettingsStore(
    useShallow((state) => ({
      language: state.language,
      toggleLanguage: state.toggleLanguage,
    }))
  );

export const useSidebar = () =>
  useSettingsStore(
    useShallow((state) => ({
      sidebarMode: state.sidebarMode,
      changeMode: state.changeMode,
      closeSidebar: state.closeSidebar,
    }))
  );

export default useSettingsStore;
