import React, { PropsWithChildren } from 'react';

import { useIsAuthenticated } from 'store';
import { Navigate } from 'react-router-dom';
import { RoutesConfig } from 'router/routes.config';

const GuestGuard = (props: PropsWithChildren) => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <>{props.children}</>;
  }

  return <Navigate to={RoutesConfig.Dashboard} />;
};

export default GuestGuard;
