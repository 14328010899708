import useStore from 'store/pe-edit-appraisal.store';
import { useFormContext } from 'react-hook-form';
import { PeEquipmentForm } from 'constants/forms';
import { useEffect } from 'react';

const useResetEquipment = () => {
  const { appraisal } = useStore();
  const { reset } = useFormContext<PeEquipmentForm.FormType>();

  useEffect(() => {
    if (appraisal) {
      reset({
        [PeEquipmentForm.Keys.Options]: appraisal.optionsVinDecodeBB ?? [],
      });
    } else {
      reset(PeEquipmentForm.defaultValues);
    }
  }, [appraisal]);
};

export default useResetEquipment;
