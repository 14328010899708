import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { SIGN_IN_URL } from 'constants/config/app-variables';

interface AuthStore {
  isAuthenticated: () => boolean;
  token: string | null;
  redirect: () => void;
  login: (token: string) => void;
  logout: () => void;
}

const CRITICAL_SIZE_TOKEN = 250;

const useAuthStore = create<AuthStore>()(
  persist<AuthStore>(
    (set, get) => ({
      token: null,

      isAuthenticated: () => {
        const token = get().token as string;

        if (!token || (!!token && token.length > CRITICAL_SIZE_TOKEN)) {
          return false;
        }

        if (token === 'test') {
          return true;
        }

        return !!token;
      },

      login: (token) => set({ token }),

      logout: () => set({ token: null }),

      redirect: () => {
        window.location.href = SIGN_IN_URL;
      },
    }),

    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useIsAuthenticated = () =>
  useAuthStore((state) => state.isAuthenticated());

export const useAuth = useAuthStore;

export default useAuthStore;
