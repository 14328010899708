export const RoutesConfig = {
  Auth: {
    SignIn: '/sign-in/',
    SignUp: '/sign-up/',
  },

  Dashboard: '/dashboard/',
  NotFound: '/404/',
  PercentSettings: '/percent-settings/',

  Profile: {
    Me: '/profile/me/',
    Notifications: '/profile/notification/',
    NotificationSystem: '/profile/notification-system/',
  },

  Users: {
    List: '/users/',
    Edit: '/users/user/:id/',
  },

  Assessor: {
    List: '/assessor/',
  },

  Analytics: {
    Dashboard: '/analytics/',
    CameraList: '/analytics/camera/',
    CameraViewOne: '/analytics/camera/:id/',
    BadStartComplete: '/analytics/bad-start-and-complete/',
    BadVinDecode: '/analytics/bad-vin-decode/',
    PhysicalEvalPrice: '/analytics/physical-evaluation-price/',
  },

  SalesManagerPrice: {
    Dashboard: '/sales-manager-price/',
    MyStatistic: '/statistics-my/',
    List: '/sales-manager-price/list/:ids',
    ViewOne: '/sales-manager-price/vehicle/:id/:ids',
  },

  AppraisalConditions: {
    List: '/appraisal-conditions/',
    Create: '/appraisal-conditions/add/',
    Edit: '/appraisal-conditions/:id/edit/',
    ViewOne: '/appraisal-conditions/:id/',
  },

  AppraisalPrice: {
    Statistic: '/appraisal-price/',
    List: '/appraisal-price/list/:ids',
    ViewOne: '/appraisal-price/vehicle/:id/:ids',
  },

  ConditionDamage: {
    List: '/variant-car/',
    Manage: '/variant-car/:vehicleType/',
  },

  PhysicalEvaluation: {
    List: '/physical-evaluation',
    Create: '/physical-evaluation/create',
    ViewOne: '/physical-evaluation/:id',
    Edit: '/physical-evaluation/:id/edit',
    ConditionReport: '/physical-evaluation/:id/condition-report',
    Reminder: '/physical-evaluation/:id/reminder',
    EditUser: '/physical-evaluation/:id/edit-user',
    Appraise: '/physical-evaluation/:id/appraise',
  },

  VirtualEvaluation: {
    List: '/listing/',
    Create: '/listing/create',
    ViewOne: '/listing/:id',
    Edit: '/listing/:id/edit/',

    Appraise: '/listing/:id/appraise/',
    AppraiseValue: '/listing/:id/appraisalValue/',
  },

  Work: {
    List: '/work/',
    ViewOne: '/work/:id/',
  },

  UserEvaluation: {
    List: '/user-evaluation/',
    ViewOne: '/user-evaluation/:id/',
  },
};
