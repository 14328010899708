import React, { FC, memo, useMemo } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';
import { AddDeductListItem } from 'graphql-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { PeEquipmentForm } from 'constants/forms';
import { useUserChecks } from 'store';
import { Permissions } from 'constants/entities/user';

type Props = {
  item: AddDeductListItem;
};

const EquipmentCheckBox: FC<Props> = ({ item }) => {
  const { isHasAccess } = useUserChecks();

  const { setValue } = useFormContext<PeEquipmentForm.FormType>();

  const selectedOptions: AddDeductListItem[] =
    useWatch<PeEquipmentForm.FormType>({
      name: PeEquipmentForm.Keys.Options,
    });

  const isChecked = useMemo(() => {
    return selectedOptions.some((option) => option.name === item.name);
  }, [selectedOptions]);

  const isCheckedByAuto = item.auto === 'Y';

  const handleChange = () => {
    if (isChecked) {
      return setValue(
        PeEquipmentForm.Keys.Options,
        selectedOptions.filter(
          (option) => option.name !== item.name
        ) as PeEquipmentForm.FormType['optionsVinDecodeBB']
      );
    }

    setValue(PeEquipmentForm.Keys.Options, [
      ...selectedOptions,
      item,
    ] as PeEquipmentForm.FormType['optionsVinDecodeBB']);
  };

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Checkbox
        id={item.name ?? undefined}
        disableRipple
        color="primary"
        onChange={handleChange}
        checked={isChecked || isCheckedByAuto}
        disabled={isCheckedByAuto}
      />

      <Typography
        variant="h5"
        component="label"
        htmlFor={item.name ?? undefined}
      >
        {`${item.name} `}

        {isHasAccess(Permissions.PhysicalEvaluation.GetPrice) && (
          <Typography sx={{ display: 'inline' }}>({item.avg}$)</Typography>
        )}
      </Typography>
    </Stack>
  );
};

export default memo(EquipmentCheckBox);
