import {
  AppraisalPhoto,
  AppraisalVehByIdCoreDocument,
  AppraisalVehByIdCoreQuery,
  AppraisalVehByIdCoreQueryVariables,
  useUploadAppraisalPhotoReactiveMutation,
} from 'graphql-types';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { openDB } from 'idb';
import useStore from '../store/pe-edit-appraisal.store';

type Job = {
  id: number;
  zone: string;
  src: string;
};

enum ActionType {
  INIT,
  ADD,
  SHIFT,
  EMPTY,
  SHUFFLE,
}

type ActionShift = {
  type: ActionType.SHIFT;
  job: Job;
};

type ActionShuffle = {
  type: ActionType.SHUFFLE;
};

type ActionInit = {
  type: ActionType.INIT;
  jobs: Job[];
};

type ActionAdd = {
  type: ActionType.ADD;
  job: Job;
};

type ImageOfflineQueueContextType = {
  jobs: Job[];
  addImage: (job: Job) => void;
};

const ImageOfflineQueueContext = createContext<
  ImageOfflineQueueContextType | undefined
>(undefined);

const jobsReducer = (
  jobs: Job[],
  action: ActionShift | ActionInit | ActionAdd | ActionShuffle
) => {
  switch (action.type) {
    case ActionType.INIT: {
      return [...action.jobs];
    }
    case ActionType.ADD: {
      return [...jobs, action.job];
    }
    case ActionType.SHIFT: {
      return jobs.filter((el) => el.zone !== action.job.zone);
    }
    case ActionType.SHUFFLE:
      // rendomize array to prevent stuck on one image
      return jobs.sort(() => 0.5 - Math.random());
    default:
      return jobs;
  }
};

const TABLE_NAME = 'jobs';
const dbPromise = async () => {
  const db = await openDB('cache', 1, {
    upgrade(db) {
      db.createObjectStore(TABLE_NAME);
    },
  });

  return db;
};

const getJobs = async () => {
  const db = await dbPromise();
  const images = await db.getAll(TABLE_NAME);
  return images.map((it) => it as Job);
};

const setJobs = async (jobs: Job[]) => {
  const db = await dbPromise();
  await db.clear(TABLE_NAME);

  for (const job of jobs) {
    try {
      await db.put(TABLE_NAME, job, `${job.id}-${job.zone}`);
    } catch (error) {
      console.log('setJobs', error);
    }
  }
};

const wait = () => new Promise((reolver) => setTimeout(reolver, 3000));

export const ImageOfflineQueueProvider = (props: PropsWithChildren) => {
  const [isExecutingTask, setIsExecutingTask] = useState(false);

  const { setAppraisalPhoto } = useStore();

  const [mutate] = useUploadAppraisalPhotoReactiveMutation({
    update: (cache, result) => {
      const photo = result.data?.uploadAppraisalPhotoReactive.result;

      if (photo && photo.id) {
        const query = cache.readQuery<
          AppraisalVehByIdCoreQuery,
          AppraisalVehByIdCoreQueryVariables
        >({
          query: AppraisalVehByIdCoreDocument,
          variables: { id: `${photo.id}` },
        });

        if (!query) return;

        const data = {
          ...query,
          appraisal: {
            ...query.appraisalVehByIdCore,
            result: {
              ...query.appraisalVehByIdCore.result,
              photos: [
                ...query.appraisalVehByIdCore.result.photos.filter(
                  (it) => it.zone !== photo.zone
                ),
                photo as AppraisalPhoto,
              ],
            },
          },
        };

        setAppraisalPhoto(photo);

        cache.writeQuery<
          AppraisalVehByIdCoreQuery,
          AppraisalVehByIdCoreQueryVariables
        >({
          query: AppraisalVehByIdCoreDocument,
          variables: { id: `${photo.id}` },
          data,
        });
      }
    },
  });
  const [jobs, dispatch] = useReducer(jobsReducer, []);

  useEffect(() => {
    const init = async () => {
      const jobs = await getJobs();

      dispatch({ type: ActionType.INIT, jobs });
    };

    init();
  }, []);

  useEffect(() => {
    const update = async () => {
      await setJobs(jobs);
    };

    update();
  }, [jobs]);

  useEffect(() => {
    const func = async () => {
      if (jobs.length > 0 && !isExecutingTask) {
        setIsExecutingTask(true);

        const job = jobs[0];
        try {
          await mutate({
            variables: {
              file: job,
              id: job.id,
            },
          });

          dispatch({ type: ActionType.SHIFT, job });
        } catch (error) {
          if (error instanceof Error) {
            dispatch({ type: ActionType.SHIFT, job });
          }

          await wait();
          dispatch({ type: ActionType.SHUFFLE });
        }
        setIsExecutingTask(false);
      }
    };

    func();
  }, [jobs, isExecutingTask]);

  const addImage = useCallback(
    (image: Job) => {
      dispatch({ type: ActionType.ADD, job: image });
    },
    [jobs]
  );

  return (
    <ImageOfflineQueueContext.Provider
      value={{
        jobs,
        addImage,
      }}
    >
      {props.children}
    </ImageOfflineQueueContext.Provider>
  );
};

export default ImageOfflineQueueContext;
