import React from 'react';
import { RoutesConfig } from 'router/routes.config';

const conditionDamage = [
  {
    path: RoutesConfig.ConditionDamage.List,
    exact: true,
    component: React.lazy(() => import('modules/condition-damage/List')),
  },
  {
    path: RoutesConfig.ConditionDamage.Manage,
    exact: true,
    component: React.lazy(() => import('modules/condition-damage/Manage')),
  },
];

export default conditionDamage;
