import {
  Countries,
  useBbInfoValuesMutation,
  useGetCarGurusByVinLazyQuery,
  useGetCarstoryByVinMutation,
  useGetOffersByVinLazyQuery,
} from 'graphql-types';
import React, { createContext, PropsWithChildren, useState } from 'react';
import { CA_LOCATION, US_LOCATION } from '../constants/entities/location';

type ReatailPricesType = {
  adjustedRetailAvg: number;
  adjustedRetailClean: number;
  adjustedRetailRough: number;
  adjustedRetailXclean: number;
  baseRetailAvg: number;
  baseRetailClean: number;
  baseRetailRough: number;
  baseRetailXclean: number;
};

type WholeSalePrices = {
  adjustedWholeAvg: number;
  adjustedWholeClean: number;
  adjustedWholeRough: number;
  adjustedWholeXclean: number;
  baseWholeAvg: number;
  baseWholeClean: number;
  baseWholeRough: number;
  baseWholeXclean: number;
};

type TradeinPrices = {
  adjustedTradeinAvg: number;
  adjustedTradeinClean: number;
  adjustedTradeinRough: number;
  adjustedTradeinXclean: number;
  baseTradeinAvg: number;
  baseTradeinClean: number;
  baseTradeinRough: number;
  baseTradeinXclean: number;
};

type BbInfoValuesType = {
  reatailPrices: ReatailPricesType;
  wholeSalePrices: WholeSalePrices;
  tradeinPrices: TradeinPrices;
};

type PhysicalCardContextType = {
  isLoading: boolean;
  isLoadingGetOffersByVin: boolean;
  isLoadingGetCarGurusByVIN: boolean;
  isLoadingGetCarstoryByVIN: boolean;
  drivlyGeneralValues: DrivlyGeneralType | null;
  bbInfoValues: BbInfoValuesType | null;
  carGurusByValues: CarGurusByVINType | null;
  carstoryByVINValues: GetCarstoryByVIN | null;
  onBBInfoHandler: (input: BBInfoInputType) => void;
  checkCountryCar: (locationCar: string) => string;
  getOffersByVIN: (vin: string) => any;
  onGetCarGurusByVINHandler: (vin: string) => any;
  onGetCarstoryByVINHandler: (vin: string) => any;
};

type DrivlyGeneralType = {
  carmaxDeclineReason: string;
  carmaxError: any;
  carmaxPrice: number;
  carmaxUrl: string;
  carvanaError: any;
  carvanaPrice: number;
  carvanaUrl: string;
  make: string;
  mileage: number;
  model: string;
  retry: any;
  vin: string;
  vroomError: any;
  vroomGrade: string;
  vroomPrice: number;
  vroomUrl: string;
  year: number;
};

export type BBInfoInputType = {
  vin: string;
  lang: string;
  country: string;
  styleId: string;
  series: string;
  mileage: string;
};

export type CarGurusByVINType = {
  vin: string;
  retail: string;
  privateParty: string;
  trade: string;
  error: string;
};

export type GetCarstoryByVIN = {
  error?: string;
  bedStyle: string;
  bodyStyle: string;
  cabStyle: string;
  condition: string;
  conditionTimestamp: number;
  confidenceScore4: string;
  doors: number;
  driveType: string;
  engine: string;
  engineDescription: string;
  engineDisplacement: string;
  exteriorColor: string;

  fuel: string;
  generation: number;
  interiorColor: string;
  make: string;
  mileage: number;
  mileageTimestamp: number;
  model: string;
  mpg: number;
  mpgCity: number;
  mpgHighway: number;
  subGeneration: number;
  subModel: string;
  subTrim: string;
  transmission: string;
  transmissionDetails: string;
  trim: string;
  vin: string;
  wheelbase: string;
  year: number;
};

const PhysicalCardContext = createContext<PhysicalCardContextType | undefined>(
  undefined
);

export const PhysicalCardProvider = (props: PropsWithChildren) => {
  const [bbInfoValues, setBbInfoValues] = useState<BbInfoValuesType | null>(
    null
  );
  const [drivlyGeneralValues, setDrivlyGeneralValues] =
    useState<DrivlyGeneralType | null>(null);

  const [carGurusByValues, setCarGurusByValues] =
    useState<CarGurusByVINType | null>(null);

  const [carstoryByVINValues, setCarstoryByVINValues] =
    useState<GetCarstoryByVIN | null>(null);

  const [getBBInfo, { loading }] = useBbInfoValuesMutation({
    onCompleted: (e) => {
      const result = e?.bbInfoValues?.result ? e.bbInfoValues.result : null;
      if (result) {
        const { reatailPrices, wholeSalePrices, tradeinPrices } = result;
        setBbInfoValues({
          reatailPrices,
          wholeSalePrices,
          tradeinPrices,
        } as BbInfoValuesType);
      }
    },
  });

  const [getOffersByVinQuery, { loading: loadingGetOffersByVin }] =
    useGetOffersByVinLazyQuery({
      onCompleted: (e) => {
        const result = e?.getOffersByVIN ? e.getOffersByVIN : null;

        if (result) {
          setDrivlyGeneralValues(result as DrivlyGeneralType);
        }
      },
    });

  const [getCarGurusByVIN, { loading: loadingGetCarGurusByVIN }] =
    useGetCarGurusByVinLazyQuery({
      onCompleted: (e) => {
        const result = e.getCarGurusByVIN ? e.getCarGurusByVIN : null;
        if (result) {
          setCarGurusByValues(result as CarGurusByVINType);
        }
      },
    });

  const [getCarstoryByVIN, { loading: loadingGetCarstoryByVIN }] =
    useGetCarstoryByVinMutation({
      onCompleted: (e) => {
        const result = e.getCarstoryByVIN?.result?.carstory?.vehicleInfo
          ? e.getCarstoryByVIN.result.carstory.vehicleInfo
          : null;
        if (result) {
          setCarstoryByVINValues(result as GetCarstoryByVIN);
        }
      },
    });

  const onBBInfoHandler = (input: BBInfoInputType) => {
    getBBInfo({
      variables: {
        input,
      },
    });
  };

  const checkCountryCar = (locationCar: string): string => {
    if (CA_LOCATION.includes(locationCar)) {
      return Countries.CA;
    }

    if (US_LOCATION.includes(locationCar)) {
      return Countries.US;
    }

    return Countries.CA;
  };

  const getOffersByVIN = async (vin: string) => {
    getOffersByVinQuery({ variables: { vin, mileage: '0', zipCode: '' } });
  };

  const onGetCarGurusByVINHandler = async (vin: string) => {
    getCarGurusByVIN({
      variables: { vin },
    });
  };

  const onGetCarstoryByVINHandler = async (vin: string) => {
    getCarstoryByVIN({
      variables: {
        input: {
          url: `https://offers.dev.driv.ly/api/vin/${vin}/carstory`,
        },
      },
    });
  };

  return (
    <PhysicalCardContext.Provider
      value={{
        isLoading: loading,
        isLoadingGetOffersByVin: loadingGetOffersByVin,
        isLoadingGetCarGurusByVIN: loadingGetCarGurusByVIN,
        isLoadingGetCarstoryByVIN: loadingGetCarstoryByVIN,
        bbInfoValues,
        drivlyGeneralValues,
        carGurusByValues,
        carstoryByVINValues,
        onBBInfoHandler,
        onGetCarGurusByVINHandler,
        onGetCarstoryByVINHandler,
        checkCountryCar,
        getOffersByVIN,
      }}
    >
      {props.children}
    </PhysicalCardContext.Provider>
  );
};

export default PhysicalCardContext;
