import { useContext } from 'react';
import ImageOfflineQueueContext from 'contexts/ImageOfflineQueue';

const useImageOfflineQueue = () => {
  try {
    const context = useContext(ImageOfflineQueueContext);

    if (!context) throw new Error('ImageOfflineQueueContext not provided');

    return context;
  } catch (error) {
    console.log('Error in useImageOfflineQueue', error);
    throw error;
  }
};

export default useImageOfflineQueue;
