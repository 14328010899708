import { useUser } from 'store';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = 'G-F4PNYZ2HFZ'; // OUR_TRACKING_ID

type ProblemWithVehicleType = {
  vin: string;
  vAutoId: string;
  message: string;
  location: string;
};

type CompleteReportType = {
  vin: string;
};

type UseAnalyticsType = {
  startGaTracker: () => void;
  eventUserCamera: (isPermission: boolean) => void;
  eventBadVinNumber: (vin: string) => void;
  problemWithStartVehicle: (input: ProblemWithVehicleType) => void;
  problemWithCompleteVehicle: (input: ProblemWithVehicleType) => void;
  completeReport: (input: CompleteReportType) => void;
  sendVinToCarfaxNumber: (vin: string) => void;
};

const useAnalytics = (): UseAnalyticsType => {
  const user = useUser();

  const startGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
      if (!user) return;

      ReactGA.initialize(TRACKING_ID, {
        gtagOptions: {
          userId: user?.email,
        },
      });
      ReactGA.ga('set', 'userId', user.email);

      setInitialized(true);
    }, [user]);

    useEffect(() => {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }, [initialized, location]);
  };

  const eventUserCamera = (isPermission: boolean) => {
    if (!user) return;

    ReactGA.gtag('event', 'user_camera', {
      isCameraPermissionOn: isPermission,
      sentEventTime: `${new Date().toISOString()}`,
      isCameraForUserOn: `${user.email} - ${isPermission}`,
      userId: user.id,
      userEmail: user.email,
    });
  };

  const eventBadVinNumber = (vin: string) => {
    if (!user) return;

    ReactGA.gtag('event', 'bad_vin_decode', {
      sentEventTime: `${new Date().toISOString()}`,
      vin: vin,
      userId: user.id,
      userEmail: user.email,
    });
  };

  const problemWithStartVehicle = (input: ProblemWithVehicleType) => {
    if (!user) return;

    ReactGA.gtag('event', 'problem_with_start_vehicle', {
      vin: input.vin,
      sentEventTime: `${new Date().toISOString()}`,
      userId: user.id,
      userEmail: user.email,
      messageError: input.message,
    });
  };

  const problemWithCompleteVehicle = (input: ProblemWithVehicleType) => {
    if (!user) return;

    ReactGA.gtag('event', 'problem_with_complete_vehicle', {
      vin: input.vin,
      sentEventTime: `${new Date().toISOString()}`,
      userId: user.id,
      userEmail: user.email,
      messageError: input.message,
    });
  };

  const completeReport = (input: CompleteReportType) => {
    if (!user) return;

    ReactGA.gtag('event', 'condition_report_complete', {
      vin: input.vin,
      sentEventTime: `${new Date().toISOString()}`,
      userId: user.id,
      userEmail: user.email,
      country: user.country,
      dealership: user.dealership,
    });
  };

  const sendVinToCarfaxNumber = (vin: string) => {
    if (!user) return;

    ReactGA.gtag('event', 'send_vin_to_carfax_number', {
      sentEventTime: `${new Date().toISOString()}`,
      vin: vin,
      userId: user.id,
      userEmail: user.email,
    });
  };

  return {
    startGaTracker,
    eventUserCamera,
    completeReport,
    eventBadVinNumber,
    problemWithStartVehicle,
    problemWithCompleteVehicle,
    sendVinToCarfaxNumber,
  };
};

export default useAnalytics;
