export enum FeatureFlags {
  FeatureAdmin = 'FEATURE_ADMIN',
  ConditionManagement = 'CONDITION_MANAGEMENT',
  ExtendedVehicleReport = 'EXTENDED_VEHICLE_REPORT',
  AppraisalConditions = 'APPRAISAL_CONDITIONS_FEATURE',
  FeatureAnalytic = 'FEATURE_ANALYTIC',
  FeatureQuaggaBarcode = 'FEATURE_QUAGGA_BARCODE',
  NewNotificationSystem = 'NEW_NOTIFICATION_SYSTEM',
  ExtraPriceSystemPhysical = 'EXTRA_PRICE_SYSTEM_PHYSICAL',
}
