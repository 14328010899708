import useStore from 'store/pe-edit-appraisal.store';
import {
  useNewBlackBookDecoderQuery,
  useNewBlackBookDetailDecoderLazyQuery,
} from 'graphql-types';
import { useMemo } from 'react';
import { IOption } from '../../../../../constants/entities/ui/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { PeVehicleForm } from 'constants/forms';
import { VehicleDetailKeys } from '../../../../physical-eval-edit/constants/values';

const useBlackBookController = () => {
  const { appraisal } = useStore();

  const { setValue } = useFormContext<PeVehicleForm.FormType>();

  const selectedSeries = useWatch<PeVehicleForm.FormType>({
    name: PeVehicleForm.Keys.Series,
  });

  /*
   * Fetches the vehicle information from the VIN
   */
  const { data: decodeVinInfo, loading: isDecodeLoading } =
    useNewBlackBookDecoderQuery(
      appraisal?.vin ? { variables: { vin: appraisal?.vin } } : { skip: true }
    );

  const [fetchStyles, { data: stylesData, loading: isFetchingStyles }] =
    useNewBlackBookDetailDecoderLazyQuery({
      onCompleted: ({ newBlackBookDetailDecoder }) => {
        if (
          !newBlackBookDetailDecoder.trims.find(
            (series) => series.value === selectedSeries
          )?.styles?.length
        ) {
          setValue(
            PeVehicleForm.Keys.EngineCylinderLabel,
            newBlackBookDetailDecoder?.engine ?? ''
          );

          setValue(
            PeVehicleForm.Keys.EngineCylinderCount,
            newBlackBookDetailDecoder?.engineCylinderCount ?? ''
          );

          setValue(
            PeVehicleForm.Keys.Traction,
            newBlackBookDetailDecoder?.traction ?? ''
          );

          setValue(
            PeVehicleForm.Keys.TransmissionType,
            newBlackBookDetailDecoder?.transmission ?? ''
          );

          return;
        }

        setValue(PeVehicleForm.Keys.EngineCylinderLabel, '');
        setValue(PeVehicleForm.Keys.EngineCylinderCount, '');
        setValue(PeVehicleForm.Keys.Traction, '');
        setValue(PeVehicleForm.Keys.TransmissionType, '');
      },
    });

  /*
   * Fetches the vehicle style details.
   * Call 'fetchStyleDetails' when the user selects a style from the dropdown.
   */
  const [fetchStyleDetails, { loading: isDetailsLoading }] =
    useNewBlackBookDetailDecoderLazyQuery({
      onCompleted: ({ newBlackBookDetailDecoder }) => {
        setValue(
          PeVehicleForm.Keys.EngineCylinderLabel,
          newBlackBookDetailDecoder?.engine ?? ''
        );

        setValue(
          PeVehicleForm.Keys.EngineCylinderCount,
          newBlackBookDetailDecoder?.engineCylinderCount ?? ''
        );

        setValue(
          PeVehicleForm.Keys.Traction,
          newBlackBookDetailDecoder?.traction ?? ''
        );

        setValue(
          PeVehicleForm.Keys.TransmissionType,
          newBlackBookDetailDecoder?.transmission ?? ''
        );
      },
    });

  const seriesOptions = useMemo<IOption[]>(() => {
    return (
      decodeVinInfo?.newBlackBookDecoder?.trims?.map(
        ({ value, styles, label }) => ({
          value: value || label,
          label: value || label,
          styles,
        })
      ) ?? []
    );
  }, [decodeVinInfo]);

  const stylesList = useMemo(() => {
    return (
      stylesData?.newBlackBookDetailDecoder.trims.find(
        (series) => series.value === selectedSeries
      )?.styles ?? []
    );
  }, [stylesData, selectedSeries]);

  const bodyTypeOptions = useMemo<IOption[]>(() => {
    return stylesList.map(({ value }: any) => ({ value, label: value }));
  }, [stylesList]);

  const handleSeriesChange = (series: string) => {
    setValue(PeVehicleForm.Keys.StyleId, '');
    setValue(PeVehicleForm.Keys.BodyType, '');
    VehicleDetailKeys.forEach(({ formKey }) => setValue(formKey, ''));

    const id =
      decodeVinInfo?.newBlackBookDecoder?.trims?.find(
        ({ value }: any) => value === series
      )?.label ?? '';

    fetchStyles({ variables: { id: id || series, series: id || series } });
  };

  const handleBodyTypeChange = (newType: string) => {
    const id = stylesList.find(({ value }: any) => value === newType)?.label;

    if (!id) return;

    setValue(PeVehicleForm.Keys.StyleId, id);
    VehicleDetailKeys.forEach(({ formKey }) => setValue(formKey, ''));

    fetchStyleDetails({ variables: { id, series: id } });
  };

  return {
    seriesOptions,
    bodyTypeOptions,
    selectedSeries,

    handleSeriesChange,
    handleBodyTypeChange,

    isDecodeLoading,
    isDetailsLoading: isFetchingStyles || isDetailsLoading,
  };
};

export default useBlackBookController;
