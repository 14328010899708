import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from './settings.store';

const useSettingsInit = () => {
  const { language } = useLanguage();
  const { i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => i18n.changeLanguage(language), 10);
  }, [language]);
};

export default useSettingsInit;
