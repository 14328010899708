import React from 'react';
import {
  Icon,
  IconButton,
  MenuItem,
  Avatar,
  Hidden,
  Menu,
  Badge,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import LangSwitcher from './lang-switcher';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'hooks/useGlobal';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ContactlessIcon from '@mui/icons-material/Contactless';
import { RoutesConfig } from '../../router/routes.config';

const useStyles = makeStyles((theme) => ({
  topbar: {
    top: 0,
    zIndex: 96,
    boxShadow: theme.shadows[8],
    height: 64,
    paddingLeft: 18,
    paddingRight: 20,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0))',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 16,
    },
  },
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 24,
    padding: 4,
    '& span': {
      margin: '0 8px',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 185,
  },
  notification: {
    backgroundColor: '#1d9b541f',
  },
}));

type TopbarProps = {
  userName: string;
  onSidebarToggle: () => void;
  onNotificationsBarToggle: () => void;
  onLogout: () => void;
};

const Topbar: React.FC<TopbarProps> = ({
  userName,
  onLogout,
  onSidebarToggle,
  onNotificationsBarToggle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>();
  const { notification } = useGlobal();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <div className={classes.topbar}>
      <div className="flex justify-between items-center h-full">
        <div className="flex">
          <IconButton onClick={onSidebarToggle} size="large">
            <Icon>menu</Icon>
          </IconButton>
        </div>
        <div className="flex items-center">
          <LangSwitcher color="secondary" />
          <IconButton onClick={onNotificationsBarToggle} size="large">
            {notification.count ? (
              <Badge badgeContent={notification.count} color="primary">
                <NotificationsActiveIcon />
              </Badge>
            ) : (
              <NotificationsIcon />
            )}
          </IconButton>
          <div className={classes.userMenu} onClick={handleClick}>
            <Hidden smDown>
              <span>
                <strong>{userName}</strong>
              </span>
            </Hidden>
            <Avatar className="cursor-pointer" />
          </div>
          <Menu
            elevation={8}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Link to={RoutesConfig.Profile.Me}>
              <MenuItem className={classes.menuItem}>
                <Icon> person </Icon>
                <span className="pl-4"> {t(`general.profile`)} </span>
              </MenuItem>
            </Link>
            <Link to={RoutesConfig.Profile.NotificationSystem}>
              <MenuItem className={classes.menuItem}>
                <ContactlessIcon />
                <span className="pl-4"> {t(`general.notification`)}</span>
              </MenuItem>
            </Link>
            <MenuItem className={classes.menuItem} onClick={onLogout}>
              <Icon> power_settings_new </Icon>
              <span className="pl-4"> {t(`general.logout`)} </span>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Topbar);
