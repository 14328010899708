import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  Name = 'custName',
  SmsNumber = 'custSmsNumber',
  Comment = 'commentClient',
  Called = 'called',
  Taken = 'taken',
}

export const Schema = z.object({
  [Keys.Name]: z.string(),
  [Keys.SmsNumber]: z.string(),
  [Keys.Comment]: z.string(),
  [Keys.Called]: z.boolean(),
  [Keys.Taken]: z.boolean(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.Name]: '',
  [Keys.SmsNumber]: '',
  [Keys.Comment]: '',
  [Keys.Called]: false,
  [Keys.Taken]: false,
};

export const useCustomerForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
