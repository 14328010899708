import { useFeedback } from 'hooks/useFeedback';
import useImageOfflineQueue from 'hooks/useImageOfflineQueue';
import useStore from 'store/pe-edit-appraisal.store';
import useGlobal from 'hooks/useGlobal';

const usePipePicturesSubmit = () => {
  const { showWarning } = useFeedback();
  const { jobs } = useImageOfflineQueue();
  const { appraisal } = useStore();
  const { refetchAppraisalsHandle } = useGlobal();

  const handlePipePictures = () => {
    if (jobs.some((it) => it.id === appraisal?.id)) {
      showWarning(
        'Please take all pictures and wait when all will be uploaded'
      );

      throw new Error();
    }

    refetchAppraisalsHandle();
  };

  return { handlePipePictures };
};

export default usePipePicturesSubmit;
