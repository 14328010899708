import React from 'react';

import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  BoxProps,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useStyles } from './style';

const IS_FORM_AMAZON = 'amazonaws.com';

type ImageCard = BoxProps & {
  id?: string;
  label: string;
  src: string;
  zone: string;
  isDeletable?: boolean;
  isUploading?: boolean;
  withChip?: boolean;
  isCanEdit?: boolean;
  onEdite?: () => void;
  onDelete?: () => void;
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ImageCard: React.FC<ImageCard> = ({
  id,
  label,
  src,
  zone,
  isDeletable = false,
  isUploading = false,
  withChip = true,
  isCanEdit = false,
  onEdite,
  onUpload,
  onDelete,
  ...rest
}) => {
  const classes = useStyles();

  const deleteBtnElem = (
    <Box
      position="absolute"
      width="100%"
      display="flex"
      flexDirection="row-reverse"
      zIndex={1}
    >
      <IconButton
        aria-label="delete"
        className={classes.deleteIcon}
        onClick={onDelete}
        size="large"
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const editBtnElem = (
    <Box
      position="absolute"
      width="100%"
      display="flex"
      flexDirection="row-reverse"
      zIndex={1}
      top="10px"
      right="8px"
    >
      <IconButton
        aria-label="delete"
        className={classes.editIcon}
        onClick={onEdite}
        size="large"
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  return (
    <Box position="relative" maxWidth={'321px'} {...rest}>
      {isUploading && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          <CircularProgress />
        </Box>
      )}
      {isDeletable && deleteBtnElem}
      {isCanEdit && onEdite && src.includes(IS_FORM_AMAZON) && editBtnElem}
      {!isUploading && onUpload && (
        <Box display="none">
          <input
            accept="image/*"
            id={id}
            name={zone}
            type="file"
            onChange={onUpload}
          />
        </Box>
      )}
      <label htmlFor={id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          className="cursor-pointer"
        >
          <img className={classes.image} src={src} alt={zone} />
          {withChip && (
            <Box mt={-1.5}>
              <Chip className="uppercase" color="primary" label={label} />
            </Box>
          )}
        </Box>
      </label>
    </Box>
  );
};

export default ImageCard;
