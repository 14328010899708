import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Divider } from '@mui/material';
import { format } from 'date-fns';

import {
  NotificationCountDocument,
  NotificationListDocument,
  useListingLazyQuery,
  useNotificationReadMutation,
  useAppraisalLazyQuery,
} from 'graphql-types';

import { useStyles } from './styles';
import { NotificationItem } from 'contexts/GlobalContext';
import { Link } from 'react-router-dom';
import { useUser } from 'store';
import clsx from 'clsx';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LinkIcon from '@mui/icons-material/Link';

type Notification = {
  notification: NotificationItem;
};

type SourceType = 'Listing' | 'Appraisal';

enum SourceConst {
  listing = 'Listing',
  appraisal = 'Appraisal',
}

const NotificationMassage: React.FC<Notification> = ({ notification }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useUser();
  const [title, setTitle] = useState('');

  const [queryListining] = useListingLazyQuery({
    onCompleted: (data) => {
      setTitle(data.listing.result.referenceTitle || '');
    },
  });

  const [queryAppraisal] = useAppraisalLazyQuery({
    onCompleted: (data) => {
      if (!data.appraisal.result) {
        setTitle('');
      }
      const { modelYear, make, model } = data.appraisal.result;
      setTitle(`${modelYear} ${make} ${model}`);
    },
  });

  const [notificationIsRead] = useNotificationReadMutation();

  const { _id, id, source, read_by, text, created } = notification;

  const getTitle = async () => {
    if (source.type === SourceConst.listing) {
      await queryListining({
        variables: {
          id: source.id,
        },
      });
      return;
    }

    if (source.type === SourceConst.appraisal) {
      await queryAppraisal({
        variables: {
          id: `${source.id}`,
          withInventory: false,
        },
      });
      return;
    }

    setTitle('Non title');
  };

  useEffect(() => {
    getTitle();
  }, []);

  const checkNotification = async () => {
    if (!read_by.includes(`${user?.id}`)) {
      await notificationIsRead({
        variables: {
          input: { id: id },
        },
        refetchQueries: [
          { query: NotificationListDocument },
          { query: NotificationCountDocument },
        ],
      });
    }
  };

  const isRead = read_by.includes(`${user?.id}`);
  const convertSourceType = (type: SourceType) => {
    switch (type) {
      case 'Appraisal':
        return 'appraiser';
      case 'Listing':
        return 'listing';
      default:
        return 'listing';
    }
  };

  return (
    <Box>
      <Box
        className={clsx('cursor-pointer', {
          [classes.itemNotificationActive]: !isRead,
        })}
        onClick={checkNotification}
      >
        <Box
          className={clsx({
            [classes.textColorNotActive]: isRead,
          })}
          display="flex"
          flexDirection="column"
          p={2}
          pb={0}
        >
          <Box
            className={clsx({
              [classes.textColorPrimary]: !isRead,
            })}
            display="flex"
            alignItems="center"
          >
            <SpeakerNotesIcon /> <Box ml={1} fontWeight="500">{`${title}`}</Box>
          </Box>
          <Box component="p" p={0} mt={1} mb={0}>
            {text}
          </Box>
        </Box>
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link
            key={_id}
            to={`/${convertSourceType(source.type as SourceType)}/${
              source.id
            }/`}
          >
            <Button size="small" startIcon={<LinkIcon />}>
              {t('btn.link')}
            </Button>
          </Link>
          <Box>{format(new Date(created), 'dd MMM yyyy HH:mm')}</Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default NotificationMassage;
