import React from 'react';
import salesManagerPriceRoutes from './sales-manager-price.routes';
import appraisalPriceRoutes from './appraisal-price.routes';
import { RoutesConfig } from 'router/routes.config';

const analyticRoutes = [
  {
    path: RoutesConfig.Analytics.Dashboard,
    exact: true,
    component: React.lazy(() => import('modules/analytics')),
  },
  {
    path: RoutesConfig.Analytics.CameraList,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/camera-analytics/List')
    ),
  },
  {
    path: RoutesConfig.Analytics.CameraViewOne,
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/View')),
  },
  {
    path: RoutesConfig.Analytics.BadStartComplete,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/physical-evaluation')
    ),
  },
  {
    path: RoutesConfig.Analytics.BadVinDecode,
    exact: true,
    component: React.lazy(() => import('modules/analytics/bad-vin-decode')),
  },
  {
    path: RoutesConfig.Analytics.PhysicalEvalPrice,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/physical-evaluation-price')
    ),
  },

  ...salesManagerPriceRoutes,
  ...appraisalPriceRoutes,
];

export default analyticRoutes;
