import { useEffect, useState } from 'react';

const isPermissionCamera = () => {
  const [isPermission, setIsPermission] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getMedia() {
      let stream = null;
      try {
        setLoading(true);
        stream = await navigator.mediaDevices.getUserMedia({ video: true });

        stream.getTracks().forEach((mediaTrack) => {
          mediaTrack.stop();
        });

        setIsPermission(true);
        setLoading(false);
      } catch (err) {
        console.log('catch', err);
        setLoading(false);
        setIsPermission(false);
      } finally {
        setLoading(false);
      }
    }

    getMedia();
  }, []);

  return { isPermission, loading };
};

export default isPermissionCamera;
