import { useUpdatePhysicalEvaluationMutation } from 'graphql-types';

export const useUpdatePhysicalEvaluation = () => {
  const [updateFn, { loading, data, error }] =
    useUpdatePhysicalEvaluationMutation();

  return {
    updateFn,
    loading,
    data,
    error,
  };
};
