import { z } from 'zod';
import { VinCodeRegexp } from '../validation/regexps';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  Vin = 'vin',
  LicenseState = 'licenseState',
  LicensePlate = 'licensePlate',
}

export const Schema = z.object({
  [Keys.Vin]: z
    .string()
    .min(1, 'appraisal.vehicle.vin.required')
    .transform((val) => val.toUpperCase())
    .refine(
      (val) => val.match(VinCodeRegexp) !== null,
      'appraisal.vehicle.vin.invalid'
    ),
  [Keys.LicenseState]: z.string(),
  [Keys.LicensePlate]: z.string(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  vin: '',
  licenseState: '',
  licensePlate: '',
};

export const useCreateAppraisalForm = () =>
  useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
