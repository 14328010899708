class UnitConverter {
  /*
   * Conversion factors to meters.
   * The value of each unit is multiplied by the factor to get the value in meters.
   */
  private static conversionFactors = {
    meters: 1,
    kilometers: 1000,
    miles: 1621.371,
    yards: 0.9144,
    feet: 0.3048,
    inches: 0.0254,
    centimeters: 0.01,
    millimeters: 0.001,
    nauticalMiles: 1852,
  };

  static convert(
    value: number | string,
    fromUnit: keyof typeof UnitConverter.conversionFactors,
    toUnit: keyof typeof UnitConverter.conversionFactors,
    fixedCount = 0
  ): number {
    if (!(fromUnit in this.conversionFactors)) {
      throw new Error(`Unknown unit: ${fromUnit}`);
    }

    if (!(toUnit in this.conversionFactors)) {
      throw new Error(`Unknown unit: ${toUnit}`);
    }

    const valueInMeters = Number(value || 0) * this.conversionFactors[fromUnit];
    const result = valueInMeters / this.conversionFactors[toUnit];

    if (fixedCount !== undefined) {
      return parseFloat(result.toFixed(fixedCount));
    }

    return result;
  }
}

export default UnitConverter;
