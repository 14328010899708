import { create } from 'zustand';

export type StepperState<T extends number = number> = {
  activeStep: T;
  totalSteps: T;
  goToStep: (step: T) => void;
  nextStep: () => void;
  prevStep: () => void;
  resetStepper: () => void;
};

const createStepperStore = <T extends number = number>(
  totalSteps: T,
  initialStep = 0 as T
) =>
  create<StepperState<T>>((set) => ({
    activeStep: initialStep,
    totalSteps,

    goToStep: (step: T) => {
      set((state) => {
        if (step >= 0 && step < state.totalSteps) {
          return { activeStep: step };
        }

        return state;
      });
    },

    nextStep: () => {
      set((state) => ({
        activeStep:
          state.activeStep < state.totalSteps - 1
            ? ((state.activeStep + 1) as T)
            : state.activeStep,
      }));
    },

    prevStep: () => {
      set((state) => ({
        activeStep:
          state.activeStep > 0
            ? ((state.activeStep - 1) as T)
            : state.activeStep,
      }));
    },

    resetStepper: () => {
      set({ activeStep: initialStep });
    },
  }));

export default createStepperStore;
