/* eslint-disable react/display-name */
import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useGlobal from 'hooks/useGlobal';
import { RoutesConfig } from '../../router/routes.config';
import { Permissions } from '../../constants/entities/user';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  badge: {
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
  },
}));

const navigations: NavigationItem[] = [
  {
    key: 'dashboard',
    path: RoutesConfig.Dashboard,
    icon: 'dashboard',
    type: 'link',
  },
  // {
  //   key: 'dashboard-statistic',
  //   path: '/statistics/',
  //   icon: 'dashboard',
  //   type: 'link',
  //   permission: 'dashboard:get-global-statistic',
  // },
  {
    key: 'dashboard-statistic',
    path: RoutesConfig.Analytics.Dashboard,
    icon: 'dashboard',
    type: 'link',
    permission: 'analytics',
  },
  // {
  //   key: 'dashboard-statistic-my',
  //   path: '/statistics-my/',
  //   icon: 'dashboard',
  //   type: 'link',
  //   permission: 'dashboard:get-own-statistic',
  // },
  {
    key: 'virtual-evaluations-menu',
    type: 'label',
    permission: 'virtual-evaluation',
  },
  {
    key: 'virtual-evaluations-new',
    path: RoutesConfig.VirtualEvaluation.Create,
    icon: 'add',
    type: 'link',
    permission: 'virtual-evaluation:create',
  },
  {
    key: 'virtual-evaluations',
    path: '/listing/',
    icon: 'link',
    type: 'link',
    permission: 'virtual-evaluation:get-list',
    count: () => {
      const classes = useStyles();
      const { listing } = useGlobal();

      return (
        <Box className={classes.container}>
          {!!listing.evaluation && (
            <Box bgcolor="info.main" className={classes.badge}>
              {listing.evaluation}
            </Box>
          )}
          {!!listing.evaluating && (
            <Box bgcolor="secondary.main" className={classes.badge}>
              {listing.evaluating}
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    key: 'physical-evaluations-menu',
    type: 'label',
    permission: 'physical-evaluation',
  },
  {
    key: 'physical-evaluations-new',
    path: RoutesConfig.PhysicalEvaluation.Create,
    icon: 'add',
    type: 'link',
    permission: Permissions.PhysicalEvaluation.All,
  },
  {
    key: 'physical-evaluations',
    path: RoutesConfig.PhysicalEvaluation.List,
    icon: 'directions_car',
    type: 'link',
    permission: 'physical-evaluation:get-list',
    count: () => {
      const classes = useStyles();
      const { appraisal } = useGlobal();
      return (
        <Box className={classes.container}>
          {!!appraisal.evaluation && (
            <Box bgcolor="info.main" className={classes.badge}>
              {appraisal.evaluation}
            </Box>
          )}
          {!!appraisal.evaluating && (
            <Box bgcolor="secondary.main" className={classes.badge}>
              {appraisal.evaluating}
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    key: 'under-evaluations',
    path: '/appraiser/',
    icon: 'directions_car',
    type: 'link',
    permission: `physical-evaluation:appraise`,
  },
  {
    key: 'conditions-reports',
    type: 'label',
    permission: `condition-report`,
  },
  {
    key: 'appraisal-conditions-new',
    path: RoutesConfig.AppraisalConditions.Create,
    icon: 'build',
    type: 'link',
    permission: Permissions.ConditionReport.Create,
  },
  {
    key: 'conditions-inspections',
    path: '/assessor/',
    icon: 'build',
    type: 'link',
    permission: `condition-report:get-list`,
    count: () => {
      const classes = useStyles();
      const { appraisal } = useGlobal();

      return (
        <Box className={classes.container}>
          {!!appraisal.assessment && (
            <Box bgcolor="info.main" className={classes.badge}>
              {appraisal.assessment}
            </Box>
          )}
          {!!appraisal.pending && (
            <Box bgcolor="secondary.main" className={classes.badge}>
              {appraisal.pending}
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    key: 'conditions-works',
    path: '/work/',
    icon: 'build',
    type: 'link',
    permission: `work:get-list`,
  },
  {
    key: 'administration',
    type: 'label',
    permission: 'user',
  },
  {
    key: 'administration-users',
    path: RoutesConfig.Users.List,
    icon: 'group',
    type: 'link',
    permission: 'user:get-list',
  },
  {
    key: 'user-evaluation-menu',
    type: 'label',
    permission: 'user-evaluation',
  },
  {
    key: 'user-evaluation-menu-list',
    path: '/user-evaluation/',
    icon: 'directions_car',
    type: 'link',
    permission: 'user-evaluation',
  },
];

export { navigations };
