import React from 'react';
import { Permissions } from '../../constants/entities/user';
import { RoutesConfig } from '../routes.config';

export const VirtualEvalationListingPath = '/listing/';

const virtualEvaluationRoutes = [
  {
    path: RoutesConfig.VirtualEvaluation.List,
    exact: true,
    component: React.lazy(() => import('modules/virtual-eval-listing')),
    permission: Permissions.VirtualEvaluation.GetList,
  },
  {
    path: RoutesConfig.VirtualEvaluation.Create,
    exact: true,
    component: React.lazy(() => import('modules/virtual-eval-create')),
    permission: Permissions.VirtualEvaluation.Create,
  },
  {
    path: RoutesConfig.VirtualEvaluation.ViewOne,
    exact: true,
    component: React.lazy(() => import('modules/virtual-evaluation/View')),
    permission: Permissions.VirtualEvaluation.GetOne,
  },

  {
    path: RoutesConfig.VirtualEvaluation.Edit,
    exact: true,
    component: React.lazy(() => import('modules/virtual-eval-edit')),
    permission: Permissions.VirtualEvaluation.Update,
  },
  {
    path: RoutesConfig.VirtualEvaluation.Appraise,
    exact: true,
    component: React.lazy(() => import('modules/virtual-evaluation/Appraise')),
    permission: Permissions.VirtualEvaluation.Appraise,
  },
  {
    path: RoutesConfig.VirtualEvaluation.AppraiseValue,
    exact: true,
    component: React.lazy(
      () => import('modules/virtual-evaluation/Appraise/appraisalValue')
    ),
    permission: Permissions.VirtualEvaluation.Appraise,
  },
];

export default virtualEvaluationRoutes;
