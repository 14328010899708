import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { HiddenTitleSteps, PhysicalEvalEditSteps } from '../constants/values';
import { useTranslation } from 'react-i18next';
import usePeEditStepper from 'store/pre-edit-stepper.store';

const FormTitle: FC = () => {
  const { t } = useTranslation();
  const { activeStep } = usePeEditStepper();

  if (HiddenTitleSteps.includes(activeStep)) return null;

  const labelKey = PhysicalEvalEditSteps[activeStep]?.label ?? '';

  return (
    <Box display="flex" justifyContent="center" pb={2}>
      <Typography variant="h2">{t(labelKey)}</Typography>
    </Box>
  );
};

export default FormTitle;
