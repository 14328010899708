import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

type Props = Pick<
  ControllerProps,
  'name' | 'defaultValue' | 'shouldUnregister'
> &
  CheckboxProps & {
    label: string;
  };

const CheckBoxController: FC<Props> = ({
  name,
  defaultValue,
  shouldUnregister,
  label,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      control={control}
      render={({ field: { onChange, value, ref, onBlur } }) => (
        <FormControlLabel
          control={<Checkbox ref={ref} {...props} />}
          label={t(label)}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default CheckBoxController;
