import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  PhotosCertify = 'photosCertify',
}

export const Schema = z.object({
  [Keys.PhotosCertify]: z
    .boolean()
    .refine((v) => v, 'common.validation.required'),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.PhotosCertify]: false,
};

export const usePicturesForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
