import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';

import { Box, Card, CardActions, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: '#FFFFFF',
    width: '100%',
  },
  typography: {
    color: '#fff',
  },
  actionRoot: {
    padding: '8px',
    justifyContent: 'space-between',
  },
  imageWrapper: {
    width: '100px',
    height: '65px',
    marginRight: '8px',
    borderRadius: '4px',
    backgroundColor: '#ebebeb',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  icons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#000',
    transition: 'all .2s',
  },
  title: {
    fontWeight: 500,
    color: '#2F2F2F',
  },
  description: {
    color: '#2F2F2F',
  },
}));

interface NotificationItemProps extends CustomContentProps {
  allowDownload?: boolean;
}

type PayloadType = {
  id: string;
  name: string;
  dealership: string;
  cover: string;
  appraislPrice: string;
  offeredPrice: string;
  topic: string;
};

const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>(
  ({ id, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const payloadString =
      typeof props?.message === 'string' ? props?.message : '';
    const payload = JSON.parse(payloadString) as PayloadType;

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card className={classes.card}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <Box display="flex">
              <Box className={classes.imageWrapper}>
                {payload.cover && (
                  <img
                    className={classes.image}
                    src={payload.cover}
                    alt={payload.name}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Box className={classes.title}>
                  {t(`notificationSystem.${payload.topic}.title`)}
                </Box>
                <Box className={classes.description}>
                  {t(`notificationSystem.${payload.topic}.message`, {
                    name: payload.name,
                    dealership: payload.dealership,
                  })}
                </Box>
              </Box>
              <div className={classes.icons}>
                <IconButton
                  size="small"
                  className={classes.expand}
                  onClick={handleDismiss}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </Box>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

NotificationItem.displayName = 'NotificationItem';

export default NotificationItem;
