import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type ToggleActions<T = any> = {
  toggle: () => void;
  on: (payload?: T) => void;
  off: () => void;
  setData: Dispatch<SetStateAction<T | null>>;
};

const useToggle = <T = any>(
  initialState = false
): [boolean, ToggleActions<T>, T | null] => {
  const [state, setState] = useState<boolean>(initialState);
  const [payload, setPayload] = useState<T | null>(null as T);

  const toggle = useCallback(() => setState((state) => !state), [setState]);

  const on = useCallback(
    (payload?: any) => {
      setState(true);
      if (payload !== undefined) setPayload(payload);
    },
    [setState, setPayload]
  );

  const off = useCallback(() => setState(false), [setState]);
  const setData = useCallback(setPayload, [setPayload]);

  return [state, { toggle, on, off, setData }, payload];
};

export default useToggle;
