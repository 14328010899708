import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  Condition = 'condition',
  Damage = 'damage',
  Repair = 'repair',
  Custom = 'custom',
  KeysCar = 'keysCar',
  Financing = 'financing',
  EngineNoise = 'engineNoise',
  TransmissionSlipWork = 'transmissionSlipWork',
}

export const Schema = z.object({
  [Keys.Condition]: z.string().min(1, 'common.validation.required'),
  [Keys.Damage]: z.string().min(1, 'common.validation.required'),
  [Keys.Repair]: z.string().min(1, 'common.validation.required'),
  [Keys.Custom]: z.string().min(1, 'common.validation.required'),
  [Keys.KeysCar]: z.string().min(1, 'common.validation.required'),
  [Keys.Financing]: z.string().min(1, 'common.validation.required'),
  [Keys.EngineNoise]: z.string().min(1, 'common.validation.required'),
  [Keys.TransmissionSlipWork]: z.string().min(1, 'common.validation.required'),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.Condition]: '',
  [Keys.Damage]: '',
  [Keys.Repair]: '',
  [Keys.Custom]: '',
  [Keys.KeysCar]: '',
  [Keys.Financing]: '',
  [Keys.EngineNoise]: '',
  [Keys.TransmissionSlipWork]: '',
};

export const useQuestionsForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
