import React, { FC } from 'react';
import { StepItem } from './types';
import {
  Step,
  StepLabel,
  Typography,
  Stepper as MuiStepper,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  activeStep: number;
  steps: StepItem[];
  completed?: number[];
};

const Stepper: FC<Props> = ({ activeStep, steps, completed }) => {
  const { t } = useTranslation();

  return (
    <MuiStepper activeStep={activeStep} alternativeLabel>
      {steps.map(({ key, label, subLabel }) => (
        <Step completed={completed?.includes(key)} key={key}>
          <StepLabel>
            <Stack gap={1}>
              {label && <Typography>{t(label)}</Typography>}

              {subLabel && (
                <Typography variant="caption">{t(subLabel)}</Typography>
              )}
            </Stack>
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
