import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  Year = 'modelYear',
  Make = 'make',
  Model = 'model',
  Series = 'series',
}

export const Schema = z.object({
  [Keys.Year]: z.string().optional(),
  [Keys.Make]: z.string().optional(),
  [Keys.Model]: z.string().optional(),
  [Keys.Series]: z.string().optional(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  modelYear: '',
  make: '',
  model: '',
  series: '',
};

export const useGeneralForm = () =>
  useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
