import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';

const Loading: React.FC = () => (
  <Backdrop open sx={{ zIndex: 110000000 }}>
    <CircularProgress />
  </Backdrop>
);

export default Loading;
