import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#18A857',
    // background: theme.palette.background.paper,
    height: '100vh',
    width: '100%',
  },
  lang: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  header: {
    display: 'grid',
    justifyItems: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: '5vw',
    color: theme.palette.common.white,
  },
  signBtn: {
    display: 'grid',
    justifyItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    minHeight: theme.spacing(8),
  },
}));

export { useStyles };
