import { EditSteps } from '../constants/types';
import { UseFormReturn } from 'react-hook-form';
import usePeEditStepper from 'store/pre-edit-stepper.store';
import { useCustomerForm } from 'constants/forms/physical-eval-edit/customer.form';
import { useVehicleForm } from 'constants/forms/physical-eval-edit/vehicle.form';
import { useEquipmentForm } from 'constants/forms/physical-eval-edit/equipment.form';
import { useQuestionsForm } from 'constants/forms/physical-eval-edit/questions.form';
import { usePicturesForm } from 'constants/forms/physical-eval-edit/pictures.form';

const usePhysicalEvalEditForm = () => {
  const { activeStep } = usePeEditStepper();

  const vehicleMethods = useVehicleForm();
  const equipmentMethods = useEquipmentForm();
  const customerMethods = useCustomerForm();
  const questionsMethods = useQuestionsForm();
  const picturesMethods = usePicturesForm();

  const methods: Record<EditSteps, UseFormReturn<any>> = {
    [EditSteps.Vehicle]: vehicleMethods,
    [EditSteps.Equipment]: equipmentMethods,
    [EditSteps.Customer]: customerMethods,
    [EditSteps.Questions]: questionsMethods,
    [EditSteps.Pictures]: picturesMethods,
  };

  return methods[activeStep];
};

export default usePhysicalEvalEditForm;
