import React from 'react';
import { Permissions } from '../../constants/entities/user';

const underEvaluationRoutes = [
  {
    path: '/appraiser/',
    exact: true,
    component: React.lazy(() => import('modules/under-evaluation-listing')),
    permission: Permissions.PhysicalEvaluation.GetList,
  },
  {
    path: '/appraiser/:id/',
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/View')),
    permission: Permissions.PhysicalEvaluation.GetOne,
  },
  {
    path: '/appraiser/:id/edit/',
    exact: true,
    component: React.lazy(() => import('modules/physical-eval-edit')),
    permission: Permissions.PhysicalEvaluation.Update,
  },
  {
    path: '/appraiser/:id/condition-report/',
    exact: true,
    component: React.lazy(
      () => import('modules/physical-evaluation/Condition')
    ),
    // component: React.lazy(() => import('../../components/ConditionReport')),
    permission: Permissions.PhysicalEvaluation.GetReminder,
  },
  {
    path: '/appraiser/:id/reminder/',
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/Reminder')),
    permission: Permissions.PhysicalEvaluation.GetReminder,
  },
  {
    path: '/appraiser/:id/appraise/',
    exact: true,
    component: React.lazy(() => import('modules/physical-evaluation/Appraise')),
    permission: Permissions.PhysicalEvaluation.Appraise,
  },
  {
    path: '/appraiser/:id/edit-user/',
    exact: true,
    component: React.lazy(
      () => import('modules/physical-evaluation/Edit-user')
    ),
    permission: Permissions.PhysicalEvaluation.Update,
    // permission: `physical-evaluation:change-customer`,
  },
];

export default underEvaluationRoutes;
