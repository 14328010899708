import React from 'react';
import { Permissions } from 'constants/entities/user';

const assessorRoutes = [
  {
    path: '/assessor/',
    exact: true,
    component: React.lazy(() => import('modules/inspections-listing')),
    permission: Permissions.ConditionReport.GetList,
  },
  {
    path: '/assessor/:id/',
    exact: true,
    component: React.lazy(() => import('modules/assessor/Edit')),
    permission: Permissions.ConditionReport.Update,
  },
  {
    path: '/condition-report/:id/',
    exact: true,
    component: React.lazy(() => import('modules/assessor/View')),
    permission: Permissions.ConditionReport.GetOne,
  },
  {
    path: '/assessor/:id/vehicle-report/',
    exact: true,
    component: React.lazy(() => import('ui/components/vehicle-report')),
    permission: Permissions.ConditionReport.GetVehicleReport,
  },
];

export default assessorRoutes;
