import React from 'react';
import { RoutesConfig } from 'router/routes.config';

const profileRoutes = [
  {
    path: RoutesConfig.Profile.Me,
    exact: true,
    component: React.lazy(() => import('modules/profile/Profile')),
  },
  {
    path: RoutesConfig.Profile.Notifications,
    exact: true,
    component: React.lazy(() => import('modules/profile/Notification')),
  },
  {
    path: RoutesConfig.Profile.NotificationSystem,
    exact: true,
    component: React.lazy(() => import('modules/profile/NotificationSystem')),
  },
];

export default profileRoutes;
