import { useUser } from 'store';
import useStore from 'store/pe-edit-appraisal.store';
import { AddDeductListItem, useDecodeBlackBookVinQuery } from 'graphql-types';
import { useEffect, useMemo } from 'react';
import usePeEditStepper from 'store/pre-edit-stepper.store';

const useEquipment = () => {
  const user = useUser();
  const { appraisal, skipEquipmentOn, skipEquipmentOff } = useStore();
  const { nextStep } = usePeEditStepper();

  const { data: vinData, loading } = useDecodeBlackBookVinQuery(
    appraisal?.vin
      ? {
          variables: {
            vin: appraisal.vin,
            country: user?.country,
          },
        }
      : { skip: true }
  );

  const equipmentList = useMemo<AddDeductListItem[]>(() => {
    return (vinData?.decodeVinBlackBook?.result?.options.find(({ styles }) =>
      styles?.includes(appraisal?.series ?? '')
    )?.addDeductList ?? []) as AddDeductListItem[];
  }, [vinData]);

  useEffect(() => {
    if (vinData && !equipmentList.length) {
      skipEquipmentOn();
      nextStep();
    } else {
      skipEquipmentOff();
    }
  }, [equipmentList, vinData]);

  return { loading, equipmentList };
};

export default useEquipment;
