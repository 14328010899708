export const en = {
  translation: {
    openOffers: 'Open Offers',
    expectation: 'Pending',
    active: 'Active',
    waiting: 'Waiting',
    drafts: 'Drafts',
    canceled: 'Canceled',
    expired: 'Expired',
    purchased: 'Purchased',
    all: 'All',
    'to do': 'To do',
    progress: 'Progress',
    approval: 'Approval',
    completed: 'Completed',
    returns: 'Returns',
    conditioning: 'Conditioning',

    'users-table': {
      name: 'Name',
      email: 'Email',
      country: 'Country',
      dealership: 'Dealership',
      online: 'Online',
      actions: 'Actions',
    },

    'no-access': {
      title: 'Access denied',
      description: 'Sorry, you do not have access to this page.',
      reasons: 'Possible reasons:',
      reason1: 'You do not have sufficient permissions to view this content.',
      reason2: 'You are not logged in. Please log in to continue.',
      reason3: 'The page may have been moved or deleted.',
      'contact-support':
        'If you believe this is an error, please contact support.',
    },

    camera: {
      'add-new-damage': 'Add damage',
    },
    'user-evaluation': {
      btn: {
        start: {
          label: 'Start',
          description: 'Move this cat to physical evaluation',
        },
        cancelled: {
          label: 'Cancelled',
          description: 'Do car not active',
        },
        physicalLink: {
          label: 'Physical evaluation link',
        },
      },
    },
    appraisal: {
      'trim-title-for-pdf': {
        label: 'You can change Trim of the vehicle in PDF file.',
        'for-pdf': 'Title PDF',
        'new-name-for-pdf': 'New name for pdf',
        button: {
          update: 'Update',
          cancel: 'Cancel',
        },
        massage: {
          error: 'Something went wrong, please try again later.',
        },
      },
      message: {
        'can-not-read-vin':
          'Sorry but we can not scan VIN try again or write by hand',
      },
      equipment: {
        label: 'Equipment',
      },
      manheim: {
        label: 'Manheim prices',
      },
      card: {
        traction: 'Traction',
        transmission: 'Transmission',
      },
      condition: {
        'all-damage': 'All damage',
        begin: 'Begin',
        'botton-clear': 'Clear',
        'botton-send': 'Send',
        completed: 'Completed',
        'damage-active-tab': 'Damage to selected sides of the car',
        finish: 'Finish',
        'go-to-back': 'Go to back',
        'image-require': 'One image require',
        'image-wait': 'Wait to image download',
        'rough-estimate-of-damage': 'Rough estimate of damage',
        'to-fix': 'To fix: ',
        'to-start': 'To start',
        total: 'Total: ',
        'will-be-repaired': 'Will be repaired',
        'modal-title': 'Delete Arbitrate',
        'modal-question': 'Do you really want delete this Arbitrate?',
        'modal-yes': 'Delete',
        'modal-now': 'Cancel',
      },
      'owner-info': 'Owner information',
      owner: {
        address: {
          label: 'Address',
        },
        city: {
          label: 'City',
        },
        email: {
          label: 'Email',
          placeholder: 'Enter Email',
        },
        'first-name': {
          label: 'First name',
          required: 'This field is required',
          placeholder: 'Enter first name',
        },
        'last-name': {
          label: 'Last name',
          required: 'This field is required',
          placeholder: 'Enter last name',
        },
        phone: {
          label: 'Phone',
          required: 'This field is required',
          placeholder: 'Enter phone number',
        },
        post: {
          label: 'Post Code',
        },
        'postal-code': {
          label: 'Postal code',
        },
        state: {
          label: 'State',
        },
        location: {
          label: 'Location',
          required: 'This field is required',
          placeholder: 'Enter location',
        },
      },
      paveSessionId: {
        description:
          'You can send link for user. User will can self add image.',
        'info-message': "You can't put start because user not sent image.",
      },
      photos: {
        'load-images-error':
          'Internet connection error please find internet connect and put the button!',
        pending: 'Please, wait until all pictures are loaded',
        required: 'Please take pictures of the car. Click on camera button.',
        zone: {
          back: 'Back',
          front: 'Front',
          odometer: 'Odometer',
          side: 'Side',
          vin: 'Vin plate',
          all: 'Not all image',
        },
      },
      reminders: {
        'amount-owed': 'Amount owed to creditors by the customer',
        'approve-director': 'Approve by the director',
        attachments: 'Attachments',
        carproof: {
          unverifyed: 'Carproof available',
          verifyed: 'Carproof checked',
        },
        checklist: {
          unverifyed: 'Paper checklist to complete and attach',
          verifyed: 'Checklist attached',
        },
        'complete-assessment': 'Complete the assessment',
        'contract-pdf': 'Download contract PDF',
        contract: {
          unverifyed: 'Signed contract to attach',
          verifyed: 'Contract signed and attached',
        },
        form: {
          'comment-document': {
            label: "Client's Comments",
          },
          'comment-document-form': {
            label: "Reviewer's comments",
          },
          'amount-price': {
            label: 'Amount due to creditors',
          },
          'driver-license-form': {
            label: "Driver's license",
          },
          link: {
            label: 'Link',
          },
          'offered-price': {
            label: 'Price offered to the customer',
          },
          'offered-price-us': {
            label: 'Final Offer',
          },
          'appraisal-value': {
            label: 'Price appraisal',
          },
          'save-status-sale': {
            label: 'Save sale status',
          },
          text: {
            label: 'Text',
          },
          title: {
            label: 'Title',
          },
        },
        license: {
          unverifyed: "Driver's license '' to be verified",
          verifyed: "Owner's driver's license",
        },
        'price-appraiser': 'Price to be obtained from the expert',
        'price-obtained-from-appraiser': 'Price obtained from appraiser',
        'price-offered-director': 'Price offered by the director',
        'price-to-be-confirmed': 'Price to be confirmed by the evaluator',
        rdprm: {
          unverifyed: 'RDPRM file to verify',
          verifyed: 'RDPRM file checked and attached',
        },
        reminder: 'Reminders',
        'upload-file': 'Attach the link',
        'upload-image': 'Attach picture',
        'upload-link': 'Attach Attach link',
        'upload-note': 'Attach a note',
      },
      steps: {
        condition: {
          label: 'Condition',
          subLabel: 'About 3 Min',
        },
        customer: {
          label: 'Customer Info',
          subLabel: 'About 3 Min',
        },
        equipment: {
          label: 'Equipment Info',
          subLabel: 'About 4 Min',
        },
        photos: {
          label: 'Pictures',
          subLabel: 'About 5 Min',
        },
        question: {
          label: 'Question',
          subLabel: 'About 4 Min',
        },
        vehicle: {
          label: 'Vehicle Information',
          subLabel: 'About 3 Min',
        },
        vin: {
          label: 'VIN Decoder',
          subLabel: 'About 1 Min',
        },
      },
      summary: {
        color: 'Color',
        engine: 'Engine',
        mileage: 'Mileage',
        traction: 'Traction',
        transmission: 'Transmission',
      },
      vehicle: {
        'not-found-equipment': 'Not found any paid equipment',
        'body-type': {
          label: 'Styles',
        },
        'engine-cylinder-count': {
          label: 'Engine cylinder count',
        },
        engine: {
          label: 'Engine',
        },
        'exterior-color': {
          label: 'Exterior color',
        },
        'interior-color': {
          label: 'Interior color',
        },
        make: {
          label: 'Make',
        },
        'model-year': {
          label: 'Model year',
        },
        model: {
          label: 'Model',
        },
        odometer: {
          label: 'Odometer',
          required: 'Odometer is required',
        },
        origin: {
          label: 'Source of evaluation',
          required: 'Source of evaluatio is required',
        },
        plate: {
          label: 'License plate',
        },
        series: {
          label: 'Series',
        },
        state: {
          label: 'State',
        },
        style: {
          label: 'Style',
        },
        traction: {
          label: 'Traction',
        },
        'transmission-type': {
          label: 'Transmission',
        },
        vin: {
          invalid: 'Vin number is invalid',
          label: 'Vin',
          required: 'Vin number is required',
          placeholder: 'Enter VIN',
        },
        stock: {
          label: 'Stock',
          required: 'Stock number is required',
        },
        phone: {
          label: 'Phone',
          required: 'Phone number is required',
        },
      },
    },
    assessor: {
      form: {
        bucket: 'Bucket',
        edit: 'Edit damage',
        new: 'New damage',
      },
      arbitrage: 'Arbitrate',
      arbitrageOpen: 'Arbitrate',
    },
    'body-types': {
      'cab/chassis': 'Cab / Chassis',
      'cargo Van': 'Cargo Van',
      chassis: 'Frame',
      convertible: 'Convertible',
      coupe: 'Chopped off',
      'cutaway van': 'Cutaway Van',
      hatchback: 'Hatchback',
      hearse: 'Hearse',
      limousine: 'Limousine',
      'minivan/van': 'Minivan / Van',
      other: 'Other',
      sedan: 'Sedan',
      suv: 'SUV',
      truck: 'Van',
      wagon: 'Car',
    },
    btn: {
      add: 'Add',
      approbate: 'Submit for approval',
      approve: 'Approve',
      back: 'Back',
      cancel: 'Cancel',
      checked: 'Checked',
      complete: 'Complete',
      decode: 'Decode',
      estimate: 'Estimate',
      link: 'Link',
      origin: 'Origin',
      'generate-pdf': 'Generate Customer Voucher',
      'customer-pdf': 'Customer pdf',
      'printable-pdf': 'Printable pdf',
      pdf: 'PDF',
      'pdf-copy': 'Copy PDF',
      png: 'CONTRACT',
      'remote-transaction-request': 'Remote Transaction Request PDF',
      reopen: 'Reopen',
      restart: 'Restart',
      resubmit: 'Resubmit',
      return: 'Return',
      save: 'Save',
      'save-images': 'Save images',
      'upload-images-with-cache': 'Upload images with cache',
      start: 'Start',
      submit: 'submit',
      'submit-pave': 'Submit PAVE',
      'take-over': 'Take over',
      update: 'Update',
    },
    charts: {
      'condition-reports': 'Condition Reports',
      'evaluations-physiques': 'Physical Evaluation',
      'number-of-virtual-evaluations':
        'Virtual evaluation price gap per evaluator',
      'number-of-virtual-evaluations-type-of-actions-taken':
        'Number of virtual evaluations by type of actions taken by agents',
      'number-virtual-evaluations-with-price':
        'Number of virtual evaluations with a price per type of action taken by agents',
      'physical-assessment-time-in-minutes-per-rental':
        'Physical assessment time in minutes per rental',
      'physical-evaluations-lease': 'Physical Evaluations by Lease',
      'virtual-evaluations': 'Virtual evaluations',
      'virtual-evaluations-location': 'Virtual evaluations by location',
    },
    colors: {
      'beige/tan': 'Beige / Tan',
      'black metallic': 'Black metallic',
      blue: 'Blue',
      'blue (dark)': 'Blue (Dark)',
      'blue (light)': 'Blue (Dark)',
      'blue (metallic)': 'Metallic blue',
      'blue metallic': 'Blue metallic',
      'blue metallic (light)': 'Blue metallic (Light)',
      bronze: 'Bronze',
      brown: 'Brown',
      'brown (dark)': 'Brown (Dark)',
      'brown metallic': 'Brown metallic',
      champagne: 'Champagne',
      charcoal: 'Charcoal',
      gold: 'Gold',
      gray: 'Gray',
      green: 'Green',
      'green (dark)': 'Green (Dark)',
      'green (light)': 'Green (Light)',
      'green metallic': 'Green metallic',
      maroon: "Bordeaux 'maroon'",
      orange: 'Orange',
      pearl: 'Pearl',
      pewter: 'Pewter',
      pink: 'Pink',
      purple: 'Mauve',
      'purple metallic': 'Purple metallic',
      red: 'Red',
      'red metallic': 'Red metallic',
      silver: 'Silver',
      'teal green': "'Teal' green",
      white: 'White',
      yellow: 'Yellow',
      'yellow (light)': 'Yellow (Light)',
    },
    common: {
      welcome: `Welcome in Evaluation`,
      'brand-title': 'Evaluation Dashboard',
      'empty-result': 'Sorry no results found for your search',
      'old-app': 'Switch to Evaluation 1.0 (old)',
      or: 'or',
      'new-physical-evaluation': 'New Physical Evaluation',
      'new-virtual-evaluation': 'New Virtual Evaluation',
      'using-vin': 'No License Plate?',
      'using-vin-link': 'Enter VIN',
      'using-plate': 'No VIN number?',
      'using-plate-link': 'Enter License Plate',
      save: 'Save',
      submit: 'Submit',
      upload: 'Upload',
      validation: {
        required: 'This field is required',
        email: 'Email is not correct',
        notZerro: 'Can not be zero price',
      },
      'no-data': 'No data available',
      'no-data-simple': 'No data',
      search: 'Search',
      location: 'Location',
      'new-appraisal-condition': 'New Appraisal Condition',
    },
    'condition-edit': {
      action: {
        'deval-low': {
          label: 'Deval low',
        },
      },
    },
    'condition-report': {
      'active-damage': 'A mapper correctement',
      'active-report': 'Vehicle report  ACTIVE',
      'all-damage': 'All damage',
      'not-decode-damage': 'Not decode damage',
      comments: {
        label: 'Comments',
      },
      price: {
        label: 'Price',
      },
      'condition-report-link': 'Auction inspection report',
      create: {
        content: 'Create {{price}}',
      },
      'front-left': {
        label: 'front left',
      },
      'front-right': {
        label: 'front right',
      },
      'inventory-link': 'Inventory portal',
      'mounted-on': {
        label: 'Mounted on',
      },
      'steel-wheel': {
        label: 'Steel wheel',
      },
      'aluminium-rims': {
        label: 'Aluminium rims',
      },
      'steel-wheel-rims': {
        label: 'Steel wheel (rims)',
      },
      'aluminum-rims-mags': {
        label: 'Aluminum rims (mags)',
      },
      'seson-summer': {
        label: 'Summer',
      },
      'seson-winter': {
        label: 'Winter',
      },
      'not-active-report': 'Vehicle report NOT ACTIVE',
      odometer: {
        label: 'Odometer',
      },
      photos: {
        required: 'Photos is required',
      },
      question: {
        ac: {
          label: "Does the vehicle's air conditioning work?",
        },
        compound: {
          label: 'Does the car need a polishing (compound)?',
        },
        engine: {
          label: 'Does the engine make a weird noise or a lot of noise?',
        },
        keys: {
          label: 'How many keys for this vehicle?',
        },
      },

      'rear-left': {
        label: 'rear left',
      },
      'rear-right': {
        label: 'rear right',
      },
      season: {
        label: 'Climb on',
      },
      'the-tread-button': 'Add another pair of wheels',
      'the-tread-title': 'Tire tread and wrenches',
    },
    'connecting-rod-connection': 'Connecting rod',
    'damage-manage': {
      action: {
        btn: {
          create: 'Create',
          update: 'Update',
        },
        'deval-high': {
          label: 'Deval high',
        },
        'label-en': {
          label: 'Label in English',
        },
        'label-fr': {
          label: 'Label in French',
        },
        'repair-high': {
          label: 'Repair high',
        },
        'repair-low': {
          label: 'Repair low',
        },
      },
      buckets: {
        bodyshop: 'Bodyshop',
        detail: 'Detail',
        dsp: 'DSP',
        interiorrepair: 'Interior repair',
        mechanic: 'Mechanic',
        movetobodyshop: 'Move body shop',
        windshield: 'Windshield',
      },
      'create-damage-action': {
        content: 'Create damage action',
      },
      'create-damage': {
        content: 'Create damage',
      },
      damage: {
        btn: {
          create: 'Create',
          update: 'Update',
        },
        bucket: {
          label: 'Recontract bucket',
        },
        'label-en': {
          label: 'Label in English',
        },
        'label-fr': {
          label: 'Label in French',
        },
      },
      'deval-high': {
        label: 'Deval high',
      },
      'deval-low': {
        label: 'Deval low',
      },
      label: {
        label: 'Label',
      },
      'repair-high': {
        label: 'Repair High',
      },
      'repair-low': {
        label: 'Repair Low',
      },
    },
    dashboard: {
      'condition-reports': 'Condition reports',
      'create-physical': 'Create a physical assessment',
      'create-virtual': 'Create a virtual evaluation',
      'my-physical-evaluations': 'My physical evaluations',
      'my-virtual-evaluation': 'My virtual evaluation',
      'physical-assessments': 'Physical assessments in court',
      'your-options': 'Your options',
    },
    'engine-exhaust-transmission-one': 'Engine exhaust transmission #1',
    'engine-exhaust-transmission-two': 'Engine exhaust transmission #2',
    error: {
      'error-internet-connection': 'Error internet connection',
      'error-load-file': 'Fichier de chargement d’erreur',
    },
    exception: {
      answer: 'You must answer the mechanical questions in order to complete',
      'error-tire':
        'You need to enter the tire tread thickness to be able to finish',
      price: 'Evaluation price is required',
    },
    general: {
      logout: 'Logout',
      notification: 'Notification',
      profile: 'Profile',
    },
    inspection: {
      'not-found-any-problem': 'Inspection not found any problem',
      'tab-title': 'AI Inspection',
      'waite-create-inspection': 'Waite create inspection',
    },
    listing: {
      'update-error': 'Virtual appraisal isn`t updated. Try again.',
      'modal-title': 'Information message',
      'modal-description': 'Please turn your device to a horizontal position',
      'appointment-was-taken': {
        label: 'An appointment has been made with the client',
      },
      'appraisal-value': {
        label: 'Revised Amount',
      },
      'appraiser-comment': {
        label: 'Appraiser comments',
      },
      'asking-value': {
        label: 'Amount requested',
      },
      'change-appraisal': {
        value: 'Edit',
      },
      'client-comment': {
        label: 'Document comment',
      },
      'client-comment-form': {
        label: "Reviewer's comments",
      },
      'customer-name': {
        label: 'Client name',
      },
      'customer-sms-number': {
        label: 'mobile for automatic price sending',
      },
      'customer-was-called': {
        label: 'The customer has been contacted',
      },
      'document-comment': {
        label: 'Document comments',
      },
      exception: {
        price: 'Appraisal price is required',
      },
      make: {
        label: 'Make',
      },
      mileage: {
        label: 'Mileage',
      },
      'model-year': {
        label: 'Model year',
      },
      model: {
        label: 'Model',
      },
      odometer: {
        label: 'Odometer',
      },
      origin: {
        label: 'Source of Evaluation',
      },
      'preliminary-value': {
        label: 'Evaluation amount',
      },
      'reference-id': {
        label: 'Reference number',
        placeholder: '# ref',
      },
      'reference-link': {
        label: 'Link',
      },
      'reference-title': {
        label: 'Title',
      },
      series: {
        label: 'Series',
      },
      steps: {
        customer: {
          label: 'Customer Info',
          subLabel: 'About 3 Min',
        },
        general: {
          label: 'General',
          subLabel: 'About 1 Min',
        },
        question: {
          label: 'Question',
          subLabel: 'About 4 Min',
        },
        vehicle: {
          label: 'Vehicle Information',
          subLabel: 'About 3 Min',
        },
      },
      title: 'Listing',
      'v-auto-id': {
        label: 'vAuto',
      },
      vin: {
        label: 'VIN',
      },
    },
    login: {
      btn: 'Login',
      email: {
        label: 'Email',
        required: 'Email is required',
      },
      invalid: 'Your login attempt was not successful.',
      password: {
        label: 'Password',
        required: 'Password is required',
      },
      title: 'Sign into your account',
    },
    massage: {
      'images-save': 'Images save',
    },
    navigations: {
      'conditions-inspections': 'Inspections',
      'conditions-manager': 'Condition manager',
      'evaluation-analytics-label': 'Analytical section',
      'evaluation-analytics': 'Camera permission',
      'evaluation-admin': 'Administration section',
      'conditions-reports': 'CONDITION REPORTS',
      'conditions-works': 'Work',
      dashboard: 'Dashboard',
      feature: 'Feature',
      'physical-evaluations': 'Listing',
      'physical-evaluations-menu': 'Physical Evaluations',
      'user-evaluation-menu': 'User evaluation',
      'user-evaluation-menu-list': 'Vehicle  list',
      'physical-evaluations-new': 'New',
      'profile-label': 'Admin',
      'profile-list': 'Users',
      'under-evaluations': 'Under Evaluations',
      'virtual-evaluations': 'Listing',
      'virtual-evaluations-menu': 'Virtual EValuation',
      'dashboard-statistic': 'Statistics',
      'dashboard-statistic-my': 'My statistics',
      'virtual-evaluations-new': 'New',
      'procent-settings': 'Location procent price',
      'appraisal-conditions-title': 'Appraisal conditions',
      'appraisal-conditions-new': 'Add appraisal conditions',
      'copilot-ai': 'Copilot AI',
      'appraisal-conditions-list': 'Appraisal conditions list',
      administration: 'Administration',
      'administration-users': 'Users',
    },
    notificationSystem: {
      PE_SUBMITTED: {
        title: 'Evaluation submitted',
        message: '{{name}} at {{dealership}}',
      },
      PE_EVALUATED: {
        title: 'Evaluation conducted',
        message: '{{name}} at {{dealership}}',
      },
      PE_STARTED: {
        title: 'Evaluation started',
        message: '{{name}} at {{dealership}}',
      },
      PE_PRICE_CHANGED: {
        title: 'Evaluation Price changed',
        message: '{{name}} at {{dealership}}',
      },
      VE_SUBMITTED: {
        title: 'Virtual Evaluation submitted',
        message: '{{name}} at {{dealership}}',
      },
      VE_EVALUATED: {
        title: 'Virtual Evaluation conducted',
        message: '{{name}} at {{dealership}}',
      },
      VE_STARTED: {
        title: 'Virtual Evaluation started',
        message: '{{name}} at {{dealership}}',
      },
      VE_PRICE_CHANGED: {
        title: 'Virtual Evaluation Price changed',
        message: '{{name}} at {{dealership}}',
      },
      CR_CREATED: {
        title: 'Condition Report created',
        message: '{{name}} at {{dealership}}',
      },
      CR_APROVED: {
        title: 'Condition Report approved',
        message: '{{name}} at {{dealership}}',
      },
    },
    notification: {
      email: 'Email',
      notification: 'Notification',
      sms: 'Sms',
      title: 'Subscription',
    },
    notifications: {
      title: 'Notifications',
    },
    origins: {
      buy: 'Sell only',
      exchange: 'Trade-in',
      inventory: 'Aged unit',
      service: 'In service department',
    },
    pictures: {
      'photos-certify':
        'I certify that i declared all damages and have taken the proper photos',
      back: 'back',
      backleft: 'backleft',
      backright: 'back right',
      backseat: 'back seat',
      board: 'board',
      camera: 'camera',
      damage: 'damage {{number}}',
      dashboard: 'dashboard',
      front: 'front',
      frontlateralleft: 'front lateral left',
      frontlateralright: 'front lateral right',
      frontleft: 'front left',
      frontright: 'front right',
      generic: 'generic {{number}}',
      interiorback: 'interior back',
      interiorfront: 'interior front',
      interiorrear: 'interior rear',
      left: 'left',
      leftzoomback: 'left zoom back',
      leftzoomfront: 'left zoom front',
      mags: 'mags',
      middlelateralleft: 'middle lateral left',
      middlelateralright: 'middle lateral right',
      odometer: 'odometer',
      rear: 'rear',
      rearlateralleft: 'rear lateral left',
      rearlateralright: 'rear lateral right',
      rearleft: 'rear left',
      rearright: 'rear right',
      right: 'right',
      rightzoomback: 'right zoom back',
      rightzoomfront: 'right zoom front',
      roof: 'roof',
      seat: 'seat',
      tread: 'tread',
      trunk: 'trunk',
      underhood: 'underhood',
      vin: 'vin',
      vinplate: 'vin plate',
    },
    PICTURES: {
      FRONTLATERALLEFT: 'front side left',
      REARLATERALLEFT: 'read side left',
      REARLATERALRIGHT: 'rear side right',
    },
    profile: {
      any: {
        completed: 'Inform me when a physical assessment is complete',
        create: 'Inform me when a new physical assessment is created',
        new: 'Inform me when a new physical assessment is ready',
      },
      appraisal: {
        any: {
          assessed: 'Inform me when a condition report is completed',
          assessment: 'Inform me when a condition report is added',
          challenged: 'Inform me when a claim is added to a condition report',
          pending:
            'Notify me when a condition report for ({{locationUser}}) is pending approval',
          resolved: 'Inform me when a claim reaches resolution',
          resubmit:
            'Inform me when a condition report is submitted for review (new request)',
          returned:
            'Inform me when a condition report is returned to the seller (at the auction)',
        },
      },
      chat: {
        id: {
          assessing:
            'Subscribe [automatically] to chat communications when I start a condition report',
          claim:
            'Subscribe [automatically] to chat communications when I make a claim on a condition report',
          created:
            'Subscribe [automatically] to a virtual evaluation chat when I create a registration',
          'created-appraisal':
            'Subscribe [automatically] to a physical assessment chat when I create an application',
          send: 'Subscribe [automatically] to a physical evaluation chat when I write in the chat',
          sms: 'Subscribe [automatically] to text messages in a virtual evaluation when I create a registration',
          started:
            'Subscribe [automatically] to a virtual evaluation chat when I start a registration',
          'started-appraisal':
            'Subscribe [automatically] to a physical assessment chat when I start an application',
        },
        listing: {
          id: {
            created:
              'Subscribe [automatically] to text messages in a virtual evaluation when I create a registration',
            send: 'Subscribe [automatically] to a virtual evaluation chat when I write in the chat',
            started:
              'Subscribe [automatically] to a virtual assessment update when I start a registration',
          },
        },
      },
      email: {
        label: 'Email',
      },
      'first-name': {
        label: 'First name',
      },
      id: {
        claim:
          'Subscribe [automatically] to the update of a condition report when I make a claim',
        created:
          'Subscribe [automatically] to a physical assessment update when I create an application',
        started:
          'Subscribe [automatically] to a physical assessment update when I start an application',
      },
      'last-name': {
        label: 'Last name',
      },
      listing: {
        any: {
          completed: 'Inform me when a virtual assessment is complete',
          create: 'Inform me when a new virtual assessment is created',
          created:
            'Subscribe [automatically] to a virtual evaluation chat when I create a registration',
          new: 'Inform me when a new virtual assessment is ready',
        },
      },
      'me-page': {
        country: 'Country',
        email: 'Email',
        language: 'Language',
        phone: 'Phone',
        region: 'Region',
        role: 'Role',
        segmentation: 'Segmentation',
        settings: {
          distance: 'Distance',
          language: 'Language',
          title: 'Default Settings',
        },
        teams: 'Teams',
      },
      phone: {
        label: 'Phone',
      },
      role: 'Role',
      segmentation: 'Segmentation',
      subscription: {
        title: 'Subscription',
      },
      teams: 'Teams',
    },
    question: {
      'customer-estimate': {
        label: 'Estimate Visible to customer',
      },
      evaluator: {
        label: 'Evaluator',
      },
      client: {
        label: 'Client comment',
      },
      'excellente-condition': {
        description:
          'The car still has its best years ahead. It is beautiful. It smells good. It works very well. The drive-thru employees sometimes compliment her.',
        label: 'Excellent condition',
      },
      'good-condition': {
        description:
          "The car may not have reached its peak yet, but it is getting close. The exterior has its fair share of bumps and bruises. The interior looks a bit drab. Friends ask if you're in the market for a new car in a way that feels a bit judgmental.",
        label: 'Good condition',
      },
      'has-aftermarket': {
        label: 'Does the vehicle have custom made parts?',
      },
      'has-dept': {
        label: 'Does it have any financing associated with this vehicle?',
      },
      'how-many-keys': {
        label: 'How many keys for this vehicle?',
      },
      'needs-repairs': {
        label:
          'Does the vehicle have any needed repairs or dashboard warnings?',
      },
      no: 'No',
      'no-keys': 'Any',
      'previously-damaged': {
        label: 'Has the vehicle been damaged previously?',
      },
      'rugged-condition': {
        description:
          'The car has seen better days. Needs painting. Needs repairs. Needs parts. It has four wheels, and a lot of good memories, but not much else.',
        label: 'Slightly rugged',
      },
      seller: {
        label: 'Seller',
      },
      'what-condition-car': 'What condition is the vehicle in?',
      yes: 'Yes',
      'engine-make-a-weird-noise':
        'Does the engine make a weird noise / Does it need to be replaced?',
      'transmission-slip-work':
        'Does the transmission slip/work properly / Does it need to be changed?',
    },
    questionUs: {
      'other-issue': 'Other issue',
      number: 'Number',
      'question-required': 'All question is required',
      other: 'Other',
      'question-one': {
        title: 'What is the overall condition of the vehicle?',
        option1:
          'Outstanding - Exceptional Mechanical, exterior and interior condition with no visible wear; no reconditioning required.',
        option2:
          'Clean - Minimal wear and tear with no major mechanical or cosmetic problems; may require limited reconditioning.',
        option3:
          'Average - Normal wear and tear. May have a few mechanical and/or cosmetic problems and may require some reconditioning.',
        option4:
          'Rough - Several mechanical and/or cosmetic problems requiring repairs.',
        option5:
          'Damaged - Major mechanical and/or body damage that may render it in non-safe running condition.',
      },
      'question-two': {
        title: 'Has the vehicle ever been in an accident?',
      },
      'question-three': {
        title: 'Are there any mechanical or other issues present?',
        option1: 'None observed',
        option2: 'AC issues',
        option3: 'Brake issues',
        option4: 'Frame damage',
        option5: 'Missing standard equipment',
        option6: 'Noticeable smoke, pet, or mildew odor',
        option7: 'Suspension issues',
        option8: 'Other issues',
      },
      'question-four': {
        title: 'Are there any issues noted with the engine?',
        option1: 'Engine noise',
        option2: 'Runs improperly',
        option3: 'No runner',
      },
      'question-five': {
        title: 'Are there any issues noted with the transmission?',
        option1: 'Clutch needs service',
        option2: 'Missing gear',
        option3: 'Not engaging',
        option4: 'Shifts hard',
        option5: 'Slipping',
      },
      'question-six': {
        title: 'Are there any panels in need of paint or body work?',
        option1: '1',
        option2: '2',
        option3: '3 or more',
      },
      'question-seven': {
        title: 'Are there any warning lights currently on?',
        option1: 'Check engine',
        option2: 'ABS/Brakes',
        option3: '4x4 needs repair',
        option4: 'Airbag',
        option5: 'Transmission',
        option6: 'Other',
      },
      'question-eight': {
        title: 'Is there any perforating rust present on the vehicle?',
        option1: 'Yes - on the body',
        option2: 'Yes - on the undercarriage',
      },
      'question-nine': {
        title: 'Are any interior parts broken or inoperable?',
        option1: '1',
        option2: '2',
        option3: '3 or more',
      },
      'question-ten': {
        title: 'Has the vehicle been lifted or lowered?',
      },
      'question-eleven': {
        title: 'Have engine modifications been made?',
      },
      'question-thirteen': {
        title: 'Do any tires need to be replaced?',
        option1: '1',
        option2: '2',
        option3: '3 or more',
      },
      'question-fourteen': {
        title: 'Are there any interior parts with rips, tears, or stains?',
        option1: '1',
        option2: '2',
        option3: '3 or more',
      },
    },
    settings: {
      distances: {
        km: 'Kilometers',
        mi: 'Miles',
      },
      languages: {
        en: 'English',
        fr: 'French',
      },
      'button-save': 'save changes',
    },
    side: {
      back: 'back',
      front: 'front',
      interior: 'interior',
      left: 'left',
      right: 'right',
      top: 'top',
    },
    sources: {
      buy: 'Sell only',
      exchange: 'Trade-in',
      inventory: 'Aged unit',
      service: 'In service department',
      takenIn: 'Taken-In',
    },
    statuses: {
      acquired: 'Purchased',
      acquiring: 'Offers retained',
      acquisition: 'Offer presented',
      approved: 'Approved',
      assessed: 'Report Completed',
      assessing: 'Report Started',
      assessment: 'Report Ordered',
      cancelled: 'Cancelled',
      cloned: 'Clone',
      closed: 'Clone',
      conditioned: 'Conditioned',
      conditioning: 'Conditioning',
      draft: 'Draft',
      evaluated: 'Open offer',
      evaluating: 'In progress',
      evaluation: 'New',
      expired: 'Offer expired',
      pending: 'Pending Report',
      rejected: 'Rejected',
      returned: 'Return to seller',
      sold: 'Sold',
      wait: 'Wait PAVE images',
      waite: 'Waite image',
    },
    track: {
      add: 'Added by',
      completed: 'Completed by',
      report: 'Report started by',
      submitted: 'Submitted by',
      scan: 'Scan',
    },
    'traction-types': {
      '4wd': '4x4 (4WD)',
      awd: 'Integral (AWD)',
      fwd: 'Front (FWD)',
      rwd: 'Rear (RWD)',
    },
    'transmission-types': {
      automatic: 'Automatic',
      cvt: 'CVT',
      manual: 'Manual',
      manuelle: 'Manual',
    },
    'vehicle-report': {
      form: {
        'to-be-repaired': 'To be repaired',
        na: 'N/A',
        'requires-verification': 'Requires verification',
      },
      accessories: {
        label: 'Accessories',
      },
      'brakes-fitst': {
        label: 'Brakes #1',
      },
      'brakes-second': {
        label: 'Brakes #2',
      },
      circle: {
        'abs-sistem': 'ABS system',
        accompaniement: 'Accompaniment',
        'adjustable-steering-wheel': 'Adjustible steering wheel',
        'air-filter': 'Air filter',
        alignment: 'Alignment',
        alternator: 'Alternator',
        'anchor-bolt': 'Anchor bolt',
        'arm-suspension': 'Lower control arm',
        'arm-suspension-right': 'Upper control arm',
        'back-system-windscreen-washer': 'Front widshield washer system',
        'battery-cable': 'Battery cable',
        'before-discs': 'Front discs',
        'before-system-windscreen-washer': 'Rear window washer system',
        bellows: 'Bellows',
        'belt-seat-security-back': 'Belt seat security back',
        'belt-seat-security-before': 'Belt seat security before',
        'bench-and-seats': 'Bench and seats',
        binoculars: 'Binoculars',
        'blade-composite': 'Composite blade',
        'blade-stirrup': 'Blade',
        'bolts-dowels-nuts': 'Bolts, studs and nuts',
        'brake-fluid-level': 'Brake fluid - level',
        'brake-parking': 'Parking brake',
        'bus-ducts-fittings': 'Conduits/Fittings - bottom',
        canalisatins: 'Pipelines',
        'canalisation-air': 'Piping (air)',
        'case-management': 'Case management',
        catalyst: 'Catalyst',
        ceiling: 'Ceiling',
        clutch: 'Clutch',
        'connecting-rod-connection': 'Connecting rod connection',
        connection: 'Connection',
        'connection-rob-mating': 'Coupling rod',
        'control-level': 'Control level',
        'convertible-roof': 'Convertible roof',
        cork: 'Reservoir',
        'coupling-bar': 'Tie rod',
        'cousin-inflatable': 'Airbag (witness)',
        'cousin-rubber': 'Rubber pad',
        'cover-battery-box': 'Battery compartment cover',
        'cylinder-wheel': 'Wheel cylinder',
        'dashboard-lighting': 'Dashboard (lighting)',
        'dashboard-lighting-big': 'Check engine light',
        'daytime-running-lights-left': 'Daytime running lights - left',
        'daytime-running-lights-right': 'Daytime running lights - right',
        defrost: 'Defrost',
        'direction-lights': 'Direction lights - front',
        'direction-lights-rear': 'Direction lights - rear',
        'direction-lights-left': 'Direction lights - left',
        'direction-lights-right': 'Direction lights - right',
        discs: 'Rear discs',
        drums: 'Rear drum',
        'ducts-fittings': 'Ducts/Fittings',
        'element-of-fixation': 'Fixing element',
        'engine-oil': 'Engine oil',
        'fixing-element': 'Fixing element',
        flying: 'Steering wheel',
        'four-check': '4x4 verification',
        'front-windshield': 'Windshield - front',
        gauge: 'Gauge',
        gimbal: 'Gimbal',
        'glove-other-storage': 'Glove box and other storage',
        'hazard-lights-back': 'Hazard warning lights - rear',
        'hazard-lights-before': 'Hazard lights (Before)',
        'hazard-lights-central': 'Stop lights - central',
        'hazard-lights-front': 'Hazard warning lights - front',
        'hazard-lights-left': 'Stop lights - left',
        'hazard-lights-left-smoll': 'Hazard warning lights - left',
        'hazard-lights-right': 'Stop lights - right',
        'hazard-lights-right-smoll': 'Hazard warning lights - right',
        'head-support': 'Headrest',
        'headlight-cover': 'Headlight cover',
        'headlights-left': 'High beams - left',
        'headlights-right': 'High beams - right',
        hinge: 'Rear trim',
        'hinge-discs': 'Front trim',
        honk: 'Horn',
        'idler-arm': 'Idler arm',
        'inner-ball-joint': 'Inner ball joint',
        'inner-end': 'Inner end',
        'internal-sun-visor': 'Interior sun visor',
        'jank-and-tools-rescue': 'Emergency jack and tools',
        'left-bellows': 'Bellows - left',
        'left-belt-seat-security-back': 'Rear seat safety belt - left',
        'left-belt-seat-security-before': 'Front seat safety belt - left',
        'left-inner-ball-joint': 'Inner ball joint - left',
        'left-inner-end': 'Inner end cap - left',
        'left-mechanism-siege-back': 'Rear seat mechanism - left',
        'left-mechanism-siege-before': 'Front seat mechanism - left',
        'left-mirrir-outside': 'Exterior mirror - left',
        'left-outer-ball-joint': 'Outer ball joint - left',
        'left-outer-end': 'Outer end cap - straight',
        'license-plate-lights': 'License plate lights',
        'light-switch': 'Switch',
        'light-switch-back': 'Light switch (Back)',
        'light-switch-before': 'Light switch (Before)',
        'light-switch-left': 'Light switch (Left)',
        'light-switch-right': 'Light switch (Right)',
        'lighthouses-lights-left': 'Fog lamps - left',
        'lighthouses-lights-right': 'Fog lamps - right',
        'liquid-level': 'Liquid level',
        'lock-door': 'Door lock',
        'loud-speaker-back': 'Rear speaker - left',
        'loud-speaker-back-right': 'front speaker - right',
        'loud-speaker-before': 'Front speaker - left',
        'loud-speaker-right': 'Hrear speaker - right',
        'making-running': 'Power outlet',
        'master-blade': 'Master blade',
        'master-cylinder': 'Master cylinder',
        'mechanism-eiege-before': 'Mechanism siege before',
        'mechanism-siege-back': 'Mechanism siege back',
        'mechanism-siege-before': 'Mechanism siege before',
        'mechanism-windows': 'Window mechanism (AVG)',
        'mechanism-windows-AVD': 'Window mechanism (AVD)',
        'mechanism-windows-ARD': 'Window mechanism (ARD) ',
        'mechanism-windows-ARG': 'Window mechanism (ARG)',
        'mirrir-interior': 'Exterior mirror',
        'mirrir-outside': 'Mirror outside',
        mudguard: 'Mudguards',
        'oil-filter': 'Oil filter',
        'oil-leak-motor': 'Engine oil leak',
        'oil-leak-transmission': 'Transmission oil leak',
        'opening-trunk': 'Trunk opening',
        'ordered-traction': 'Traction control (warning light)',
        'outer-ball-joint': 'Outer ball joint',
        'outer-end': 'Outer arm',
        'pedal-of-brake': 'Brake pedal',
        'pipe-air': 'Pipe (air)',
        pipeline: 'Pipes',
        pipelines: 'Pipelines',
        'pipes-exhaust': 'Exhaust pipes',
        'power-steering': 'Power steering',
        pump: 'Pump (power steering)',
        quiet: 'Silent',
        raccerd: 'Fitting',
        'race-brake-pedal': 'Brake pedal stroke',
        rack: 'Rack',
        radio: 'Radio',
        'rear-windshield': 'Windshield - rear',
        reasonator: 'Resonator',
        reflector: 'Reflector',
        'remote-control-door': 'Door remote',
        'reversing-lights-left': 'Reversing lights - left',
        'reversing-lights-right': 'Reversing lights - right',
        'right-bellows': 'Bellows - right',
        'right-belt-seat-security-before': 'Rear seat safety belt - right',
        'right-inner-ball-joint': 'Inner ball joint - right',
        'right-inner-end': 'Inner end cap - straight',
        'right-mechanism-siege-back': 'Right rear seat mechanism',
        'right-mechanism-siege-before': 'Front seat mechanism - right',
        'right-mirrir-outside': 'Exterior mirror - right',
        'right-outer-ball-joint': 'Outer ball joint - right',
        'right-outer-end': 'Outer cap - left',
        'rims-mags-or-steel': 'Rims/mags or steel',
        'rocket-lever': 'Stub lever',
        'rolling-wheels': 'Wheel bearing (ARD)',
        'rolling-wheels-ARG': 'Wheel bearing (ARG)',
        'rolling-wheels-AVD': 'Wheel bearing (AVD) ',
        'rolling-wheels-AVG': 'Wheel bearing (AVG)',
        'seats-heated': 'Ceiling',
        'shock-absorbers-back': 'Rear shock absorbers - left',
        'shock-absorbers-back-right': 'Rear shock absorbers - right',
        'shock-absorbers-before': 'Front shock absorbers - left',
        'shock-absorbers-before-right': 'Front shock absorbers - right',
        'shock-management': 'Steering',
        'side-window': 'Side window (AVG)',
        'side-window-AVD': 'Side window (AVD)',
        'side-window-ARG': 'Side window (ARG)',
        'side-window-ARD': 'Side window (ARD)',
        'sidelights-left': 'Position lights - left',
        'sidelights-right': 'Position lights - right',
        'sliding-joint': 'Slip joint',
        'spacer-wheel': 'Wheel',
        'spring-support-blade': 'Leaf spring support',
        springs: 'Springs',
        'stabilizing-bar': 'Stabilizer bar',
        'steering-column': 'Steering lever',
        'steering-lever': 'Steering lever',
        stirrups: 'Calipers - front',
        'stop-lights-left': 'Stop lights (Left)',
        'stop-lights-right': 'Stop lights (Right)',
        'structure-protective': 'Protective structure',
        'summer-winter-tires': 'Summer/Winter tires',
        sunroof: 'Sunroof',
        'support-engine': 'Motor',
        'support-transmission': 'Transmission',
        suspension: 'Suspension',
        'suspension-pneumatic': 'Air suspensionc',
        'system-of-conditioning': 'Air conditioning system',
        'system-of-heating': 'Heating system',
        'system-windscreen-washer': 'System windscreen washer',
        tank: 'Reservoir',
        'tank-gasoline': 'Fuel tank',
        'thick-headed-electric': 'Electrical terminal',
        tire: 'ARD tire',
        tireARG: 'ARG tire',
        tireAVD: 'AVD tire',
        tireAVG: 'AVG tire',
        'top-ducts-fittings': 'Conduits/Fittings - top',
        'torsion-bar': 'Torsion bar',
        'tree-transmission': 'Drive shaft protection',
        'universal-joint': 'Universal joint',
        'valve-level': 'Level valve',
        'valves-tpms-valves': 'TPMS valves',
        windshield: 'Windshield',
        'wiper-back': 'Rear wiper',
        'wiper-before': 'Front wiper',
        'witness-failure': 'Witness failure (dashboard)',
      },
      'class-and-mirrors': {
        label: 'Class and mirrors',
      },
      'direction-first': {
        label: 'Direction #1',
      },
      'direction-second': {
        label: 'Direction #2',
      },
      'direction-third': {
        label: 'Direction #3',
      },
      'electrical-system': {
        label: 'Electrical system',
      },
      'engine-exhaust-transmission-one': {
        label: 'Engine exhaust transmission #1',
      },
      'engine-exhaust-transmission-two': {
        label: 'Engine exhaust transmission #2',
      },
      'lighting-and-signaling-first': {
        label: 'Lighting and signaling #1',
      },
      'lighting-and-signaling-second': {
        label: 'Lighting and signaling #2',
      },
      maintenance: {
        label: 'Maintenance',
      },
      'security-first': {
        label: 'Security #1',
      },
      'security-second': {
        label: 'Security #2',
      },
      'summer-winter-tires': 'Summer/Winter tires',
      'suspension-first': {
        label: 'Suspension #1',
      },
      'suspension-second': {
        label: 'Suspension #2',
      },
      'tires-wheels': {
        label: 'Tires Wheels',
      },
    },
    users: {
      fields: {
        'first-name': {
          label: 'First name',
        },
        'last-name': {
          label: 'Last name',
        },
        email: {
          label: 'Eemail',
        },
        'phone-number': {
          label: 'Phone number',
        },
        country: {
          label: 'Country',
        },
        dealership: {
          label: 'Dealership',
        },
        roels: {
          label: 'Roles',
        },
      },
      notifications: {
        successful: 'User updated sucessful',
        error: 'User updated failed',
      },
    },
    'sign-in': {
      notifications: {
        error: `Authorization failed. Try again later`,
      },
    },
    'sign-up': {
      texts: {
        help: 'Please choose your country, dealership and contact with your manager',
      },
      fields: {
        country: {
          label: 'Country',
          required: 'Country is required',
        },
        dealership: {
          label: 'Dealership',
          required: 'Dealership is required',
        },
      },
      notifications: {
        error: `Registration failed. Try again later`,
      },
    },
  },
};
