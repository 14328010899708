import { StepItem } from 'ui/components/Stepper/types';
import { EditSteps } from './types';
import VehicleForm from '../../appraisal-module/forms/VehicleForm';
import { PeVehicleForm } from 'constants/forms';
import EquipmentForm from '../components/EquipmentForm';
import CustomerForm from '../../appraisal-module/forms/CustomerForm';
import QuestionForm from '../components/QuestionForm';
import PicturesForm from '../components/PicturesForm';

export const PhysicalEvalEditFormComponents = {
  [EditSteps.Vehicle]: VehicleForm,
  [EditSteps.Equipment]: EquipmentForm,
  [EditSteps.Customer]: CustomerForm,
  [EditSteps.Questions]: QuestionForm,
  [EditSteps.Pictures]: PicturesForm,
};

export const HiddenTitleSteps = [EditSteps.Vehicle];

export const PhysicalEvalEditSteps: StepItem[] = [
  {
    key: EditSteps.Vehicle,
    label: 'appraisal.steps.vehicle.label',
    subLabel: 'appraisal.steps.vehicle.subLabel',
  },
  {
    key: EditSteps.Equipment,
    label: 'appraisal.steps.equipment.label',
    subLabel: 'appraisal.steps.equipment.subLabel',
  },
  {
    key: EditSteps.Customer,
    label: 'appraisal.steps.customer.label',
    subLabel: 'appraisal.steps.customer.subLabel',
  },
  {
    key: EditSteps.Questions,
    label: 'appraisal.steps.question.label',
    subLabel: 'appraisal.steps.question.subLabel',
  },
  {
    key: EditSteps.Pictures,
    label: 'appraisal.steps.photos.label',
    subLabel: 'appraisal.steps.photos.subLabel',
  },
];

export const VehicleDetailKeys = [
  {
    labelKey: 'engine',
    formKey: PeVehicleForm.Keys.EngineCylinderLabel,
    flexBasis: 190,
  },
  {
    labelKey: 'engine-cylinder-count',
    formKey: PeVehicleForm.Keys.EngineCylinderCount,
    flexBasis: 150,
  },
  {
    labelKey: 'traction',
    formKey: PeVehicleForm.Keys.Traction,
    flexBasis: 170,
  },

  {
    labelKey: 'transmission-type',
    formKey: PeVehicleForm.Keys.TransmissionType,
    flexBasis: 180,
  },
];

export const RequiredQuestions = [
  'question-one',
  'question-two',
  'question-three',
  'question-four',
  'question-five',
  'question-six',
  'question-seven',
  'question-eight',
  'question-nine',
  'question-ten',
  'question-eleven',
  'question-fourteen',
  'question-thirteen',
];

export const GenericSight = 'generic';
export const sights: Record<string, string> = {
  'vwtroc-vLcBGkeh': 'front',
  'vwtroc-EqLDVYj3': 'frontright',
  'vwtroc-PuIw17h0': 'frontlateralright',
  'vwtroc-B5s1CWT-': 'middlelateralright',
  'vwtroc-AoO-nOoM': 'rearlateralright',
  'vwtroc-Cce1KCd3': 'rearright',
  'vwtroc-XyeyZlaU': 'rear',
  'vwtroc-GHbWVnMB': 'rearleft',

  'vwtroc-GvCtVnoD': 'rearlateralleft',
  'vwtroc-UHZkpCuK': 'middlelateralleft',
  'vwtroc-xQKQ0bXS': 'frontlateralleft',
  'vwtroc-xsuH1g5T': 'frontleft',

  rSvk2C: 'dashboard',
  IqwSM3: 'interiorfront',
  rj5mhm: 'interiorrear',
  qhKA2z: 'trunk',
  // sLu0CfOt: 'vinplate',
};
