import React from 'react';
import { RoutesConfig } from 'router/routes.config';

const dashboardRoutes = [
  {
    path: RoutesConfig.Dashboard,
    component: React.lazy(() => import('modules/dashboard/Dashboard')),
  },
];

export default dashboardRoutes;
