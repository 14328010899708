import React from 'react';
import clsx from 'clsx';
import ScrollBar from 'react-perfect-scrollbar';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { convertHexToRGB } from 'utils';

import { VERSION } from 'constants/config/app-variables';

import Brand from './brand';

const useStyles = makeStyles((theme) => ({
  sidenav: ({ width }: { width: string }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: width,
    boxShadow: theme.shadows[8],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    zIndex: 111,
    overflow: 'hidden',
    color: theme.palette.primary.contrastText,
    transition: 'all 250ms ease-in-out',
    backgroundImage: `linear-gradient(to bottom, rgba(${convertHexToRGB(
      theme.palette.primary.main
    )}, 0.96), rgba(${convertHexToRGB(
      theme.palette.primary.main
    )}, 0.96)), url('assets/images/sidebar/sidebar-bg-dark.jpg')`,
    '&:hover': {
      width: 'var(--sidenav-width)',
      '& .sidenavHoverShow': {
        display: 'block',
      },
      '& .compactNavItem': {
        width: '100%',
        maxWidth: '100%',
        '& .nav-bullet': {
          display: 'block',
        },
        '& .nav-bullet-text': {
          display: 'none',
        },
      },
    },
  }),
  scrollable: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  sidenavMobileOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.54)',
    zIndex: -1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  version: {
    position: 'fixed',
    left: theme.spacing(4),
    bottom: theme.spacing(2),
  },
}));

type SidenavProps = {
  children: React.ReactNode;
  mode: SidebarMode;
  onOverlayClick: () => void;
};

const Sidenav = (props: SidenavProps) => {
  const getSidenavWidth = () => {
    switch (props.mode) {
      case 'compact':
        return 'var(--sidenav-compact-width)';
      default:
        return 'var(--sidenav-width)';
    }
  };

  const classes = useStyles({
    width: getSidenavWidth(),
  });

  return (
    <div className={classes.sidenav}>
      <div className="flex-column relative h-full">
        <Brand />
        <Typography variant="caption" className={classes.version}>
          {VERSION}
        </Typography>
        <ScrollBar
          options={{ suppressScrollX: true }}
          className={clsx('relative px-4', classes.scrollable)}
        >
          {props.children}
        </ScrollBar>
        <div
          onClick={props.onOverlayClick}
          className={classes.sidenavMobileOverlay}
        />
      </div>
    </div>
  );
};

export default Sidenav;
