import React, { createElement, FC, useEffect } from 'react';
import Stepper from 'ui/components/Stepper';
import {
  PhysicalEvalEditFormComponents,
  PhysicalEvalEditSteps,
} from './constants/values';
import { useGetCamera } from 'hooks';
import { Box } from '@mui/material';
import FormTitle from './components/FormTitle';
import {
  FormWrapper,
  Wrapper,
} from 'modules/appraisal-module/components/EditAppraisalWrappers';
import StepNavigation from './components/StepNavigation';
import usePhysicalEvalEditForm from './hooks/usePhysicalEvalEditForm';
import { FormProvider } from 'react-hook-form';
import useSaveEval from './hooks/useSaveEval';
import useAppraisalQuery from './hooks/useFetchAppraisal';
import EditRequestBodyTransformer from './util/EditRequestBodyTransformer';
import useInitMonkaiInspection from './hooks/useInitMonkaiInspection';
import usePeEditStepper from 'store/pre-edit-stepper.store';
import usePipeQuestionSubmit from './hooks/usePipeQuestionSubmit';
import { EditSteps } from './constants/types';
import { useFeedback } from '../../hooks/useFeedback';
import { useTranslation } from 'react-i18next';
import usePipePicturesSubmit from './hooks/usePipePicturesSubmit';
import useSubmitEval from './hooks/useSubmitEval';
import { useSearchParams } from 'react-router-dom';

const EditEvalPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get('step');
  const { t } = useTranslation();
  const { shwoError } = useFeedback();
  const { refetch, loading } = useAppraisalQuery();
  useInitMonkaiInspection(refetch);
  useGetCamera();

  const { activeStep, resetStepper, goToStep } = usePeEditStepper();

  useEffect(() => {
    if (step) return goToStep(Number(step) as EditSteps);
    resetStepper();
  }, [step]);

  useEffect(() => {
    setSearchParams({ step: activeStep.toString() });
  }, [activeStep]);

  const { handlePipeQuestions } = usePipeQuestionSubmit();
  const { handlePipePictures } = usePipePicturesSubmit();
  const currentFormMethods = usePhysicalEvalEditForm();
  const { handleSaveEval } = useSaveEval();
  const { handleSubmit, isLoadingPictures } = useSubmitEval(refetch);

  const handleSaveClick = currentFormMethods.handleSubmit(
    async (data) => {
      if (activeStep === EditSteps.Pictures) {
        handlePipePictures();
        return handleSubmit();
      }

      const transformedData = EditRequestBodyTransformer.transform(
        data,
        activeStep
      );

      const saveData = await handlePipeQuestions(transformedData);

      handleSaveEval(saveData);
    },
    () => {
      if (activeStep === EditSteps.Questions) {
        return shwoError(t('questionUs.question-required'));
      }
    }
  );

  return (
    <>
      <Stepper activeStep={activeStep} steps={PhysicalEvalEditSteps} />

      <Wrapper>
        <FormTitle />

        <FormProvider {...currentFormMethods}>
          <FormWrapper onSubmit={handleSaveClick}>
            {createElement(PhysicalEvalEditFormComponents[activeStep])}

            <Box flex={1} />

            <StepNavigation isLoading={loading || isLoadingPictures} />
          </FormWrapper>
        </FormProvider>
      </Wrapper>
    </>
  );
};

export default EditEvalPage;
