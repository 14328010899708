import React from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { Box, RadioGroup, RadioGroupProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface RadioGroupFieldProps extends RadioGroupProps {
  errors?: FieldError;
}

const useStyles = makeStyles((theme) => ({
  withError: {
    color: theme.palette.error.main,
  },
}));

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  errors = null,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box display="grid">
      <RadioGroup
        className={clsx(className, { [classes.withError]: !isEmpty(errors) })}
        {...rest}
      />
    </Box>
  );
};

export default RadioGroupField;
