import React, { FC } from 'react';
import { EditSteps } from '../constants/types';
import usePeEditStepper from 'store/pre-edit-stepper.store';
import useStore from 'store/pe-edit-appraisal.store';
import { useFormContext } from 'react-hook-form';
import { PePicturesForm } from 'constants/forms';
import StepButtons from 'modules/appraisal-module/components/StepButtons';

type Props = {
  isLoading: boolean;
};

const StepNavigation: FC<Props> = ({ isLoading }) => {
  const { isSkipEquipment } = useStore();
  const { activeStep, prevStep, goToStep } = usePeEditStepper();
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const handlePrevStep = () => {
    if (activeStep === EditSteps.Customer && isSkipEquipment) {
      return goToStep(EditSteps.Vehicle);
    }

    prevStep();
  };

  const isDisabled = () => {
    switch (activeStep) {
      case EditSteps.Pictures: {
        return !watch(PePicturesForm.Keys.PhotosCertify);
      }
      default: {
        return Object.keys(errors).length > 0;
      }
    }
  };

  return (
    <StepButtons
      isPrevShown={activeStep > 0}
      onPrevStep={handlePrevStep}
      isDisabled={isDisabled()}
      nextName={activeStep === EditSteps.Pictures ? 'Submit' : undefined}
      isLoading={isLoading}
    />
  );
};

export default StepNavigation;
