import React, { FC, PropsWithChildren } from 'react';
import { useUser, useUserChecks } from 'store';
import Loading from 'ui/components/loading';
import NoAccessPage from '../../modules/no-access';

type Props = PropsWithChildren & {
  permissionRequired?: string[] | string;
};

const PermissionGuard: FC<Props> = ({ children, permissionRequired }) => {
  const user = useUser();
  const { isHasAccess } = useUserChecks();

  if (permissionRequired) {
    if (user.id === 0) {
      return <Loading />;
    }

    const hasAccess = Array.isArray(permissionRequired)
      ? permissionRequired?.every((perm) => isHasAccess(perm))
      : isHasAccess(permissionRequired);

    if (hasAccess) {
      return <>{children}</>;
    }

    return <NoAccessPage />;
  }

  return <>{children}</>;
};

export default PermissionGuard;
