import React, { PropsWithChildren, useEffect } from 'react';

import { gql, useSubscription } from '@apollo/client';
import { useSnackbar } from 'notistack';

type Notifications = {
  id: string;
  topic: string;
  userId: string;
  payload: string;
};

type NotificationsDate = {
  notifications: Notifications;
};

const COMMENTS_SUBSCRIPTION = gql`
  subscription notifications {
    notifications {
      id
      topic
      userId
      payload
    }
  }
`;

const NotificationSystem = (props: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useSubscription(COMMENTS_SUBSCRIPTION, {
    context: { clientName: 'notification-subscription' },
  });

  const sendMessage = (data: NotificationsDate) => {
    if (!data) return;

    const { id, topic, payload } = data?.notifications;

    const mutationPayload = JSON.stringify({
      ...JSON.parse(payload),
      topic,
    });

    enqueueSnackbar(mutationPayload, {
      preventDuplicate: true,
      key: id,
      variant: 'warning', //Option "warning" using only this template "warning" change
      autoHideDuration: 5000,
    });
  };

  useEffect(() => {
    if (!loading) sendMessage(data);
  }, [data]);

  return <>{props.children}</>;
};

export default NotificationSystem;
