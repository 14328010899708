import React, { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loading from '../ui/components/loading';
import NotFound from '../modules/not-found';
import SignIn from '../modules/auth/sign-in';
import SignUp from '../modules/auth/sign-up';
import dashboardRoutes from './routes-sets/dashboard.routes';
import virtualEvaluationRoutes from './routes-sets/virtual-evaluation.routes';
import physicalEvaluationRoutes from './routes-sets/physical-evaluation.routes';
import clientMODRoutes from './routes-sets/physical-evaluation.routes';
import assessorRoutes from './routes-sets/assessor.routes';
import workRoutes from './routes-sets/work.routes';
import underEvaluationRoutes from './routes-sets/under-evaluation.routes';
import profileRoutes from './routes-sets/profile.routes';
import conditionDamage from './routes-sets/condition-damage.routes';
import procentSettingsRoutes from './routes-sets/percent-settings.routes';
import appraisalConditionsRoutes from './routes-sets/appraisal-conditions.routes';
import analyticRoutes from './routes-sets/analytics.routes';
import usersManegerRoutes from './routes-sets/users.routes';
import coreServiceRoutes from './routes-sets/user-eval.routes';
import DynamicRedirect from './DynamicRedirect';
import { RoutesConfig } from './routes.config';
import PermissionGuard from './guards/Permission.guard';
import Layout from 'ui/layouts/layout';
import useAnalytics from '../hooks/useAnalytics';
import AuthGuard from './guards/Auth.guard';
import GuestGuard from './guards/Guest.guard';
import DealershipGuard from './guards/Dealership.guard';
import { useIsAuthenticated } from 'store';
const ConditionReport = React.lazy(() => import('modules/conditionreport'));

type AppRoutes = { path?: string; component: React.FC; permission?: string };

const routes: AppRoutes[] = [
  ...dashboardRoutes,
  ...virtualEvaluationRoutes,
  ...physicalEvaluationRoutes,
  ...clientMODRoutes,
  ...assessorRoutes,
  ...workRoutes,
  ...underEvaluationRoutes,
  ...profileRoutes,
  ...conditionDamage,
  // ...featuresRoutes,
  ...procentSettingsRoutes,
  ...appraisalConditionsRoutes,
  ...analyticRoutes,
  ...usersManegerRoutes,
  ...coreServiceRoutes,
  {
    path: '/404/',
    // eslint-disable-next-line react/display-name
    component: () => <Navigate to={`/404/`} />,
  },

  // To support old email links
  {
    path: '/client/:id/',
    component: () => (
      <DynamicRedirect
        to={RoutesConfig.PhysicalEvaluation.ViewOne}
        replaceKeys={['id']}
      />
    ),
  },
];

const Router: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { startGaTracker } = useAnalytics();

  startGaTracker();

  return (
    <Routes>
      <Route
        path={RoutesConfig.Auth.SignIn}
        element={
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        }
      />

      <Route
        path={RoutesConfig.Auth.SignUp}
        element={
          <AuthGuard>
            <SignUp />
          </AuthGuard>
        }
      />

      <Route
        path={`/conditionreport/:id`}
        element={
          <Suspense fallback={<Loading />}>
            <ConditionReport />
          </Suspense>
        }
      />

      <Route path={RoutesConfig.NotFound} element={<NotFound />} />

      {routes.map((obj) => {
        const Component = obj.component;

        return (
          <Route
            key={obj.path}
            path={obj.path}
            element={
              <Suspense fallback={<Loading />}>
                <AuthGuard>
                  <DealershipGuard>
                    <Layout>
                      <PermissionGuard permissionRequired={obj.permission}>
                        <Component />
                      </PermissionGuard>
                    </Layout>
                  </DealershipGuard>
                </AuthGuard>
              </Suspense>
            }
          />
        );
      })}

      <Route
        path="*"
        element={
          <Navigate
            to={
              isAuthenticated
                ? RoutesConfig.Dashboard
                : RoutesConfig.Auth.SignIn
            }
          />
        }
      />
    </Routes>
  );
};

export default Router;
