import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { VinCodeRegexp } from '../../validation/regexps';

export enum Keys {
  Origin = 'origin',
  Odometer = 'odometer',
  OdometerMiles = 'odometerMiles',
  Vin = 'vin',
  ReferenceId = 'referenceId',
  ReferenceTitle = 'referenceTitle',
  ReferenceLink = 'referenceLink',
  AskingValue = 'askingValue',
}

export const Schema = z.object({
  [Keys.Origin]: z.string(),
  [Keys.Odometer]: z
    .string()
    .refine(
      (value) => value.length && Number(value) > 0,
      'common.validation.required'
    ),
  [Keys.OdometerMiles]: z
    .string()
    .refine(
      (value) => value.length && Number(value) > 0,
      'common.validation.required'
    ),
  [Keys.Vin]: z
    .string()
    .trim()
    .min(1, 'common.validation.required')
    .regex(VinCodeRegexp, 'appraisal.vehicle.vin.invalid'),
  [Keys.ReferenceId]: z.string(),
  [Keys.ReferenceTitle]: z.string().min(1, 'common.validation.required'),
  [Keys.ReferenceLink]: z.string(),
  [Keys.AskingValue]: z.string(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.Origin]: '',
  [Keys.Odometer]: '',
  [Keys.OdometerMiles]: '',
  [Keys.Vin]: '',
  [Keys.ReferenceId]: '',
  [Keys.ReferenceTitle]: '',
  [Keys.ReferenceLink]: '',
  [Keys.AskingValue]: '',
};

export const useVehicleForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
