import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, fr } from './lang';
import { useSettings } from './store';

i18n.use(initReactI18next).init({
  resources: {
    en,
    fr,
  },
  lng: useSettings.getState().language,
  // keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
