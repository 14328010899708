import useStore from 'store/pe-edit-appraisal.store';
import { useFormContext } from 'react-hook-form';
import { PeVehicleForm } from 'constants/forms';
import { useEffect } from 'react';

const useResetVehicle = () => {
  const { appraisal } = useStore();
  const { reset } = useFormContext<PeVehicleForm.FormType>();

  useEffect(() => {
    if (appraisal) {
      reset({
        [PeVehicleForm.Keys.Origin]: appraisal.origin ?? '',
        [PeVehicleForm.Keys.Odometer]: appraisal.odometer?.toString() ?? '',
        [PeVehicleForm.Keys.OdometerMiles]:
          appraisal.odometerMiles?.toString() ?? '',
        [PeVehicleForm.Keys.StyleId]: appraisal.styleId ?? '',
        [PeVehicleForm.Keys.ModelYear]: appraisal.modelYear?.toString() ?? '',
        [PeVehicleForm.Keys.Make]: appraisal.make ?? '',
        [PeVehicleForm.Keys.Model]: appraisal.model ?? '',
        [PeVehicleForm.Keys.Series]: appraisal.series ?? '',
        [PeVehicleForm.Keys.EngineCylinderCount]:
          appraisal.engineCylinderCount ?? '',
        [PeVehicleForm.Keys.EngineCylinderLabel]:
          appraisal.engineCylinderLabel ?? '',
        [PeVehicleForm.Keys.BodyType]: appraisal.bodyType ?? '',
        [PeVehicleForm.Keys.TransmissionType]: appraisal.transmissionType ?? '',
        [PeVehicleForm.Keys.Traction]: appraisal.traction ?? '',
        [PeVehicleForm.Keys.ExteriorColor]: appraisal.exteriorColor ?? '',
        [PeVehicleForm.Keys.InteriorColor]: appraisal.interiorColor ?? '',
      });
    } else {
      reset(PeVehicleForm.defaultValues);
    }
  }, [appraisal]);
};

export default useResetVehicle;
