import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  icon: {
    width: 70,
    height: 50,
    backgroundSize: `70px auto`,
    backgroundRepeat: `no-repeat`,
    background: `url("assets/images/brand-logo.png")`,
  },
  brand: {
    padding: '20px 18px 20px 24px',
  },
});

const Brand: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx('flex items-center justify-between', classes.brand)}>
      <div className="flex items-center">
        <div className={classes.icon}></div>
        <span className={'text-18 ml-2 font-medium'}>
          {t('common.brand-title')}
        </span>
      </div>
    </div>
  );
};

export default Brand;
