import { useContext, useEffect } from 'react';
import SocketContext from 'contexts/SocketContext';

const useSocket = () => {
  try {
    const context = useContext(SocketContext);

    if (!context) throw new Error('SocketContext not provided');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const on = (name: string, fn: any) => {
      useEffect(() => {
        context.bus.on(name, fn);

        return () => {
          context.bus.off(name, fn);
        };
      }, [context]);
    };

    return { ...context, on };
  } catch (error) {
    console.log('Error in useSettings', error);
    throw error;
  }
};

export default useSocket;
