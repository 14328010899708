import React from 'react';
import { RoutesConfig } from 'router/routes.config';
import { Permissions } from 'constants/entities/user';

const appraisalPriceRoutes = [
  {
    exact: true,
    path: RoutesConfig.AppraisalPrice.Statistic,
    permission: Permissions.Dashboard.GetGlobalStatistic,
    component: React.lazy(
      () => import('modules/analytics/appraisal-price/DashboardStatistics')
    ),
  },
  {
    path: RoutesConfig.AppraisalPrice.List,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/appraisal-price/List')
    ),
  },
  {
    path: RoutesConfig.AppraisalPrice.ViewOne,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/appraisal-price/View')
    ),
  },
];

export default appraisalPriceRoutes;
