import { AppraisalInput } from 'graphql-types';
import useStore from 'store/pe-edit-appraisal.store';
import usePeEditStepper from 'store/pre-edit-stepper.store';
import { EditSteps } from '../constants/types';

const usePipeQuestionSubmit = () => {
  const { activeStep } = usePeEditStepper();
  const { appraisal } = useStore();

  // TODO: Temporary removed the Manheim API
  // const [getManheimDetails] = useManheimDataByVinLazyQuery();

  const handlePipeQuestions = async (
    data: Partial<AppraisalInput>
  ): Promise<Partial<AppraisalInput>> => {
    // Add Manheim only on Questions step
    if (!appraisal || activeStep !== EditSteps.Questions) return data;

    try {
      // TODO: Temporary removed the Manheim API
      // const manheim = await getManheimDetails({
      //   variables: {
      //     vin: String(appraisal.vin),
      //     odometer: Number(appraisal.odometerMiles),
      //     grade: String(countGrateQuestion(data.disclosuresUS ?? [])),
      //   },
      // });

      const manheim = { data: {} } as any;

      const payload: any = {
        count: manheim.data?.manheimDataByVin?.result.count,
        href: manheim.data?.manheimDataByVin?.result.href,
        items: manheim.data?.manheimDataByVin?.result.items,
      };

      return { ...data, manheimData: payload };
    } catch (error) {
      return data;
    }
  };

  return { handlePipeQuestions };
};

export default usePipeQuestionSubmit;
