import React from 'react';
import { FieldError } from 'react-hook-form';

import {
  Box,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';

import Error from '../error';

interface TextFieldProps extends Omit<MUITextFieldProps, 'error'> {
  errors?: FieldError;
}

// eslint-disable-next-line react/display-name
const TextField: React.ForwardRefExoticComponent<TextFieldProps> =
  React.forwardRef(({ errors = null, ...rest }, ref) => (
    <Box display="grid">
      <MUITextField error={!!errors} inputRef={ref} {...rest} />
      <Error errors={errors} />
    </Box>
  ));

export default TextField;
