import React, { FC } from 'react';
import useResetEquipment from './hooks/useResetEquipment';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import useEquipment from './hooks/useEquipment';
import EquipmentCheckBox from './component/EquipmentCheckBox';
import { useTranslation } from 'react-i18next';

const EquipmentForm: FC = () => {
  useResetEquipment();

  const { t } = useTranslation();
  const { equipmentList, loading } = useEquipment();

  return (
    <Stack gap={3} alignItems="center" width="100%">
      {loading && (
        <Box width="100%" maxWidth={700}>
          <LinearProgress sx={{ mb: 2 }} />
        </Box>
      )}

      {!!equipmentList.length && (
        <Stack gap={2}>
          {equipmentList.map((item) => (
            <EquipmentCheckBox key={item.name} item={item} />
          ))}
        </Stack>
      )}

      {!loading && !equipmentList.length && (
        <Typography variant="h4" color="grey.700">
          {t('common.no-data')}
        </Typography>
      )}
    </Stack>
  );
};

export default EquipmentForm;
