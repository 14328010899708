import React from 'react';
import { Button, CircularProgress, ButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {},
  pending: {},
  pendingIndicator: {
    position: 'absolute',
    visibility: 'visible',
    display: 'flex',
  },
  pendingIndicatorCenter: {
    left: '50%',
    transform: 'translate(-50%)',
  },
  pendingIndicatorStart: {
    left: 14,
  },
  pendingIndicatorEnd: {
    right: 14,
  },
  endIconPendingEnd: {
    visibility: 'hidden',
  },
  startIconPendingStart: {
    visibility: 'hidden',
  },
  labelPendingCenter: {
    visibility: 'hidden',
  },
});

const PendingIndicator = <CircularProgress color="inherit" size={16} />;

type LoadingButtonProps = ButtonProps & {
  pending?: boolean;
  pendingIndicator?: React.ReactElement;
};

const LoadingButton: React.FC<LoadingButtonProps> = React.forwardRef(
  function LoadingButton(props, ref) {
    const {
      children,
      className,
      disabled = false,
      pending = false,
      pendingIndicator = PendingIndicator,
      ...other
    } = props;

    const classes = useStyles();

    return (
      <Button
        className={clsx(
          classes.root,
          {
            [classes.pending]: pending,
          },
          className
        )}
        disabled={disabled || pending}
        ref={ref}
        classes={{
          endIcon: pending ? classes.endIconPendingEnd : '',
        }}
        {...other}
      >
        {pending && (
          <div
            className={clsx(
              classes.pendingIndicator,
              classes.pendingIndicatorEnd
            )}
          >
            {pendingIndicator}
          </div>
        )}

        {children}
      </Button>
    );
  }
);

export default LoadingButton;
