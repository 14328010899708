import { ParamsRegex } from 'constants/validation/regexps';

/*
 * @description: This class contains methods to help with navigation
 */
class Navigation {
  /*
   * @description: Replace the parameters in the path with the values from the params object
   * @param {string} path
   * @param {Record<string, string | number>} params
   * @return {string}
   * @example
   * Navigation.replaceParams('/client/:id/edit/', { id: 1 });
   * // returns '/client/1/edit/'
   */
  static replaceParams(
    path: string,
    params: Record<string, string | number | undefined | null>
  ) {
    return path.replace(ParamsRegex, (_, key) => {
      return params[key]?.toString() || '';
    });
  }
}

export default Navigation;
