import React, { FC } from 'react';
import useDrivlyCheck from './hooks/useDrivlyCheck';
import useResetQuestions from './hooks/useResetQuestions';
import { Grid } from '@mui/material';
import SimpleRadio from './components/SimpleRadio';
import TwoQuestions from './components/TwoQuestions';
import SimpleSelect from './components/SimpleSelect';
import useQuestions from './hooks/useQuestions';

const QuestionForm: FC = () => {
  useResetQuestions();
  useDrivlyCheck();

  const { errors, updateHandler, findQuestion } = useQuestions();

  return (
    <Grid container alignItems="center" spacing={1}>
      <SimpleRadio
        item={findQuestion['question-one']}
        keyQuestion="question-one"
        options={['option1', 'option2', 'option3', 'option4', 'option5']}
        updateOption={updateHandler}
        isError={errors.includes(`question-one`)}
      />

      <TwoQuestions
        item={findQuestion['question-two']}
        keyQuestion="question-two"
        options={['no', 'yes']}
        updateOption={updateHandler}
        isError={errors.includes(`question-two`)}
      />

      <SimpleSelect
        item={findQuestion['question-three']}
        keyQuestion="question-three"
        checkboxes={[
          'option1',
          'option2',
          'option3',
          'option4',
          'option5',
          'option6',
          'option7',
          'option8',
        ]}
        updateOption={updateHandler}
        isError={errors.includes(`question-three`)}
      />

      <TwoQuestions
        item={findQuestion['question-four']}
        keyQuestion="question-four"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2', 'option3']}
        updateOption={updateHandler}
        isError={errors.includes(`question-four`)}
      />

      <TwoQuestions
        item={findQuestion['question-five']}
        keyQuestion="question-five"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2', 'option3', 'option4', 'option5']}
        updateOption={updateHandler}
        isError={errors.includes(`question-five`)}
      />

      <TwoQuestions
        select
        item={findQuestion['question-six']}
        keyQuestion="question-six"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2', 'option3']}
        updateOption={updateHandler}
        isError={errors.includes(`question-six`)}
      />

      <TwoQuestions
        item={findQuestion['question-seven']}
        keyQuestion="question-seven"
        options={['no', 'yes']}
        checkboxes={[
          'option1',
          'option2',
          'option3',
          'option4',
          'option5',
          'option6',
        ]}
        updateOption={updateHandler}
        isError={errors.includes(`question-seven`)}
      />

      <TwoQuestions
        item={findQuestion['question-eight']}
        keyQuestion="question-eight"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2']}
        updateOption={updateHandler}
        isError={errors.includes(`question-eight`)}
      />

      <TwoQuestions
        select
        item={findQuestion['question-nine']}
        keyQuestion="question-nine"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2', 'option3']}
        updateOption={updateHandler}
        isError={errors.includes(`question-nine`)}
      />

      <TwoQuestions
        item={findQuestion['question-ten']}
        keyQuestion="question-ten"
        options={['no', 'yes']}
        checkboxes={[]}
        updateOption={updateHandler}
        isError={errors.includes(`question-ten`)}
      />

      <TwoQuestions
        item={findQuestion['question-eleven']}
        keyQuestion="question-eleven"
        options={['no', 'yes']}
        checkboxes={[]}
        updateOption={updateHandler}
        isError={errors.includes(`question-eleven`)}
      />

      <TwoQuestions
        select
        item={findQuestion['question-fourteen']}
        keyQuestion="question-fourteen"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2', 'option3']}
        updateOption={updateHandler}
        isError={errors.includes(`question-fourteen`)}
      />

      <TwoQuestions
        select
        item={findQuestion['question-thirteen']}
        keyQuestion="question-thirteen"
        options={['no', 'yes']}
        checkboxes={['option1', 'option2', 'option3']}
        updateOption={updateHandler}
        isError={errors.includes(`question-thirteen`)}
      />
    </Grid>
  );
};

export default QuestionForm;
