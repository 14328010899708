import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: theme.spacing(0),
    right: theme.spacing(0),
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    zIndex: 10000,
    transform: 'translate3d(0,0,0)',
    display: 'flex',
    background: 'white',
  },
}));

export { useStyles };
