import {
  AppraisalInput,
  Countries,
  useBbInfoValuesMutation,
  useGetCarGurusByVinLazyQuery,
  useGetCarstoryByVinMutation,
  useGetOffersByVinLazyQuery,
} from 'graphql-types';
import { isNil } from 'lodash';
import { useUpdatePhysicalEvaluation } from 'hooks/useUpdatePhysicalEvaluation';
import { useState } from 'react';
import { CA_LOCATION, US_LOCATION } from '../constants/entities/location';

type ReatailPricesType = {
  adjustedRetailAvg: number;
  adjustedRetailClean: number;
  adjustedRetailRough: number;
  adjustedRetailXclean: number;
  baseRetailAvg: number;
  baseRetailClean: number;
  baseRetailRough: number;
  baseRetailXclean: number;
};

type WholeSalePrices = {
  adjustedWholeAvg: number;
  adjustedWholeClean: number;
  adjustedWholeRough: number;
  adjustedWholeXclean: number;
  baseWholeAvg: number;
  baseWholeClean: number;
  baseWholeRough: number;
  baseWholeXclean: number;
};

type TradeinPrices = {
  adjustedTradeinAvg: number;
  adjustedTradeinClean: number;
  adjustedTradeinRough: number;
  adjustedTradeinXclean: number;
  baseTradeinAvg: number;
  baseTradeinClean: number;
  baseTradeinRough: number;
  baseTradeinXclean: number;
};

export type BbInfoValuesType = {
  reatailPrices: ReatailPricesType;
  wholeSalePrices: WholeSalePrices;
  tradeinPrices: TradeinPrices;
};

type PhysicalCardContextType = {
  isLoading: boolean;
  isLoadingGetOffersByVin: boolean;
  isLoadingGetCarGurusByVIN: boolean;
  isLoadingGetCarstoryByVIN: boolean;
  drivlyGeneralValues: DrivlyGeneralType | null;
  setDrivlyGeneralValues: (value: DrivlyGeneralType) => void;
  bbInfoValues: BbInfoValuesType | null;
  setBBInfoValues: (value: BbInfoValuesType) => void;
  carGurusByValues: CarGurusByVINType | null;
  setCarGurusByValues: (value: CarGurusByVINType) => void;
  carstoryByVINValues: GetCarstoryByVIN | null;
  onBBInfoHandler: (input: BBInfoInputType) => void;
  checkCountryCar: (locationCar: string) => string;
  getOffersByVIN: (vin: string, mileage: number, zipCode: string) => any;
  onGetCarGurusByVINHandler: (vin: string, mileage?: string) => any;
  onGetCarstoryByVINHandler: (vin: string) => any;
};

export type DrivlyGeneralType = {
  carmaxDeclineReason: string;
  carmaxError: any;
  carmaxPrice: number;
  carmaxUrl: string;
  carvanaError: any;
  carvanaPrice: number;
  carvanaUrl: string;
  make: string;
  mileage: number;
  model: string;
  retry: any;
  vin: string;
  vroomError: any;
  vroomGrade: string;
  vroomPrice: number;
  vroomUrl: string;
  year: number;
};

export type BBInfoInputType = {
  vin: string;
  lang: string;
  country: string;
  styleId: string;
  series: string;
  mileage: string;
};

export type CarGurusByVINType = {
  vin: string;
  retail: string;
  privateParty: string;
  trade: string;
  error: string;
};

export type GetCarstoryByVIN = {
  error?: string;
  bedStyle: string;
  bodyStyle: string;
  cabStyle: string;
  condition: string;
  conditionTimestamp: number;
  confidenceScore4: string;
  doors: number;
  driveType: string;
  engine: string;
  engineDescription: string;
  engineDisplacement: string;
  exteriorColor: string;

  fuel: string;
  generation: number;
  interiorColor: string;
  make: string;
  mileage: number;
  mileageTimestamp: number;
  model: string;
  mpg: number;
  mpgCity: number;
  mpgHighway: number;
  subGeneration: number;
  subModel: string;
  subTrim: string;
  transmission: string;
  transmissionDetails: string;
  trim: string;
  vin: string;
  wheelbase: string;
  year: number;
};

const useDrivly = (id: string): PhysicalCardContextType => {
  const {
    updateFn: updateAppraisalFn,
    // loading: upLoading,
    // error,
  } = useUpdatePhysicalEvaluation();

  const [bbInfoValues, setBbInfoValues] = useState<BbInfoValuesType | null>(
    null
  );

  const hendlerBBInfoValues = (value: BbInfoValuesType) => {
    setBbInfoValues(value);
  };

  const [drivlyGeneralValues, setDrivlyGeneralValues] =
    useState<DrivlyGeneralType | null>(null);

  const hendlertDrivlyGeneralValues = (value: DrivlyGeneralType) => {
    setDrivlyGeneralValues(value);
  };

  const [carGurusByValues, setCarGurusByValues] =
    useState<CarGurusByVINType | null>(null);

  const hendlerCarGurusByValues = (value: CarGurusByVINType) => {
    setCarGurusByValues(value);
  };

  const [carstoryByVINValues, setCarstoryByVINValues] =
    useState<GetCarstoryByVIN | null>(null);

  const handleSave = async (data: Partial<AppraisalInput>) => {
    if (id && !isNil(data)) {
      await updateAppraisalFn({
        variables: {
          id: id,
          input: {
            change: {
              blackbookData: isNil(bbInfoValues) ? null : bbInfoValues,
              drivlyDataOffers: isNil(drivlyGeneralValues)
                ? null
                : drivlyGeneralValues,
              drivlyDataGurus: isNil(carGurusByValues)
                ? null
                : carGurusByValues,
              ...data,
            },
            socket: false,
          },
        },
      });
    }
  };

  const [getBBInfo, { loading }] = useBbInfoValuesMutation({
    onCompleted: (e) => {
      const result = e?.bbInfoValues?.result ? e.bbInfoValues.result : null;
      if (result) {
        const { reatailPrices, wholeSalePrices, tradeinPrices } = result;
        setBbInfoValues({
          reatailPrices,
          wholeSalePrices,
          tradeinPrices,
        } as BbInfoValuesType);

        handleSave({
          blackbookData: {
            reatailPrices,
            wholeSalePrices,
            tradeinPrices,
          },
        });
      }
    },
  });

  const [getOffersByVinQuery, { loading: loadingGetOffersByVin }] =
    useGetOffersByVinLazyQuery({
      onCompleted: (e) => {
        const result = e?.getOffersByVIN ? e.getOffersByVIN : null;

        if (result) {
          setDrivlyGeneralValues(result as DrivlyGeneralType);
          handleSave({ drivlyDataOffers: result });
        }
      },
    });

  const [getCarGurusByVIN, { loading: loadingGetCarGurusByVIN }] =
    useGetCarGurusByVinLazyQuery({
      onCompleted: (e: any) => {
        const result = e.getCarGurusByVIN ? e.getCarGurusByVIN : null;

        if (result) {
          setCarGurusByValues(result as CarGurusByVINType);
          handleSave({ drivlyDataGurus: result });
        }
      },
    });

  const [getCarstoryByVIN, { loading: loadingGetCarstoryByVIN }] =
    useGetCarstoryByVinMutation({
      onCompleted: (e) => {
        const result = e.getCarstoryByVIN?.result?.carstory?.vehicleInfo
          ? e.getCarstoryByVIN.result.carstory.vehicleInfo
          : null;
        if (result) {
          setCarstoryByVINValues(result as GetCarstoryByVIN);
        }
      },
    });

  const onBBInfoHandler = (input: BBInfoInputType) => {
    getBBInfo({
      variables: {
        input,
      },
    });
  };

  const checkCountryCar = (locationCar: string): string => {
    if (CA_LOCATION.includes(locationCar)) {
      return Countries.CA;
    }

    if (US_LOCATION.includes(locationCar)) {
      return Countries.US;
    }

    return Countries.CA;
  };

  const getOffersByVIN = async (
    vin: string,
    mileage: number,
    zipCode: string
  ) => {
    await getOffersByVinQuery({
      variables: {
        vin,
        mileage: mileage?.toString(),
        zipCode,
      },
    });
  };

  const onGetCarGurusByVINHandler = async (vin: string, mileage?: string) => {
    await getCarGurusByVIN({
      variables: { vin, mileage },
    });
  };

  const onGetCarstoryByVINHandler = async (vin: string) => {
    getCarstoryByVIN({
      variables: {
        input: {
          url: `https://offers.dev.driv.ly/api/vin/${vin}/carstory`,
        },
      },
    });
  };

  return {
    isLoading: loading,
    isLoadingGetOffersByVin: loadingGetOffersByVin,
    isLoadingGetCarGurusByVIN: loadingGetCarGurusByVIN,
    isLoadingGetCarstoryByVIN: loadingGetCarstoryByVIN,
    bbInfoValues,
    setBBInfoValues: hendlerBBInfoValues,
    drivlyGeneralValues,
    setDrivlyGeneralValues: hendlertDrivlyGeneralValues,
    carGurusByValues,
    setCarGurusByValues: hendlerCarGurusByValues,
    carstoryByVINValues,
    onBBInfoHandler,
    onGetCarGurusByVINHandler,
    onGetCarstoryByVINHandler,
    checkCountryCar,
    getOffersByVIN,
  };
};

export default useDrivly;
