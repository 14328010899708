import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisclosuresUsInput } from 'graphql-types';

type SimpleRadio = {
  item?: DisclosuresUsInput | null;
  keyQuestion: string;
  options: string[];
  isDisabled?: boolean;
  updateOption?: (
    question: string,
    value: string,
    text?: string,
    children?: string[]
  ) => void;
  isError?: boolean;
  clearError?: (key: string) => void;
};

const SimpleRadio: React.FC<SimpleRadio> = ({
  item,
  keyQuestion,
  updateOption,
  options,
  isDisabled = false,
  isError = false,
  clearError,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (clearError) {
      clearError(keyQuestion);
    }
    if (updateOption) {
      updateOption(keyQuestion, (event.target as HTMLInputElement).value);
    }
  };
  return (
    <Grid
      container
      item
      style={{
        backgroundColor: isError ? '#fff4f4' : '',
      }}
    >
      <Grid item xs={12}>
        <Box color={isError ? 'rgb(0 0 0 / 87%)' : '#545B63'} fontSize="16px">
          {t(`questionUs.${keyQuestion}.title`)}
        </Box>
      </Grid>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="question"
          name="question"
          value={item?.value ?? ''}
          onChange={handleChange}
        >
          <Grid alignItems="center" container item>
            {options.map((option) => (
              <Grid item key={option} xs={12}>
                <FormControlLabel
                  value={option}
                  control={
                    <Radio
                      disabled={isDisabled}
                      inputProps={
                        {
                          ['data-cy']: `${keyQuestion}-option-${option}`,
                        } as any
                      }
                    />
                  }
                  label={t(`questionUs.${keyQuestion}.${option}`)}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default SimpleRadio;
