import { useEffect } from 'react';
import useStore from 'store/pe-edit-appraisal.store';
import { useCreateEmptyMonkaiInspectionMutation } from 'graphql-types';
import { useParams } from 'react-router';

const useInitMonkaiInspection = (refetchAppraisal: () => void) => {
  const { appraisal } = useStore();
  const { id } = useParams<{ id: string }>();

  const [createEmptyMonkaiInspection] = useCreateEmptyMonkaiInspectionMutation({
    onCompleted: () => {
      refetchAppraisal();
    },
  });

  useEffect(() => {
    if (appraisal && !appraisal.monkaiInspectionId && id) {
      createEmptyMonkaiInspection({
        variables: {
          input: {
            vehId: +id,
          },
        },
      });
    }
  }, [appraisal]);
};

export default useInitMonkaiInspection;
