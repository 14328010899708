import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TouchRipple from '@mui/material/ButtonBase';

import { useSidebar } from 'store';

const useStyles = makeStyles(({ palette }) => ({
  navItem: {
    transition: 'all 150ms ease-in',
    '&:hover': {
      backgroundColor: palette.action.hover,
    },
  },
  navItemActive: {
    backgroundColor: palette.action.selected,
  },
  compactNavItem: {
    overflow: 'hidden',
    justifyContent: 'center !important',
    '& $itemText': {
      display: 'none',
    },
    '& $itemIcon': {
      display: 'none',
    },
  },
  itemIcon: {},
  itemText: {
    fontWeight: 600,
    fontSize: '.9rem',
    paddingLeft: '0.8rem',
  },
  label: {
    color: palette.primary.contrastText,
  },
  bulletIcon: {
    background: palette.primary.contrastText,
  },
}));

type VerticalNavProps = {
  items: NavigationItem[];
};

const VerticalNav: React.FC<VerticalNavProps> = ({ items }) => {
  const { t } = useTranslation();
  const { sidebarMode } = useSidebar();
  const classes = useStyles();

  const renderLevels = (data: NavigationItem[]) =>
    data.map((item, index) => {
      if (item.type === `label`)
        return (
          <p
            data-cy-menu={item.key}
            key={index}
            className={clsx({
              'px-4 mb-2 mt-6 uppercase text-12 sidenavHoverShow': true,
              [classes.label]: true,
              hidden: sidebarMode === 'compact',
            })}
          >
            {t(`navigations.${item.key}`)}
          </p>
        );
      if (item.type === 'link') {
        return (
          <NavLink
            data-cy-menu={item.key}
            key={index}
            to={item.path}
            // activeClassName={classes.navItemActive} ???
            className={clsx({
              'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden':
                true,
              [classes.navItem]: true,
              [classes.compactNavItem]: sidebarMode === 'compact',
            })}
          >
            <TouchRipple key={item.key} name="child" className="w-full">
              <Icon className="text-20 align-middle w-36 px-4">
                {item.icon}
              </Icon>
              <span
                className={clsx(
                  'align-middle text-left sidenavHoverShow',
                  classes.itemText
                )}
              >
                {t(`navigations.${item.key}`)}
              </span>
              {item.count && item.count({})}
              <div className="mx-auto"></div>
            </TouchRipple>
          </NavLink>
        );
      }
    });

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(VerticalNav);
