import React, { memo, useMemo } from 'react';
import clsx from 'clsx';

import { Button, ButtonProps, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowRightAlt } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: theme.spacing(6),
    width: theme.spacing(20),
    height: theme.spacing(6),
  },
  icon: {
    color: 'inherit',
    fontSize: `${theme.spacing(5)}!important`,
  },
}));

type StepButtonProps = ButtonProps & {
  loading?: boolean;
  side?: 'none' | 'next' | 'back';
  disabled?: boolean;
};

const StepButton: React.FC<StepButtonProps> = ({
  loading,
  className,
  side,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  const sideIcon = useMemo(() => {
    switch (side) {
      case 'next':
        return <ArrowRightAlt className={classes.icon} />;
      case 'back':
        return (
          <ArrowRightAlt
            className={classes.icon}
            style={{ transform: `rotate(-180deg)` }}
          />
        );
      default:
        <></>;
    }
  }, [side]);

  const isDisabled = typeof disabled == 'undefined' ? false : disabled;

  return (
    <Button
      className={clsx(classes.btn, className)}
      endIcon={
        loading ? <CircularProgress className={classes.icon} /> : sideIcon
      }
      disabled={isDisabled || loading}
      {...rest}
    />
  );
};

export default memo(StepButton);
