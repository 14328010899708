import React, { FC, useMemo } from 'react';
import { FormLabel, Stack } from '@mui/material';
import { VehicleDetailKeys } from '../../../../physical-eval-edit/constants/values';
import { useFormContext } from 'react-hook-form';
import { PeVehicleForm } from 'constants/forms';
import OptionButton from 'ui/inputs/radio-lists/OptionButton';
import { useTranslation } from 'react-i18next';

const VehicleDetails: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<PeVehicleForm.FormType>();

  const hasValues = useMemo(() => {
    return VehicleDetailKeys.some(({ formKey }) => !!watch(formKey));
  }, [...VehicleDetailKeys.map(({ formKey }) => watch(formKey))]);

  if (!hasValues) return null;

  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      {VehicleDetailKeys.map(({ formKey, labelKey, flexBasis = 140 }) => {
        const value = watch(formKey);

        return (
          <Stack key={`${formKey}-${labelKey}`} flexBasis={flexBasis} gap={1}>
            <FormLabel>{t(`appraisal.vehicle.${labelKey}.label`)}</FormLabel>

            <OptionButton isActive={!!value}>
              {value || t('common.no-data-simple')}
            </OptionButton>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default VehicleDetails;
