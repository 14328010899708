import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { RequiredQuestions } from 'modules/physical-eval-edit/constants/values';

export enum Keys {
  QuestionList = 'disclosuresUS',
}

export const Schema = z.object({
  [Keys.QuestionList]: z
    .array(
      z.object({
        name: z.string(),
        value: z.string(),
        text: z.string().nullable(),
        children: z.array(z.string()),
      })
    )
    .refine((values) => {
      const names = values.map((it) => it.name);
      return RequiredQuestions.every((it) => names.includes(it));
    }, 'common.validation.required'),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.QuestionList]: [],
};

export const useQuestionsForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
