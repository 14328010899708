import useAnalytics from 'hooks/useAnalytics';
import isPermissionCamera from 'utils/permission-camera';
import { useEffect } from 'react';

const useGetCamera = () => {
  const { eventUserCamera } = useAnalytics();
  const { isPermission, loading: loadingCamera } = isPermissionCamera();

  useEffect(() => {
    if (!loadingCamera && isPermission !== null) {
      eventUserCamera(isPermission);
    }
  }, [isPermission, loadingCamera]);
};

export default useGetCamera;
