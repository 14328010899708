import { useContext } from 'react';
import GlobalContext from 'contexts/GlobalContext';

const useGlobal = () => {
  try {
    const context = useContext(GlobalContext);

    if (!context) throw new Error('GlobalContext not provided');

    return context;
  } catch (error) {
    console.log('Error in useGlobal', error);
    throw error;
  }
};

export default useGlobal;
