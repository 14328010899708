/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, PropsWithChildren, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import io from 'socket.io-client';
import EventEmitter from 'events';
import { useSnackbar } from 'notistack';

import { SOCKET_PATH, SOCKET_URL } from 'constants/config/app-variables';

import {
  ListingDocument,
  ListingQuery,
  MessaagesDocument,
  MessaagesQuery,
  NotificationCountDocument,
  NotificationCountQuery,
  NotificationListQuery,
  NotificationListDocument,
  AppraisalDocument,
} from 'graphql-types';
import { useUserChecks } from '../store';

type SocketContextType = {
  bus: EventEmitter;
};

const SocketContext = createContext<SocketContextType | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const socket = io.connect(SOCKET_URL, {
  path: SOCKET_PATH,
  autoConnect: true,
  reconnection: false,
  transports: ['websocket'],
  secure: true,
});

export const SocketProvider = (props: PropsWithChildren) => {
  const { isSocketUsing } = useUserChecks();
  const [bus] = React.useState(new EventEmitter());

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const isNewNotificationSystemEnabled = () => false;

  useEffect(() => {
    const sendMesaageListener = (args: any) => {
      console.log('args', args);

      socket.emit('send', { message: JSON.stringify(args) });
    };

    const reciveMesaageListener = (event: any) => {
      const message = JSON.parse(event.message);

      if (message && message.type === 'message') {
        const data = client.readQuery<MessaagesQuery>({
          query: MessaagesDocument,
          variables: {
            channel: message.channel,
          },
        });

        client.writeQuery<MessaagesQuery>({
          query: MessaagesDocument,
          variables: {
            channel: message.channel,
          },
          data: {
            messaages: {
              result: [
                ...(data?.messaages.result || []).filter(
                  (it) => it._id !== message._id
                ),
                message,
              ],
            },
          },
        });

        if (isNewNotificationSystemEnabled()) {
          enqueueSnackbar(`Message ${message.text}`, {
            preventDuplicate: true,
            key: message._id,
            variant: 'info',
            autoHideDuration: 5000,
          });
        }
        bus.emit('recive-message', message);
      }
    };

    const gloableMessageListener = (event: any) => {
      const message = JSON.parse(event.message);

      // if (!message.data || !user?.segmentations.includes(message.data.location))
      //   return;

      if (
        message.type === 'system' &&
        message.channel.match(/listing\/.*\/.*/)
      ) {
        client.writeQuery<ListingQuery>({
          query: ListingDocument,
          variables: {
            id: +message.data.id,
          },
          data: {
            listing: {
              result: message.data,
            },
          },
        });

        bus.emit('listing', message.data);
      }

      if (
        message.type === 'system' &&
        message.channel.match(/appraisal\/.*\/.*/)
      ) {
        const activeQueries = client.getObservableQueries();
        const isAppraisalQueryActive = Object.values(activeQueries).some(
          (observableQuery) =>
            observableQuery.options.query === AppraisalDocument
        );

        if (isAppraisalQueryActive) {
          client.query({
            query: AppraisalDocument,
            variables: {
              id: +message.data.id,
            },
            fetchPolicy: 'network-only',
          });
        }

        bus.emit('appraisal', message.data);
      }

      if (isNewNotificationSystemEnabled()) {
        if (message.data.status === 'new') {
          enqueueSnackbar(message.text, {
            preventDuplicate: true,
            key: message.data.id,
            variant: 'info',
            autoHideDuration: 5000,
          });
        }

        if (message.data.status === 'evaluation') {
          enqueueSnackbar(message.text, {
            preventDuplicate: true,
            key: message.data.id,
            variant: 'success',
            autoHideDuration: 5000,
          });
        }

        if (message.data.status === 'cancelled') {
          enqueueSnackbar(message.text, {
            preventDuplicate: true,
            key: message.data.id,
            variant: 'error',
            autoHideDuration: 5000,
          });
        }

        enqueueSnackbar(message.text, {
          preventDuplicate: true,
          key: message.data.id,
          variant: 'info',
          autoHideDuration: 5000,
        });
      }
    };

    const noticesListener = (event: any) => {
      const message = JSON.parse(event.message);

      // if (!message.data || !user?.segmentations.includes(message.data.location))
      //   return;

      if (
        message.type === 'system' &&
        message.channel.match(/notices\/.*\/.*/)
      ) {
        client.writeQuery<NotificationListQuery>({
          query: NotificationListDocument,
          data: {
            notificationList: {
              result: message.data,
            },
          },
        });

        bus.emit('notices', message.data);
      }
    };

    const noticesCountListener = (event: any) => {
      const message = JSON.parse(event.message);

      // if (!message.data || !user?.segmentations.includes(message.data.location))
      //   return;

      if (
        message.type === 'system' &&
        message.channel.match(/noticesCount\/.*\/.*/)
      ) {
        client.writeQuery<NotificationCountQuery>({
          query: NotificationCountDocument,
          data: {
            notificationCount: {
              result: message.data,
            },
          },
        });

        bus.emit('noticesCount', message.data);
      }
    };

    // socket.connect();

    bus.on('send-message', sendMesaageListener);

    socket.on('GLOBAL', (e: any) => {
      if (isSocketUsing()) {
        gloableMessageListener(e);
      }
    });

    socket.on('GLOBAL', reciveMesaageListener);

    socket.on('GLOBAL', noticesCountListener);

    socket.on('GLOBAL', noticesListener);

    socket.on('connect_error', (error: any) => {
      console.log('socket connect_error', error);
    });

    socket.on('disconnect', (error: any) => {
      console.log('socket disconnect', error);
    });

    socket.on('reconnect', (attemptNumber: any) => {
      // Обробка перепідключення
      console.log(`Socket reconnected on attempt ${attemptNumber}`);
    });

    return () => {
      bus.off('send-message', sendMesaageListener);
      bus.off('GLOBAL', reciveMesaageListener);
      bus.off('GLOBAL', gloableMessageListener);
      bus.off('GLOBAL', noticesListener);
      bus.off('GLOBAL', noticesCountListener);
      console.log('EXIS');

      socket.close();
    };
  }, [isSocketUsing()]);

  return (
    <SocketContext.Provider value={{ bus }}>
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
