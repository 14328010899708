import React from 'react';
import { RoutesConfig } from 'router/routes.config';
import { Permissions } from 'constants/entities/user';

const salesManagerPriceRoutes = [
  {
    exact: true,
    path: RoutesConfig.SalesManagerPrice.Dashboard,
    permission: Permissions.Dashboard.GetGlobalStatistic,
    component: React.lazy(
      () => import('modules/analytics/sales-sanager-price/DashboardStatistics')
    ),
  },
  {
    exact: true,
    path: RoutesConfig.SalesManagerPrice.MyStatistic,
    permission: Permissions.Dashboard.GetOwnStatistic,
    component: React.lazy(
      () => import('modules/analytics/sales-sanager-price/DashboardStatistics')
    ),
  },
  {
    path: RoutesConfig.SalesManagerPrice.List,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/sales-sanager-price/List')
    ),
  },
  {
    path: RoutesConfig.SalesManagerPrice.ViewOne,
    exact: true,
    component: React.lazy(
      () => import('modules/analytics/sales-sanager-price/View')
    ),
  },
];

export default salesManagerPriceRoutes;
