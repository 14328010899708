import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type OptionButtonProps = {
  isActive: boolean;
};

const OptionButton = styled(Box)<OptionButtonProps>(({ theme, isActive }) => ({
  margin: 0,
  padding: '5px 15px',
  minWidth: '64px',
  borderRadius: 4,

  border: '1px solid rgba(24, 168, 87, 0.5)',

  color: isActive ? theme.palette.white : theme.palette.green[900],
  background: isActive ? theme.palette.green[900] : 'transparent',
  boxShadow: isActive
    ? '0px 3px 1px -2px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.042), 0px 1px 5px 0px rgba(0, 0, 0, 0.036)'
    : 'none',

  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
}));

export default OptionButton;
