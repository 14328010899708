import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  itemNotification: {
    backgroundColor: '#efefef96',
  },
  itemNotificationActive: {
    backgroundColor: '#eee',
  },
  itemCountNotification: {
    backgroundColor: theme.palette.primary.main,
    padding: '4px',
    marginRight: '4px',
    borderRadius: '8px',
    height: '10px',
    width: '10px',
  },
  textColorPrimary: {
    color: theme.palette.primary.main,
  },
  textColorNotActive: {
    color: '#5c5c5c',
  },
}));
