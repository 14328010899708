import {
  AppraisalInput,
  useUpdatePhysicalEvaluationMutation,
} from 'graphql-types';
import { isDirty } from 'utils';
import useStore from 'store/pe-edit-appraisal.store';
import { EditSteps } from '../constants/types';
import usePeEditStepper from 'store/pre-edit-stepper.store';
import { useFeedback } from 'hooks/useFeedback';
import { useTranslation } from 'react-i18next';

const useSaveEval = () => {
  const { shwoError } = useFeedback();
  const { t } = useTranslation();
  const { appraisal, isSkipEquipment, ...store } = useStore();
  const { activeStep, goToStep, nextStep } = usePeEditStepper();

  const [updateAppraisalFn, updateState] =
    useUpdatePhysicalEvaluationMutation();

  const handleNextSlide = () => {
    if (activeStep === EditSteps.Vehicle && isSkipEquipment) {
      return goToStep(EditSteps.Customer);
    }

    nextStep();
  };

  const handleSaveEval = async (data: Partial<AppraisalInput>) => {
    try {
      if (appraisal && appraisal._id && isDirty(data, appraisal)) {
        await updateAppraisalFn({
          variables: {
            id: appraisal._id,
            input: {
              change: { ...data },
              socket: activeStep === EditSteps.Customer,
            },
          },
        });

        store.updateAppraisal(data);
      }

      handleNextSlide();
    } catch (error) {
      shwoError(
        t('failed-save-eval', {
          defaultValue: 'Failed to save evaluation. Try again.',
        })
      );
    }
  };

  return { handleSaveEval, ...updateState } as const;
};

export default useSaveEval;
