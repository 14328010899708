import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

type Features = {
  isFeatureAdminEnabled: boolean;
  isConditionManagementEnabled: boolean;
  isFeatureVehicleReport: boolean;
  isFeatureAppraisalConditionsFeature: boolean;
  isFeatureAnalyticEnabled: boolean;
  isQuaggaBarcode: boolean;
  isNewNotificationSystem: boolean;
  isExtraPriceSystemPhysicalSystem: boolean;
};

type FeaturesStore = {
  features: Features;
  setFeatures: (features: Features) => void;
};

const useFeaturesStore = create<FeaturesStore>((set) => ({
  features: {
    isFeatureAdminEnabled: false,
    isConditionManagementEnabled: false,
    isFeatureVehicleReport: false,
    isFeatureAppraisalConditionsFeature: false,
    isFeatureAnalyticEnabled: false,
    isQuaggaBarcode: false,
    isNewNotificationSystem: false,
    isExtraPriceSystemPhysicalSystem: false,
  },

  setFeatures: (features: Features) => set({ features }),
}));

export const useFeatures = () =>
  useFeaturesStore(
    useShallow<FeaturesStore, Features>(({ features }) => features)
  );

export default useFeaturesStore;
