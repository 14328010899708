import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisclosuresUsInput } from 'graphql-types';

type SimpleItem = {
  radio?: boolean;
  select?: boolean;
  item?: DisclosuresUsInput | null;
  keyQuestion: string;
  options: string[];
  checkboxes?: string[];
  isDisabled?: boolean;
  updateOption?: (
    question: string,
    value: string,
    text?: string,
    children?: string[]
  ) => void;
  isError?: boolean;
  clearError?: (key: string) => void;
};

const TwoQuestions: React.FC<SimpleItem> = ({
  radio = false,
  select = false,
  item,
  keyQuestion,
  options,
  checkboxes,
  updateOption,
  isDisabled = false,
  isError = false,
  clearError,
}) => {
  const { t } = useTranslation();

  const [valueOther, setValueOther] = useState(item?.text || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (clearError) {
      clearError(keyQuestion);
    }
    if (updateOption) {
      updateOption(
        keyQuestion,
        (event.target as HTMLInputElement).value,
        '',
        []
      );
    }
    setChecked([]);
  };

  const handleTextChange = (value: string) => {
    if (updateOption) {
      updateOption(keyQuestion, 'yes', value, checked);
    }
    setValueOther(value);
  };

  const [checked, setChecked] = React.useState<string[]>(
    (item?.children ? item.children : []) as string[]
  );

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked((prev) => {
      if (radio) {
        return [event.target.value];
      }

      const isChecked = prev.includes(event.target.name);

      if (isChecked) {
        const filterElement = prev.filter((it) => it !== event.target.name);
        return filterElement ?? [];
      }
      const unique = Array.from(new Set<string>([...prev, event.target.name]));
      return unique;
    });
  };

  const handleSelectBoxChange = (event: any) => {
    setChecked([event.target.value as string]);
  };

  useEffect(() => {
    if (item?.value === 'yes' && updateOption) {
      updateOption(keyQuestion, 'yes', '', checked);
    }
  }, [checked]);

  const itemChildrenCheckBox = item?.children ?? [];

  return (
    <Grid
      container
      item
      style={{
        backgroundColor: isError ? '#fff4f4' : '',
      }}
    >
      <Grid item xs={12}>
        <Box
          color={isError ? 'rgb(0 0 0 / 87%)' : '#545B63'}
          fontSize="16px"
          marginTop="18px"
        >
          {t(`questionUs.${keyQuestion}.title`)}
        </Box>
      </Grid>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="question"
          name="question"
          value={item?.value ?? ''}
          onChange={handleChange}
        >
          <Grid alignItems="center" container item>
            {options.map((option) => (
              <Grid item key={option}>
                <FormControlLabel
                  value={option}
                  control={
                    <Radio
                      disabled={isDisabled}
                      inputProps={
                        {
                          ['data-cy']: `${keyQuestion}-radio-option-${option}`,
                        } as any
                      }
                    />
                  }
                  label={t(`question.${option}`)}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
      {item?.value === 'yes' && (
        <>
          {checkboxes && checkboxes?.length > 0 && (
            <Grid item xs={12}>
              {select ? (
                <Box
                  marginLeft="2px"
                  paddingLeft="18px"
                  style={{
                    borderLeft: '4px solid rgb(84 91 99 / 23%)',
                  }}
                >
                  <FormControl variant="outlined">
                    <Box style={{ width: '210px' }}>
                      <InputLabel htmlFor="outlined-select">
                        {t(`questionUs.number`)}
                      </InputLabel>
                      <Select
                        fullWidth
                        value={checked ? checked[0] : ''}
                        onChange={handleSelectBoxChange}
                        label={t(`questionUs.number`)}
                        inputProps={{ id: 'outlined-select' }}
                        data-cy={`${keyQuestion}-select`}
                      >
                        {checkboxes.map((checkKey) => (
                          <MenuItem
                            key={checkKey}
                            value={checkKey}
                            data-cy={`${keyQuestion}-select-${checkKey}`}
                          >
                            {t(`questionUs.${keyQuestion}.${checkKey}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </FormControl>
                </Box>
              ) : (
                <Box
                  marginLeft="2px"
                  paddingLeft="18px"
                  style={{
                    borderLeft: '4px solid rgb(84 91 99 / 23%)',
                  }}
                >
                  {!radio && (
                    <FormControl>
                      {checkboxes.map((checkKey) => (
                        <FormGroup key={keyQuestion + checkKey}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={itemChildrenCheckBox.includes(
                                  checkKey
                                )}
                                name={checkKey}
                                onChange={handleCheckBoxChange}
                                disabled={isDisabled}
                                inputProps={
                                  {
                                    'data-cy': `${keyQuestion}-checkbox-${checkKey}`,
                                  } as any
                                }
                              />
                            }
                            label={t(`questionUs.${keyQuestion}.${checkKey}`)}
                          />
                        </FormGroup>
                      ))}
                    </FormControl>
                  )}

                  {radio && (
                    <RadioGroup
                      aria-label="question"
                      name="question"
                      value={checked ? checked[0] : ''}
                      onChange={handleCheckBoxChange}
                    >
                      <Grid alignItems="center" container item>
                        {checkboxes.map((checkKey) => (
                          <Grid item key={checkKey} xs={12}>
                            <FormControlLabel
                              value={checkKey}
                              control={
                                <Radio
                                  disabled={isDisabled}
                                  inputProps={
                                    {
                                      ['data-cy']: `${keyQuestion}-radio-${checkKey}`,
                                    } as any
                                  }
                                />
                              }
                              label={t(`questionUs.${keyQuestion}.${checkKey}`)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  )}
                </Box>
              )}
            </Grid>
          )}
          {keyQuestion === 'question-seven' && checked.includes('option6') ? (
            <>
              <Grid item xs={12}>
                <Box maxWidth={320} mt={2}>
                  <TextField
                    label={t(`questionUs.other-issue`)}
                    multiline
                    rows={4}
                    fullWidth
                    value={valueOther}
                    onChange={(e) => {
                      handleTextChange(e.target.value);
                    }}
                    variant="outlined"
                    disabled={isDisabled}
                    inputProps={
                      {
                        ['data-cy']: `${keyQuestion}-other-issue`,
                      } as any
                    }
                  />
                </Box>
              </Grid>
            </>
          ) : null}
        </>
      )}
    </Grid>
  );
};

export default TwoQuestions;
