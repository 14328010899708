import React from 'react';
import { FieldError } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import {
  Box,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';

import Error from '../error';

export interface MaskFieldProps extends Omit<MUITextFieldProps, 'error'> {
  errors?: FieldError;
  mask: string;
}

// eslint-disable-next-line react/display-name
export const MaskField: React.FC<MaskFieldProps> = React.forwardRef(
  ({ errors = null, mask, ...rest }, ref) => (
    <Box display="grid">
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <ReactInputMask mask={mask} inputRef={ref as any} {...(rest as any)}>
        {(inputProps: MUITextFieldProps) => (
          <MUITextField {...rest} {...inputProps} />
        )}
      </ReactInputMask>

      <Error errors={errors} />
    </Box>
  )
);

export default MaskField;
