import React from 'react';
import { RoutesConfig } from 'router/routes.config';
import { Permissions } from 'constants/entities/user';

const userEvalRoutes = [
  {
    path: RoutesConfig.UserEvaluation.List,
    exact: true,
    component: React.lazy(() => import('modules/user-eval-listing')),
    permission: Permissions.PhysicalEvaluation.All,
  },
  {
    path: RoutesConfig.UserEvaluation.ViewOne,
    exact: true,
    component: React.lazy(() => import('modules/user-evaluation/View')),
    permission: Permissions.PhysicalEvaluation.All,
  },
];

export default userEvalRoutes;
