import { EditSteps } from '../constants/types';
import { AppraisalInput } from 'graphql-types';
import { EditPeFormValues } from 'constants/forms/physical-eval-edit/common';

class EditRequestBodyTransformer {
  static transform(
    body: Partial<EditPeFormValues>,
    step: EditSteps
  ): Partial<AppraisalInput> {
    switch (step) {
      case EditSteps.Vehicle: {
        return {
          ...body,
          odometer: Number(body.odometer) ?? 0,
        };
      }
      case EditSteps.Equipment: {
        return {
          optionsVinDecodeBB:
            body.optionsVinDecodeBB?.map(
              (option) =>
                ({
                  name: option.name?.toString() ?? '',
                  avg: Number(option.avg) ?? 0,
                  auto: option.auto?.toString() ?? '',
                  clean: Number(option.clean) ?? 0,
                  resid12: Number(option.resid12) ?? 0,
                  resid24: Number(option.resid24) ?? 0,
                  resid30: Number(option.resid30) ?? 0,
                  resid36: Number(option.resid36) ?? 0,
                  resid42: Number(option.resid42) ?? 0,
                  resid48: Number(option.resid48) ?? 0,
                  resid60: Number(option.resid60) ?? 0,
                  resid72: Number(option.resid72) ?? 0,
                  rough: Number(option.rough) ?? 0,
                  uoc: option.uoc?.toString() ?? '',
                  xclean: Number(option.xclean) ?? 0,
                } as any)
            ) ?? [],
        };
      }
      default: {
        return body as Partial<AppraisalInput>;
      }
    }
  }
}

export default EditRequestBodyTransformer;
