import React from 'react';
import { FieldError } from 'react-hook-form';

import { MaskField, MaskFieldProps } from '../mask-field';

interface PhoneFieldProps extends Omit<MaskFieldProps, 'mask'> {
  errors?: FieldError;
}

const PhoneField: React.FC<PhoneFieldProps> = (props) => (
  <MaskField mask="+1 999 999-9999" {...props} />
);

export default PhoneField;
