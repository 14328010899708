import { IOption } from './types';
import { EvaluationSource } from '../appraisal';

export const statusGroups = {
  drafts: ['draft', 'none'],
  waiting: ['evaluation', 'evaluating', 'new', 'waiting'],
  active: [
    'evaluation',
    'evaluating',
    'new',
    'waiting',
    'evaluated',
    'acquisition',
    'acquiring',
    'completed',
    'approved',
  ],
  openOffers: [
    'evaluated',
    'expired',
    'acquisition',
    'acquiring',
    'completed',
    'approved',
  ],
  bought: ['acquired', 'transport'],
  assess: [
    'assessment',
    'assessing',
    'assessed',
    'processing',
    'pending',
    'returned',
  ],
  ready: ['assessed', 'conditioned'],
  conditioning: ['assessed', 'conditioning'],
  conditioned: ['conditioned'],
  sold: ['sold'],
  canceled: ['returned', 'closed', 'cloned', 'cancelled', 'rejected'],
  expired: ['expired'],
  client: [
    'draft',
    'new',
    'evaluation',
    'completed',
    'evaluated',
    'cloned',
    'expired',
    'cancelled',
  ],
  appraiser: [
    'new',
    'waiting',
    'evaluating',
    'evaluated',
    'acquisition',
    'acquiring',
    'expired',
    'cancelled',
  ],
  director: [
    'approved',
    'acquiring',
    'acquisition',
    'transport',
    'acquired',
    'expired',
    'cancelled',
  ],
  assessor: ['processing', 'assessment', 'assessing', 'pending', 'assessed'],
  buyer: ['evaluating', 'evaluated'],
};

export const EvaluationSourceOptions: IOption[] = [
  { value: EvaluationSource.Exchange, label: 'origins.exchange' },
  { value: EvaluationSource.Buy, label: 'origins.buy' },
  { value: EvaluationSource.Service, label: 'origins.service' },
  { value: EvaluationSource.Inventory, label: 'origins.inventory' },
];

export const VehicleColors = [
  'Beige/Tan',
  'Black Metallic',
  'Blue',
  'Bronze',
  'Brown',
  'Champagne',
  'Charcoal',
  'Gold',
  'Gray',
  'Green',
  'Maroon',
  'Orange',
  'Pearl',
  'Pink',
  'Purple',
  'Red',
  'Silver',
  'White',
  'Yellow',
];

export const VehicleColorsOptions: IOption[] = VehicleColors.map((color) => ({
  value: color,
  label: `colors.${color.toLowerCase()}`,
}));
