import useStore from 'store/pe-edit-appraisal.store';
import { useFormContext } from 'react-hook-form';
import { PeQuestionsForm } from 'constants/forms';
import { useEffect } from 'react';

const useResetQuestions = () => {
  const { appraisal } = useStore();
  const { reset } = useFormContext<PeQuestionsForm.FormType>();

  useEffect(() => {
    if (appraisal) {
      setTimeout(
        () =>
          reset({
            [PeQuestionsForm.Keys.QuestionList]: (appraisal.disclosuresUS ??
              []) as any,
          }),
        10
      );
    } else {
      reset(PeQuestionsForm.defaultValues);
    }
  }, [appraisal]);
};

export default useResetQuestions;
