import Navigation from './utils/Navigation';

declare global {
  interface String {
    replaceParams: (
      params: Record<string, string | number | undefined | null>
    ) => string;
  }
}

String.prototype.replaceParams = function (params) {
  return Navigation.replaceParams(this as string, params);
};
