import React, { FC } from 'react';
import { Box } from '@mui/material';
import StepButton from 'ui/components/step-button';

type Props = {
  isLoading: boolean;
  isPrevShown?: boolean;
  onPrevStep?: () => void;
  isDisabled?: boolean;
  nextName?: string;
};

const StepButtons: FC<Props> = ({
  isLoading,
  isPrevShown = true,
  onPrevStep,
  isDisabled,
  nextName,
}) => {
  return (
    <Box display="flex" gap={5} pt={5} pb={3}>
      {isPrevShown && (
        <StepButton
          data-cy-back-btn
          variant="contained"
          color="primary"
          side="back"
          loading={isLoading}
          onClick={onPrevStep}
        />
      )}

      <StepButton
        data-cy="next-step-btn"
        variant="contained"
        color="primary"
        type="submit"
        side="next"
        disabled={isDisabled}
        loading={isLoading}
      >
        {nextName}
      </StepButton>
    </Box>
  );
};

export default StepButtons;
