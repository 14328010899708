import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import Loading from 'ui/components/loading';

import { useUser } from 'store';
import { RoutesConfig } from 'router/routes.config';

const DealershipGuard = ({ children }: PropsWithChildren) => {
  const user = useUser();

  if (!user || user.id === 0) {
    return <Loading />;
  }

  if (!user.dealership) {
    return <Navigate to={RoutesConfig.Auth.SignUp} />;
  }

  return <>{children}</>;
};

export default DealershipGuard;
