import React, { FC } from 'react';
import { Navigate, NavigateProps } from 'react-router';
import { useParams } from 'react-router-dom';

type Props = NavigateProps & {
  replaceKeys: string[];
};

const DynamicRedirect: FC<Props> = ({ to, replaceKeys, ...props }) => {
  const params = useParams();
  const isToString = typeof to === 'string';

  const replacedToPath = replaceKeys.reduce<string>(
    (acc, key) => acc.replace(`:${key}`, params[key] ?? ''),
    isToString ? to : to.pathname ?? ''
  );

  return (
    <Navigate
      to={isToString ? replacedToPath : { ...to, pathname: replacedToPath }}
      {...props}
    />
  );
};

export default DynamicRedirect;
