import React, { FC, memo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import VAutoCheck from 'modules/physical-evaluation/components/vAutoCheck';
import useStore from 'store/pe-edit-appraisal.store';

const VinTitleBlock: FC = () => {
  const { appraisal } = useStore();
  const { vin, make, model, modelYear } = appraisal || {};

  return (
    <Stack gap={1} alignItems="center">
      <Typography variant="h2">
        {`${modelYear || ''} ${make || ''} ${model || ''}`}
      </Typography>

      <Stack flexDirection="row" alignItems="center">
        <Typography variant="h4" color="green.800">
          VIN:{' '}
          <Typography variant="caption" fontSize={18}>
            {vin || ''}
          </Typography>
        </Typography>

        {vin && (
          <Box mx={2}>
            <VAutoCheck vin={vin} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(VinTitleBlock);
