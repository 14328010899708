import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisclosuresUsInput } from 'graphql-types';

type SimpleItem = {
  item?: DisclosuresUsInput | null;
  keyQuestion: string;
  checkboxes: string[];
  isDisabled?: boolean;
  updateOption?: (
    question: string,
    value: string,
    text?: string,
    children?: string[]
  ) => void;
  isError?: boolean;
  clearError?: (key: string) => void;
};

const SimpleSelect: React.FC<SimpleItem> = ({
  item,
  keyQuestion,
  updateOption,
  checkboxes,
  isDisabled,
  isError = false,
  clearError,
}) => {
  const { t } = useTranslation();

  const [checked, setChecked] = React.useState<string[]>(
    (item?.children ? item.children : []) as string[]
  );

  const [valueOther, setValueOther] = useState(item?.text || '');

  const handleChange = (value: string) => {
    if (clearError) {
      clearError(keyQuestion);
    }

    if (updateOption) {
      updateOption(keyQuestion, 'yes', value, checked);
    }
    setValueOther(value);
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
    setChecked((prev) => {
      if (event.target.name === 'option1') {
        return ['option1'];
      }

      const prevState = prev.filter((it) => it !== 'option1');

      const isChecked = prevState.includes(event.target.name);

      if (isChecked) {
        const filterElement = prevState.filter(
          (it) => it !== event.target.name
        );
        return filterElement ?? [];
      }
      const unique = Array.from(
        new Set<string>([...prevState, event.target.name])
      );
      return unique;
    });
  };

  useEffect(() => {
    if (item?.value === 'yes' && updateOption) {
      updateOption(keyQuestion, 'yes', '', checked);
    }
    if (!checked.includes('option8')) {
      setValueOther('');
    }
  }, [checked]);

  const itemChildrenCheckBox = item?.children ?? [];

  return (
    <Grid
      container
      item
      style={{
        backgroundColor: isError ? '#fff4f4' : '',
      }}
    >
      <Grid item xs={12}>
        <Box color={isError ? 'rgb(0 0 0 / 87%)' : '#545B63'} fontSize="16px">
          {t(`questionUs.${keyQuestion}.title`)}
        </Box>
      </Grid>

      <FormControl component="fieldset">
        <FormControl>
          {checkboxes.map((checkKey) => (
            <FormGroup key={keyQuestion + checkKey}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={itemChildrenCheckBox.includes(checkKey)}
                    name={checkKey}
                    onChange={handleCheckBoxChange}
                    disabled={isDisabled}
                    inputProps={
                      {
                        'data-cy': `${keyQuestion}-checkbox-${checkKey}`,
                      } as any
                    }
                  />
                }
                label={t(`questionUs.${keyQuestion}.${checkKey}`)}
              />
            </FormGroup>
          ))}
        </FormControl>
      </FormControl>
      {itemChildrenCheckBox.includes('option8') ? (
        <Grid item xs={12}>
          <Box maxWidth={320} mt={2}>
            <TextField
              id="standard-multiline-static"
              label={t(`questionUs.other-issue`)}
              multiline
              rows={4}
              fullWidth
              value={valueOther}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              disabled={isDisabled}
              variant="outlined"
              data-cy={`${keyQuestion}-option8-other-issue`}
            />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default SimpleSelect;
