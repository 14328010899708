import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DecodeBbItemType } from 'graphql-types';

export enum Keys {
  Options = 'optionsVinDecodeBB',
}

export const Schema = z.object({
  [Keys.Options]: z.array(z.any()),
});

export type FormType = {
  [Keys.Options]: DecodeBbItemType[];
};

export const defaultValues: FormType = {
  [Keys.Options]: [],
};

export const useEquipmentForm = () => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: defaultValues,
  });
};
