import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  image: {
    objectFit: 'cover',
    width: '100%',
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.common.white,
  },
  deleteIcon: {
    color: '#ff3d57',
    backgroundColor: '#fde2e5',
    borderColor: '#ff3d57',

    '&:hover': {
      background: `#ff3d57`,
      color: '#ffffff',
    },
  },
  editIcon: {
    color: '#18a857',
    backgroundColor: '#ffffff',
    borderColor: '#18a857',

    '&:hover': {
      background: `#18a857`,
      color: '#ffffff',
    },
  },
}));
