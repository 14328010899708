import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const shadowStyles = makeStyles((theme) => ({
  '@global': {
    ...generateShadows(theme),
  },
}));

const generateShadows = (theme: Theme) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classList: any = {};

  theme.shadows.forEach((shadow, ind) => {
    classList[`.elevation-z${ind}`] = {
      boxShadow: `${shadow} !important`,
    };
  });

  return classList;
};
