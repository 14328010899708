import useDrivly from 'hooks/useDrivly';
import { useEffect } from 'react';
import { Countries } from 'graphql-types';
import { getZIPCodeWithLocationUS } from 'utils';
import useStore from 'store/pe-edit-appraisal.store';

const useDrivlyCheck = () => {
  const { appraisal } = useStore();

  const {
    onBBInfoHandler,
    onGetCarGurusByVINHandler,
    checkCountryCar,
    getOffersByVIN,
  } = useDrivly(appraisal?._id ?? '');

  useEffect(() => {
    if (!appraisal) return;

    const carCountry = checkCountryCar(appraisal.location ?? '');

    if (appraisal.vin && carCountry && appraisal.odometer) {
      onBBInfoHandler({
        country: carCountry,
        lang: 'en',
        mileage:
          carCountry === Countries.US
            ? String(appraisal.odometerMiles)
            : String(appraisal.odometer),
        series: appraisal.series ?? '',
        styleId: appraisal.styleId ?? '',
        vin: appraisal.vin,
      });

      if (carCountry === Countries.US) {
        getOffersByVIN(
          appraisal.vin,
          Number(appraisal.odometerMiles),
          getZIPCodeWithLocationUS(appraisal.location ?? '')
        );

        onGetCarGurusByVINHandler(appraisal.vin, appraisal.odometerMiles);
      }
    }
  }, []);
};

export default useDrivlyCheck;
