import useStore from 'store/pe-edit-appraisal.store';
import { useFormContext } from 'react-hook-form';
import { PeCustomerForm } from 'constants/forms';
import { useEffect } from 'react';

const useResetCustomer = () => {
  const { appraisal } = useStore();
  const { reset } = useFormContext<PeCustomerForm.FormType>();

  useEffect(() => {
    if (appraisal) {
      reset({
        [PeCustomerForm.Keys.FirstName]: appraisal.custFirstName ?? '',
        [PeCustomerForm.Keys.LastName]: appraisal.custLastName ?? '',
        [PeCustomerForm.Keys.HomePhone]: appraisal.custHomePhone ?? '',
        [PeCustomerForm.Keys.Email]: appraisal.custEmail ?? '',
        [PeCustomerForm.Keys.Address]: appraisal.custAddress ?? '',
        [PeCustomerForm.Keys.City]: appraisal.custCity ?? '',
        [PeCustomerForm.Keys.State]: appraisal.custState ?? '',
        [PeCustomerForm.Keys.PostalCode]: appraisal.custPostalCode ?? '',
      });
    } else {
      reset(PeCustomerForm.defaultValues);
    }
  }, [appraisal]);
};

export default useResetCustomer;
