/*
  @description: Regular expression for parsing the URL path parameters.
  @example: /users/:id
  @example: /users/:id/:name
  @returns: [':id', ':name']
 */
export const ParamsRegex = /:([^/]+)/g;

/*
  @description: Regular expression for validating the VIN code.
  @example: 1HGCM82633A123456
 */
export const VinCodeRegexp =
  /^([A-HJ-NPR-Z\d]{3})([A-HJ-NPR-Z\d]{5})([\dX])(([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d]{6}))$/;
