import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { TextFieldController } from 'ui/inputs';
import { PeCustomerForm } from 'constants/forms';
import MaskInputController from 'ui/inputs/controllers/MaskInputController';
import useLocationWidget from './hooks/useLocationWidget';
import useResetCustomer from './hooks/useResetCustomer';
import { useFormContext } from 'react-hook-form';
import { useUser } from 'store';

const CustomerForm: FC = () => {
  useResetCustomer();
  const { placeWidgetRef } = useLocationWidget();
  const { country } = useUser();

  const { setValue } = useFormContext<PeCustomerForm.FormType>();

  const handleLocationBlur = () => {
    setValue(
      PeCustomerForm.Keys.PostalCode,
      country === 'CA' ? 'G1R 5M1' : '32801',
      { shouldValidate: true }
    );
  };

  return (
    <Stack gap={3} width="100%" maxWidth={600}>
      <TextFieldController
        name={PeCustomerForm.Keys.FirstName}
        label="appraisal.owner.first-name.label"
        placeholder="appraisal.owner.first-name.placeholder"
        data-cy="first-name"
      />

      <TextFieldController
        name={PeCustomerForm.Keys.LastName}
        label="appraisal.owner.last-name.label"
        placeholder="appraisal.owner.last-name.placeholder"
        data-cy="last-name"
      />

      <MaskInputController
        name={PeCustomerForm.Keys.HomePhone}
        label="appraisal.owner.phone.label"
        placeholder="appraisal.owner.phone.placeholder"
        mask="+1 999 999-9999"
        data-cy="phone"
      />

      <TextFieldController
        name={PeCustomerForm.Keys.Email}
        label="appraisal.owner.email.label"
        placeholder="appraisal.owner.email.placeholder"
        data-cy="email"
      />

      <TextFieldController
        inputRef={placeWidgetRef as any}
        name={PeCustomerForm.Keys.Address}
        label="appraisal.owner.location.label"
        placeholder="appraisal.owner.location.placeholder"
        data-cy="address"
        onBlur={handleLocationBlur}
      />
    </Stack>
  );
};

export default CustomerForm;
