import React, { useMemo } from 'react';
import { useVAutoCheckCoreQuery } from 'graphql-types';
import { Button, CircularProgress, Typography } from '@mui/material';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { isNil } from 'lodash';

type VAutoCheckProps = {
  vin: string;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const VAutoCheck: React.FC<VAutoCheckProps> = ({ vin }) => {
  const { data, loading } = useVAutoCheckCoreQuery({
    variables: {
      vin,
    },
  });

  const BAD_EMAILS = ['horacio.payan@coxautoinc.com'];

  const vAutoInspection = useMemo(() => {
    return (
      data?.vAutoCheckCore.GetAppraisalWithPricingDataByVinResult.Appraisal
        .Appraiser ?? null
    );
  }, [data]);

  if (loading) {
    <CircularProgress />;
  }

  if (isNil(vAutoInspection) || BAD_EMAILS.includes(vAutoInspection?.Email))
    return null;

  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">
              Inspection is present in vAuto
            </Typography>
            {'Added by'}{' '}
            <b>{`${vAutoInspection.FirstName} ${vAutoInspection.LastName}`}</b>.{' '}
            <br />
            <u>{`${vAutoInspection.Email}`}</u>{' '}
            {`- ${vAutoInspection.UserName}`}
          </React.Fragment>
        }
      >
        <Button
          style={{
            color: '#20a8d8',
          }}
        >
          <CarCrashIcon /> vAuto
        </Button>
      </HtmlTooltip>
    </div>
  );
};

export default VAutoCheck;
