import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import ReactInputMask from 'react-input-mask';

type Props = Pick<
  ControllerProps,
  'name' | 'defaultValue' | 'shouldUnregister'
> &
  TextFieldProps & {
    label: string;
    mask: string;
  };

const MaskInputController: FC<Props> = ({
  name,
  defaultValue,
  shouldUnregister,
  fullWidth = true,
  label,
  helperText,
  placeholder,
  mask,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      control={control}
      render={({
        field: { onChange, value, ref, onBlur },
        fieldState: { error },
      }) => (
        <ReactInputMask
          mask={mask}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          {...(props as any)}
        >
          {
            ((inputProps: TextFieldProps) => (
              <TextField
                ref={ref}
                fullWidth={fullWidth}
                label={t(label)}
                error={!!error?.message}
                helperText={t((error?.message ?? helperText ?? '') as string)}
                placeholder={placeholder ? t(placeholder as string) : undefined}
                {...inputProps}
              />
            )) as any
          }
        </ReactInputMask>
      )}
    />
  );
};

export default MaskInputController;
