import React from 'react';
import clsx from 'clsx';
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';

import useGlobal from 'hooks/useGlobal';

import NotificationMassage from 'ui/components/notification';

const useStyles = makeStyles((theme) => ({
  sidenav: ({ width }: { width: string }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100vh',
    width: width,
    boxShadow: theme.shadows[8],
    color: '#000000',
    backgroundColor: '#ffffff',
    backgroundSize: 'cover',
    zIndex: 111,
    overflow: 'hidden',
    transition: 'all 250ms ease-in-out',
  }),
  scrollable: {
    zIndex: 333,
    paddingLeft: 20,
    paddingRight: 20,
  },
  sidenavMobileOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    zIndex: -1,
  },
  headerMenu: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: '58px',
    padding: '1rem',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 6%), 0px 6px 10px 0px rgb(0 0 0 / 4%), 0px 1px 18px 0px rgb(0 0 0 / 4%) !important',
  },
}));

type SidenavProps = {
  onOverlayClick: () => void;
};

const NotificationsBar: React.FC<SidenavProps> = ({ onOverlayClick }) => {
  const { t } = useTranslation();
  const { notification } = useGlobal();

  const classes = useStyles({
    width: '375px',
  });

  const renderNotification = () => {
    const list = notification.list;

    return list.map((it) => (
      <NotificationMassage key={it._id} notification={it} />
    ));
  };

  return (
    <Box className={classes.sidenav}>
      <Box className={classes.headerMenu}>
        <Box display="flex" alignItems="center">
          <NotificationsIcon />
          <Box ml={2}>{t('notifications.title')}</Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignSelf="right"
        >
          <IconButton size="medium" onClick={onOverlayClick}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className="flex-column relative h-full" py={2}>
        <ScrollBar
          options={{ suppressScrollX: true }}
          className={clsx('relative')}
        >
          {renderNotification()}
        </ScrollBar>
      </Box>
      <div onClick={onOverlayClick} className={classes.sidenavMobileOverlay} />
    </Box>
  );
};

export default NotificationsBar;
