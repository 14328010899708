export const blobToBase64Jpg = (
  uri: string,
  canvasWidth: number,
  canvasHeight: number
): Promise<string> => {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');
      if (canvas) {
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        canvas
          .getContext('2d')
          ?.drawImage(image, 0, 0, canvasWidth, canvasHeight);
        resolve(canvas.toDataURL('image/jpeg', 1.0));
      }
    };

    image.src = uri;
  });
};
