import React from 'react';
import { Permissions } from 'constants/entities/user';
import { RoutesConfig } from 'router/routes.config';

const workRoutes = [
  {
    path: RoutesConfig.Work.List,
    exact: true,
    component: React.lazy(() => import('modules/work-eval-listing')),
    permission: Permissions.Work.GetList,
  },
  {
    path: RoutesConfig.Work.ViewOne,
    exact: true,
    component: React.lazy(() => import('modules/work-evaluation/View')),
    permission: Permissions.Work.GetOne,
  },
];

export default workRoutes;
