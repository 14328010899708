import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoAccessPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack mx="auto" my="auto">
      <Typography variant="h2">{t('no-access.title')}</Typography>
      <Typography mt={1}>{t('no-access.description')}</Typography>

      <Typography mt={2}>{t('no-access.reasons')}</Typography>

      <ul>
        <Typography component="li">{t('no-access.reason1')}</Typography>
        <Typography component="li">{t('no-access.reason2')}</Typography>
        <Typography component="li">{t('no-access.reason3')}</Typography>
      </ul>

      <Typography mt={1}>{t('no-access.contact-support')}</Typography>
    </Stack>
  );
};

export default NoAccessPage;
