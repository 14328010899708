import React from 'react';
import { RoutesConfig } from '../routes.config';
import { Permissions } from 'constants/entities/user';

const appraisalConditionsRoutes = [
  {
    path: RoutesConfig.AppraisalConditions.List,
    exact: true,
    component: React.lazy(() => import('modules/appraisal-conditions/List')),
    permission: Permissions.ConditionReport.GetList,
  },
  {
    path: RoutesConfig.AppraisalConditions.Create,
    exact: true,
    component: React.lazy(() => import('modules/appraisal-condition-create')),
    permission: Permissions.ConditionReport.Create,
  },
  {
    path: RoutesConfig.AppraisalConditions.Edit,
    exact: true,
    component: React.lazy(() => import('modules/appraisal-condition-edit')),
    permission: Permissions.ConditionReport.Update,
  },
  {
    path: RoutesConfig.AppraisalConditions.ViewOne,
    exact: true,
    component: React.lazy(
      () => import('modules/appraisal-conditions/EditManege')
    ),
    permission: Permissions.ConditionReport.GetOne,
  },
];

export default appraisalConditionsRoutes;
